import React from 'react';
import DashboardCharts from '../../../components/Charts/DashboardChart';

function DashboardPage(props) {
  return (
    <>
      <DashboardCharts />
    </>
  );
}
export default DashboardPage;
