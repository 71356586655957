import { parse } from 'uuid';
import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {chatBansConstants} from '../constants';
import {errorParser} from './errorParser';

export const listChatBans =
  (perPage = 100, page = 1, search = '', order = -1) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: chatBansConstants.CHATBAN_LOADING});
    try {
      const {
        data: {data},
      } = await API.get(
        `admin/v1/chatbans?perPage=${perPage}&page=${page}&search=${search}&order=${order}`,
      );
      if (data) {
        dispatch({
          type: chatBansConstants.CHATBAN_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: chatBansConstants.CHATBAN_ERROR,
        payload: parsedError,
      });
    }
  };


export const chatUnban = (formData, name) => async (dispatch) => {
  await headerSetup();
  dispatch({type: chatBansConstants.CHATBAN_LOADING});
  try {
    const {
      data: {data},
    } = await API.post('user/v1/chat-unban', formData);
    if (data) {
      dispatch({
        type: chatBansConstants.CHAT_UNBAN_SUCCESS,
        payload: formData,
      });
      successAlert(`${name} updated successfully`);

    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: chatBansConstants.CHATBAN_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

// const handleBanUnBanUser = () => {

  
//   const url = `${Config.apiUrl}${Config.apiUserTag}${!!isUserBanned ? 'chat-unban' : 'chat-ban'}`
//    axios.post(url, !!isUserBanned ? unBanDataToSend : banDataToSend)
//       .then(response => {
//         if (response.status == 200) {
//           props.actions.setVisible(false);
//           listBannedUsers()
//           setShowModal(false);
          
//         } else {
//           return response.data.message
//             ? props.actions.showOptionsAlert(response.data.message)
//             : props.actions.withoutAlertEnable();
//         }
//         props.actions.setVisible(false);
//       })
//       .catch(error => {
//         props.actions.setVisible(false);
//         crashlytics().log('handle chat-ban-unban error.');
//         crashlytics().log(error.response.data.message);
//       });
// };
