import moment from 'moment';
import React, { Component } from 'react';
import ReactExport from 'react-export-excel';
import { connect } from 'react-redux';
import { languageNames, languages } from '../../constants';
import { API } from '../../services/auth';
import { flattenObject, toCamelCase } from '../../utils';
import { userTypes, userStatuses, adStatuses } from '../../utils/data';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const formatType = {
  dateonly: (val) => moment(val).format('DD/MM/YYYY'),
  timeonly: (val) => moment(val).format('LT'),
  datetime: (val) => moment(val).format('DD/MM/YYYY hh:mm A'),
  boolean: (val) => (val ? 'Yes' : 'No'),
};

const formatData = (info, type = undefined, dataConverter = undefined) => {
  if (dataConverter !== undefined && dataConverter !== null) {
    return dataConverter(info);
  }

  if (type !== undefined && type !== null) {
    return formatType[type](info);
  }

  if (typeof info === 'boolean') {
    return formatType['boolean'](info);
  }

  return info;
};

const createSheetData = (data, headers) => {

  let tempData = data.slice();
  tempData = tempData.map((el) => {
    let obj = {};
    const flattenedObj = flattenObject(el);
    headers.forEach((header) => {
      if (
        typeof el[header.key] === 'object' &&
        Array.isArray(el[header.key]) &&
        header.type === 'length'
      ) {
        obj[toCamelCase(header.label)] = el[header.key].length;
      } else {
        if (header.key.includes('options') && header.key.includes('filter')) {
          const key = header.key.split('.');
          obj[toCamelCase(header.label)] = el.options[key[1]]?.filter ? el.options[key[1]].filter[key[3]] : '';
        } else if (header.key.includes('options') && header.key.includes('option')) {
          const key = header.key.split('.');
          obj[toCamelCase(header.label)] = el.options[key[1]]?.name ? el.options[key[1]].name[key[3]] : '';
        } else {
          obj[toCamelCase(header.label)] = formatData(
            flattenedObj[header.key]
              ? flattenedObj[header.key]
              : header.backupKey
                ? flattenedObj[header.backupKey]
                : flattenedObj[header.key],
            header.type,
            header.dataConverter,
          );
        }
      }
    });
    return obj;
  });
  return tempData;
};

const getMaxOptionsCount = (questions) => {
  let max = 0;
  questions.forEach((question) => {
    if (question.options.length > max) {
      max = question.options.length;
    }
  });
  return max;
};

const addOptionsHeaders = (headers, maxOptionsCount, isFilter) => {
  const tempHeaders = headers.slice();
  for (let i = 0; i < maxOptionsCount; i++) {
    languages.forEach((language) => {
      tempHeaders.push({
        label: `${isFilter ? 'Filter' : 'Option'} ${i + 1} (${languageNames.en[language]
          })`,
        key: `options.${i}.${isFilter ? 'filter' : 'name'}.${language}`,
        backupKey: isFilter ? `options.${i}.name.${language}` : undefined,
      });
    });
  }
  return tempHeaders;
};
class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sheets: [],
      loading: false,
    };
  }

  csvButton = React.createRef();

  getData = (resource, commonHeaders, sheetsHeaders) => () => {
    if (!this.state.loading) {
      this.setState({ loading: true });

      const filters = this.props?.filters ? this.props.filters : {};
      API.get(`admin/v1/${resource}`, {
        params: { perPage: 999999, page: 1, ...this.props?.params, ...filters },
      })
        .then((result) => {
          result = result?.data?.data?.listing;
          let tempMaxOptions = 0;

          let sheets = [];
          if (this.props.dataType === 'questions') {
            userTypes.forEach((userType) => {
              if (userType.enum !== 4 && userType.enum !== 3) {
                this.props.categories.forEach((category) => {
                  let data = result.filter(
                    (row) =>
                      row.userType === userType.enum &&
                      row.categoryId?._id === category._id,
                  );
                  tempMaxOptions = getMaxOptionsCount(data);
                  const tempHeaders = addOptionsHeaders(
                    commonHeaders,
                    tempMaxOptions,
                    this.props?.params?.filters ? true : false,
                  );

                  data = createSheetData(data, tempHeaders);
                  sheets.push({
                    name: `${userType.name}(${category.name.en})`.replace(
                      /[`~!@#$%^&*_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                      '',
                    ),
                    headers: tempHeaders,
                    data,
                  });
                });
              } else {
                let data = result.filter(
                  (row) => row.userType === userType.enum,
                );
                tempMaxOptions = getMaxOptionsCount(data);
                const tempHeaders = addOptionsHeaders(
                  commonHeaders,
                  tempMaxOptions,
                  this.props?.params?.filters ? true : false,
                );

                data = createSheetData(data, tempHeaders);
                sheets.push({
                  name: `${userType.name}`.replace(
                    /[`~!@#$%^&*_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                    '',
                  ),
                  headers: tempHeaders,
                  data,
                });
              }
            });
          } else if (this.props.dataType === 'ads') {
            result = result.map(ad => ({
              ...ad,
              deletedAt: new Date(ad.deletedAt).toString()
            }))

            result = createSheetData(result, commonHeaders);
            sheets.push({
              name: this.props.filename,
              headers: commonHeaders,
              data: result,
            });
          } else if (this.props.dataType === 'chats') {
            result = result.map(chat => {
              return {
                ...chat,
                recieverName: chat.recieverData[0]?.name,
                recieverUserId: chat.recieverData[0]?.userId,
                senderName: chat.senderData[0]?.name,
                senderUserId: chat.senderData[0]?.userId,
                senderCount: chat.metadata.messagesCounter ? chat.metadata.messagesCounter[chat.senderData[0]?._id] : 0,
                receiverCount: chat.metadata.messagesCounter ? chat.metadata.messagesCounter[chat.recieverData[0]?._id] : 0
              }
            })
            result = createSheetData(result, commonHeaders);
            sheets.push({
              name: this.props.filename,
              headers: commonHeaders,
              data: result,
            });
          } else {
            result = createSheetData(result, commonHeaders);
            sheets.push({
              name: this.props.filename,
              headers: commonHeaders,
              data: result,
            });
          }

          this.setState(
            {
              sheets: sheets,
              loading: false,
            },
            () => {
              this.csvButton?.current?.click();
            },
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { loading } = this.state;
    const {
      resource,
      headers: commonHeaders,
      sheetsHeaders,
      filename,
      ...props
    } = this.props;

    return (
      <div>
        <button
          className="btn table-header-button"
          onClick={this.getData(resource, commonHeaders, sheetsHeaders)}>
          {loading ? 'Exporting...' : 'Export'}
        </button>
        <ExcelFile
          element={
            <button ref={this.csvButton} style={{ display: 'none' }}>
              {loading ? 'Exporting...' : 'Export'}
            </button>
          }
          filename={filename}>
          {this.state.sheets.map((sheet, index) => (
            <ExcelSheet data={sheet.data} name={sheet.name} key={index}>
              {sheet.headers.map((header, index) => (
                <ExcelColumn
                  label={header.label}
                  value={toCamelCase(header.label)}
                  key={index}
                />
              ))}
            </ExcelSheet>
          ))}
        </ExcelFile>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categoriesReducer.categories,
  };
}

export default connect(mapStateToProps)(Export);