import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Container} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {
  deleteBanner,
  editBannerStatus,
  listBanners,
  suffleBanner,
} from '../../redux/actions/banners.actions.js';
import {bannersConstants} from '../../redux/constants/banners.constants.js';
import {getImageUrl} from '../../utils/renderImage.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import SwitchSlider from '../Switch/SwitchSlider.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);

  const dispatch = useDispatch();

  const {banners, loading, count} = useSelector(
    (state) => state.bannersReducer,
  );

  const history = useHistory();

  const navigateTo = (route) => history.push(route);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteBanner(id));
    } else return;
  };

  const activeInactiveBanner = (id) => {
    dispatch(editBannerStatus(id));
  };

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) =>
      dispatch(listBanners(postsPerPage, currentPage, searchKeyword)),
    [dispatch],
  );

  const onEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let clone = banners;
      let draggedLink = clone[sourceIdx];
      let newList = clone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);

      dispatch({
        type: bannersConstants.BANNER_SUFFLE,
        payload: newList,
      });
      dispatch(
        suffleBanner({
          from: clone[sourceIdx]._id,
          to: clone[destIdx]._id,
        }),
      );
    }
  };

  const {t} = useTranslation();

  const rows = banners.map((item) => [
    item?.icon ? (
      <img
        alt={'Gulf workers'}
        className="table-banner-image"
        src={getImageUrl(item?.icon)}
      />
    ) : (
      <p>N/A</p>
    ),
    item?.link ? (
      <a
        className="table-banner-link"
        href={item?.link}
        target="_blank"
        rel={'noreferrer'}>
        {item?.link}
      </a>
    ) : (
      <p>N/A</p>
    ),
    <SwitchSlider
      clicked={() => activeInactiveBanner(item?._id)}
      checked={item?.status === 1}
      name={item?.id}
    />,
    item.bannerType === 2 ? t('fullPageAds') : t('plainAds'),
    <>
      <img
        alt={'Gulf workers'}
        className="td-action-img"
        src={editIcon}
        onClick={() => navigateTo(`/admin/banners/editBanner/${item._id}`)}
      />
      <img
        alt={'Gulf workers'}
        className="td-action-img"
        onClick={() => deleteHandler(item?._id)}
        src={deleteIcon}
      />
    </>,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[t('image'), t('url'), t('status'), t('adsType'), t('actions')]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          onDragEnd={onEnd}
          fetchData={fetchData}
          addClickHandler={() => navigateTo('/admin/banners/addBanner')}
          exportButton={
            <Export
              filename="banners"
              resource="listBanner"
              params={{search}}
              headers={[
                {label: 'URL', key: 'link'},
                {label: 'Status', key: 'status'},
                {
                  label: 'Ads Type',
                  key: 'bannerType',
                  dataConverter: (val) =>
                    val === 2 ? t('fullPageAds') : t('plainAds'),
                },
              ]}
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
