import React, {useState, useEffect} from 'react';
import Header from '../../Headers/Header.js';

import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {listCategories} from '../../../redux/actions/categories.actions.js';

import {QuestionTypeEnums, quesTypes, userTypes} from '../../../utils/data';

import {
  editQuestion,
  getSingleQuestion,
} from '../../../redux/actions/questions.actions.js';
import {useParams} from 'react-router-dom';
import {finder} from '../../../utils/dataHelpers.js';
import {useTranslation} from 'react-i18next';
import {emptyLanguagesObject, languages} from '../../../constants/index.js';
import {validateLanguagesObject} from '../../../utils/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';

const DashboardForm = ({history}) => {
  const {id} = useParams();

  //redux
  const dispatch = useDispatch();
  const {loading, question: questionById} = useSelector(
    (state) => state.questionsReducer,
  );

  const [userType, setUserType] = useState(null);
  const [quesType, setQuesType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMandatory, setIsMandatory] = useState(false);

  const [question, setQuestion] = useState({...emptyLanguagesObject});
  const [filterText, setFilterText] = useState({...emptyLanguagesObject});
  const [options, setOptions] = useState([
    {
      name: {...emptyLanguagesObject},
      filter: {...emptyLanguagesObject},
      cond: {min: '', max: '', op: '<>'},
    },
  ]);

  const validateOptions = () => {
    let isValid = true;
    options?.forEach((option) => {
      languages.forEach((lang) => {
        if (!option?.filter?.[lang] && !option?.name?.[lang]) {
          isValid = false;
        }
      });
    });
    return isValid;
  };

  const validateForm = () =>
    validateLanguagesObject(question) &&
    validateLanguagesObject(filterText) &&
    validateOptions() &&
    userType &&
    quesType;

  // handle input change
  const handleOptionsChange = (value, lang, index) => {
    const list = [...options];
    if (!list[index].filter) list[index].filter = {};
    list[index].filter[lang] = value;
    setOptions(list);
  };

  // const handleConditionChange = (e, index) => {
  //   const list = [...options];
  //   list[index].cond['op'] = e.target.value;
  //   setOptions(list);
  // }

  const handleMinVal = (e, index) => {
    const list = [...options];
    list[index].cond['min'] = e.target.value;
    setOptions(list);
  };

  const handleMaxVal = (e, index) => {
    const list = [...options];
    list[index].cond['max'] = e.target.value;
    setOptions(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...options];
    list.splice(index, 1);
    setOptions(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setOptions([
      ...options,
      {
        name: {...emptyLanguagesObject},
        filter: {...emptyLanguagesObject},
        cond: {min: '', max: '', op: '<>'},
      },
    ]);
  };

  const onChangeHandlerFilter = (value, lang) => {
    setFilterText({...filterText, [lang]: value});
  };

  const submitHandler = async () => {
    const formData = {
      userType: userType?.enum,
      questionType: quesType?.enum,
      isMandatory,
      question,
      filterText: filterText,
      ...(quesType?.enum !== 1 &&
        quesType?.enum !== 5 &&
        quesType?.enum !== 6 && {
          options: options?.map((item) => {
            if (quesType?.enum !== 7)
              return {
                filter: item?.filter,
                name: item?.name,
                cond: item?.cond,
              };

            return {
              filter: item?.filter,
              cond: item?.cond,
            };
          }),
        }),
      categoryId: selectedCategory?._id,
      questionId: id,
    };
    dispatch(editQuestion(formData, history, true));
  };

  useEffect(() => {
    dispatch(listCategories());
    dispatch(getSingleQuestion(id));
  }, [dispatch, id]);

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    if (!!questionById?.question) {
      setQuestion(questionById?.question);
      setFilterText(questionById?.filterText);
      setSelectedCategory(questionById?.categoryId);
      setIsMandatory(questionById?.isMandatory);
      setUserType(finder(userTypes, questionById?.userType));
      setOptions(questionById?.options);
      setQuesType(finder(quesTypes, questionById?.questionType));
    }
    // eslint-disable-next-line
  }, [questionById?.name, questionById?._id]);

  useEffect(() => {
    if (quesType?.enum === QuestionTypeEnums.TEXT) {
      setOptions([
        {
          name: {...emptyLanguagesObject},
          filter: {...emptyLanguagesObject},
          cond: {op: '<>', max: '', min: ''},
        },
      ]);
    } else if (
      quesType?.enum === QuestionTypeEnums.BOOLEAN &&
      !questionById?.options?.length
    ) {
      setOptions([
        {
          name: {...emptyLanguagesObject},
          filter: {...emptyLanguagesObject},
          cond: {op: '<>', max: '', min: ''},
        },
        {
          name: {...emptyLanguagesObject},
          filter: {...emptyLanguagesObject},
          cond: {op: '<>', max: '', min: ''},
        },
      ]);
    } else if (
      quesType?.enum === QuestionTypeEnums.NUMBER &&
      !questionById?.options?.length
    ) {
      setOptions([
        {
          name: {...emptyLanguagesObject},
          filter: {...emptyLanguagesObject},
          cond: {op: '<>', max: '', min: ''},
        },
        {
          filter: {...emptyLanguagesObject},
          name: {...emptyLanguagesObject},
          cond: {op: '<>', max: '', min: ''},
        },
      ]);
    } else {
      setOptions(questionById?.options);
    }
  }, [quesType?.enum, questionById?.options]);

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('editFilter')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <MultiLangInput
                    label="Question"
                    value={question}
                    disabled
                    isRequired
                  />
                  <MultiLangInput
                    label="Filter Text"
                    placeholder="Filter Text"
                    value={filterText}
                    onChange={(value, lang) =>
                      onChangeHandlerFilter(value, lang)
                    }
                    isRequired
                  />
                  <br />
                  <hr />
                  <br />
                  {quesType &&
                    quesType?.enum === 7 &&
                    options.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <MultiLangInput
                            label={`Filter ${i + 1}`}
                            placeholder={`Filter ${i + 1}`}
                            value={item.filter}
                            onChange={(value, lang) =>
                              handleOptionsChange(value, lang, i)
                            }
                            isRequired
                          />
                          <Row form>
                            <Col lg={3} md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">{t('minVal')}</Label>
                                <Input
                                  type="text"
                                  placeholder={t('youngerThan')}
                                  value={item.cond?.min}
                                  onChange={(e) => handleMinVal(e, i)}
                                  name={'fil'}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                              <FormGroup>
                                <Label for="exampleEmail">{t('maxVal')}</Label>
                                <Input
                                  type="text"
                                  placeholder={t('olderThan')}
                                  value={item.cond?.max}
                                  name={'fil'}
                                  onChange={(e) => handleMaxVal(e, i)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <hr
                            style={{
                              borderTop: '1px solid #f6f6f6bf',
                            }}
                          />

                          {quesType?.enum !== 4 && (
                            <>
                              {options.length !== 1 && (
                                <button
                                  className="btn btn-danger btn-sm remove-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveClick(i);
                                  }}>
                                  {t('deleteRow')}
                                </button>
                              )}
                              <br />
                              <br />
                              {options.length - 1 === i && (
                                <button
                                  className="  btn
                                add-button"
                                  onClick={handleAddClick}>
                                  {t('addRow')}
                                </button>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}

                  {quesType &&
                    quesType?.enum !== 1 &&
                    quesType?.enum !== 5 &&
                    quesType?.enum !== 6 &&
                    quesType?.enum !== 7 &&
                    options?.length >= 1 &&
                    options.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <MultiLangInput
                            label={`Option ${i + 1}`}
                            placeholder={`Option ${i + 1}`}
                            value={item?.name}
                            disabled
                            isRequired
                          />
                          <MultiLangInput
                            label={`Filter ${i + 1}`}
                            placeholder={`Filter ${i + 1}`}
                            value={[item?.filter, item?.name]}
                            onChange={(value, lang) =>
                              handleOptionsChange(value, lang, i)
                            }
                            isRequired
                          />
                          <hr
                            style={{
                              borderTop: '1px solid #f6f6f6bf',
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  <Row form />
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() =>
                    history.push({
                      pathname: '/admin/filters',
                      search: history.location.search,
                      state: history.location.state,
                    })
                  }>
                  {t('cancel')}
                </button>
                <button
                  className="table-header-button"
                  onClick={submitHandler}
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('update')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
