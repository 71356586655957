import React, {useState} from 'react';

import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
  addCampaign
} from '../../../redux/actions/campaign.actions.js';
import {useHistory} from 'react-router';
import 'react-phone-input-2/lib/style.css';
import {useTranslation} from 'react-i18next';

const DashboardForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.usersReducer);

  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const {t} = useTranslation();

  const validateForm = () => !!name && !!path;

  const submitHandler = async () => {
      dispatch(
        addCampaign(
          {
            name,
            path
          },
          history,
        ),
      );
  };

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('addCampaign')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={12} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('name')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg={8} md={12} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('path')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder={t("enterPath")}
                          value={path}
                          onChange={(e) => setPath(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/users')}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
