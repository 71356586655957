import { chatReportsConstants } from '../constants';

const chatReportsReducer = (
  state = { chatReports: [], pendingChatReportsCount: 0 },
  action,
) => {
  switch (action.type) {
    case chatReportsConstants.CHAT_REPORTS_LOADING:
      return { ...state, loading: true };

    case chatReportsConstants.CHAT_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chatReports: action.payload.listing,
        count: action.payload.count,
      };

    case chatReportsConstants.PENDING_CHAT_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        pendingChatReportsCount: action.payload.count,
      };

    case 'LOGOUT':
      return {
        ...state,
        chatReports: [],
        loading: false,
        error: null,
      };

    case chatReportsConstants.CHAT_REPORTS_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default chatReportsReducer;
