import ChatReports from '../../views/pages/chat-reports';

let adRoutes = [
  {
    path: '/admin/chat-reports',
    name: 'chatReports',
    icon: 'report-icon',
    component: ChatReports,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
];

export default adRoutes;
