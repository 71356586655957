import {adReportsConstants} from '../constants';

const adReportsReducer = (
  state = {adReports: [], pendingReportsCount: 0},
  action,
) => {
  switch (action.type) {
    case adReportsConstants.AD_REPORTS_LOADING:
      return {...state, loading: true};

    case adReportsConstants.AD_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        adReports: action.payload.listing,
        count: action.payload.count,
      };

    case adReportsConstants.PENDING_AD_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        pendingReportsCount: action.payload.count,
      };

    case 'LOGOUT':
      return {
        ...state,
        adReports: [],
        loading: false,
        error: null,
      };

    case adReportsConstants.AD_REPORTS_ERROR:
      return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
};

export default adReportsReducer;
