import ChatBans from '../../views/pages/chatBans';
import ChatbanView from '../../views/pages/chatBans/viewChatBan';

let routes = [
  {
    path: '/admin/chatBans',
    name: 'chatBans',
    icon: 'chat-ban-icon',
    component: ChatBans,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/chatBans/:banId',
    name: 'ChatbanView',
    icon: 'chat-ban-icon',
    component: ChatbanView,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
];
export default routes;
