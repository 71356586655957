import React from 'react';
import {FormGroup, Input, InputGroup} from 'reactstrap';

export default function TextInput({
  value,
  type,
  label,
  name,
  placeholder,
  errorMessage,
  style,
  onChange,
  disabled,
}) {
  return (
    <div>
      <label> {label}</label>
      <br />
      <FormGroup>
        <InputGroup style={style}>
          <Input
            placeholder={placeholder}
            type={type}
            name={name}
            disabled={disabled}
            value={value || ''}
            invalid={!!errorMessage ? true : false}
            onChange={(e) => {
              e.preventDefault();
              onChange(e.target.value);
            }}
          />
        </InputGroup>
        {errorMessage && (
          <>
            <br />
            <div
              style={{
                color: 'red',
                fontSize: 12,
                marginTop: -15,
                marginLeft: 5,
              }}>
              * {errorMessage}
            </div>
          </>
        )}
      </FormGroup>
    </div>
  );
}
