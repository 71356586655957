import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {
  deleteCategory,
  editCategoryStatus,
  listCategories,
  suffleCategory,
} from '../../redux/actions/categories.actions';
import {categoriesConstants} from '../../redux/constants/categories.constants.js';
import {getImageUrl} from '../../utils/renderImage.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import SwitchSlider from '../Switch/SwitchSlider.js';

const Tables = () => {
  const {categories, loading, count} = useSelector(
    (state) => state.categoriesReducer,
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const navigateTo = (route) => history.push(route);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCategory(id));
    } else return;
  };

  const activeInactiveCategory = (id) => {
    dispatch(editCategoryStatus(id));
  };

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) =>
      dispatch(listCategories(postsPerPage, currentPage, searchKeyword)),
    [dispatch],
  );

  const onEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let clone = categories;
      let draggedLink = clone[sourceIdx];
      let newList = clone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);

      dispatch({
        type: categoriesConstants.CATEGORY_SUFFLE,
        payload: newList,
      });
      dispatch(
        suffleCategory({
          from: clone[sourceIdx]._id,
          to: clone[destIdx]._id,
        }),
      );
    }
  };

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const rows = categories.map((item) => [
    item?.name[lang],
    <img
      alt={'Gulf Workers'}
      className=".table-sub-category-icon"
      src={getImageUrl(item?.icon, 50, 50)}
    />,
    <SwitchSlider
      clicked={() => activeInactiveCategory(item?._id)}
      checked={item?.status === 1}
    />,
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={editIcon}
        onClick={() => navigateTo(`/admin/categories/editCategory/${item._id}`)}
      />
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={deleteIcon}
        onClick={() => deleteHandler(item?._id)}
      />
    </>,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[t('name'), t('icons'), t('status'), t('actions')]}
          rows={rows}
          loading={loading}
          totalCount={count}
          onDragEnd={onEnd}
          addClickHandler={() => navigateTo('/admin/categories/addCategory')}
          fetchData={fetchData}
          exportButton={
            <Export
              filename="categories"
              resource="listCategory"
              params={{search}}
              headers={[
                {label: 'Name', key: `name.${lang}`},
                {label: 'Status', key: 'status'},
              ]}
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
