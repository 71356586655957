export const areasConstants = {
  AREAS_LOADING: 'AREAS_LOADING',
  AREA_ERROR: 'AREA_ERROR',
  AREAS_LIST_SUCCESS: 'AREAS_LIST_SUCCESS',
  AREA_ADDED_SUCCESS: 'AREA_ADDED_SUCCESS',
  AREA_EDIT_SUCCESS: 'AREA_EDIT_SUCCESS',
  AREA_DELETE_SUCCESS: 'AREA_DELETE_SUCCESS',
  QUESTION_GET_SINGLE_SUCCESS: 'QUESTION_GET_SINGLE_SUCCESS:',
  QUESTION_RESET_SINGLE: 'QUESTION_RESET_SINGLE:',
  QUESTION_SUFFLE: 'QUESTION_SUFFLE:',
};
