import React, {useState, useEffect} from 'react';

import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Spinner,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {listCategories} from '../../../redux/actions/categories.actions.js';
import {userTypes} from '../../../utils/data';
import {useDispatch, useSelector} from 'react-redux';
import uploadIcon from '../../../assets/images/icons/form/upload-icon.png';
import {addFeatureNationality} from '../../../redux/actions/featureNationalities.actions';
import cogoToast from 'cogo-toast';
import {featureNationalitiesConstants} from '../../../redux/constants/featureNationalities.constants';
import {imageUploader, renderImage} from '../../../utils/imageUpload.js';
import {useTranslation} from 'react-i18next';
import {validateLanguagesObject} from '../../../utils/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';

const DashboardForm = ({history}) => {
  //redux
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categoriesReducer);
  const {loading} = useSelector((state) => state.countriesReducer);
  const [icon, setIcon] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [name, setName] = useState({
    en: '',
    hi: '',
    ar: '',
    fil: '',
  });
  const [aspectRatio, setAspactRatio] = useState();
  const {en, hi, ar, fil} = name;

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const inputFileHandler = (e) => setIcon(e.target?.files?.[0]);

  const onChangeHandler = (value, lang) => {
    setName({...name, [lang]: value});
  };

  const validateForm = () => validateLanguagesObject(name);

  const userTypeChangeHandler = (item) => {
    setUserType(item);
    setUserDropdownOpen(!userDropdownOpen);
  };

  const categoryChangeHandler = (i) => {
    setSelectedCategory(i);
    setCategoryDropdownOpen(!categoryDropdownOpen);
  };

  const submitHandler = async () => {
    if (aspectRatio === 1) {
      dispatch({
        type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING,
      });
      const formData = new FormData();
      formData.append('image', icon);

      const imageUrl = await imageUploader(formData);

      const payload = {
        name,
        isNationality: true,
        icon: imageUrl,
        categoryId: selectedCategory?._id,
        userType: userType?.enum,
      };

      if (imageUrl) {
        dispatch(addFeatureNationality(payload, history));
      } else {
        cogoToast.error('Something went wrong', {
          hideAfter: 3,
          position: 'top-right',
        });
      }
    } else {
      cogoToast.error('Please chose image with same width and height');
    }
  };

  const onLoad = ({target: {offsetHeight, offsetWidth}}) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">
                {t('addFeatureNationality')}
              </h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('userType')} </Label>
                        <InputGroup
                          onClick={() =>
                            setUserDropdownOpen(!userDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('userType')}
                            value={userType?.name}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={userDropdownOpen}
                            toggle={() =>
                              setUserDropdownOpen(!userDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userTypes?.map((item, i) => (
                                <DropdownItem
                                  key={i + 1}
                                  onClick={() => userTypeChangeHandler(item)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('category')}</Label>
                        <InputGroup
                          onClick={() =>
                            setCategoryDropdownOpen(!categoryDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('select') + t('category')}
                            value={selectedCategory?.name[lang]}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={categoryDropdownOpen}
                            toggle={() =>
                              setCategoryDropdownOpen(!categoryDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userType?._id === 3
                                ? categories
                                    ?.filter((c) => c?.catType === 1)
                                    .map((item) => (
                                      <DropdownItem
                                        key={item?._id}
                                        onClick={() =>
                                          categoryChangeHandler(item)
                                        }>
                                        {item?.name[lang]}
                                      </DropdownItem>
                                    ))
                                : categories
                                    ?.filter((c) => c?.catType !== 1)
                                    .map((item) => (
                                      <DropdownItem
                                        key={item?._id}
                                        onClick={() =>
                                          categoryChangeHandler(item)
                                        }>
                                        {item?.name[lang]}
                                      </DropdownItem>
                                    ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <MultiLangInput
                    label="Name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('icons')} </Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder="Ppload file"
                              onChange={inputFileHandler}>
                              {' '}
                            </Input>
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                            <div className="upload-icon">
                              <img
                                alt={'upload'}
                                style={{maxWidth: '15px'}}
                                src={uploadIcon}
                              />
                            </div>
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <br />
                      {icon && (
                        <img
                          src={renderImage(icon)}
                          className="input-image"
                          alt={'gcc'}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/feature-nationalities')}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
