export const adsConstants = {
  AD_LOADING: 'AD_LOADING',
  AD_ERROR: 'AD_ERROR',
  AD_LIST_SUCCESS: 'AD_LIST_SUCCESS',
  AD_ADD_SUCCESS: 'AD_ADD_SUCCESS',
  AD_EDIT_SUCCESS: 'AD_EDIT_SUCCESS',
  AD_DELETE_SUCCESS: 'AD_DELETE_SUCCESS',
  AD_GET_SINGLE_SUCCESS: 'AD_GET_SINGLE_SUCCESS:',
  AD_RESET_SINGLE: 'AD_RESET_SINGLE:',
  AD_HISTORY_FETCH_SUCCESS: 'AD_HISTORY_FETCH_SUCCESS'
};
