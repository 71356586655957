import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {areasConstants} from '../constants';
import {errorParser} from './errorParser';

export const listAreas =
  (perPage = 4, page = 1, search = '', countryId = '') =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: areasConstants.AREAS_LOADING});
    const url = `admin/v1/listAreas?perPage=${perPage}&page=${page}&search=${search}${
      countryId ? `&countryId=${countryId}` : ''
    }`;
    try {
      const {
        data: {data},
      } = await API.get(url);
      if (data) {
        dispatch({
          type: areasConstants.AREAS_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: areasConstants.AREA_ERROR,
        payload: parsedError,
      });
    }
  };

// export const getSingleQuestion = (id) => async (dispatch) => {
//   await headerSetup();
//   dispatch({type: areasConstants.QUESTION_LOADING});

//   try {
//     const {
//       data: {data},
//     } = await API.get(`admin/v1/question/${id}`);

//     if (data) {
//       dispatch({
//         type: areasConstants.QUESTION_GET_SINGLE_SUCCESS,
//         payload: data,
//       });
//     }
//   } catch (err) {
//     const parsedError = await errorParser(err);
//     dispatch({
//       type: areasConstants.QUESTION_ERROR,
//       payload: parsedError,
//     });
//   }
// };

export const addArea = (payload, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: areasConstants.AREAS_LOADING});
  try {
    const {
      data: {data},
    } = await API.post('admin/v1/addArea', payload);
    if (data) {
      dispatch({
        type: areasConstants.AREA_ADDED_SUCCESS,
        payload: data,
      });
      successAlert(`Area added successfully`);
      history.push({
        pathname: '/admin/areas',
        state: history.location.state,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: areasConstants.AREA_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editArea = (payload, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: areasConstants.AREAS_LOADING});
  try {
    const {
      data: {data},
    } = await API.put('admin/v1/editArea', payload);
    if (data) {
      dispatch({
        type: areasConstants.AREA_EDIT_SUCCESS,
        payload: data?.data,
      });
      successAlert(`${payload?.name} updated successfully`);
      history.push({
        pathname: `/admin/areas`,
        state: history.location.state,
        search: window.location.search,
      });
      dispatch({
        type: areasConstants.QUESTION_RESET_SINGLE,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: areasConstants.AREA_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const deleteArea = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: areasConstants.AREAS_LOADING});
  try {
    const resp = await API.delete(`admin/v1/deleteArea/${id}`);

    dispatch({
      type: areasConstants.AREA_DELETE_SUCCESS,
      payload: id,
    });
    successAlert(`user ${id} removed`);
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: areasConstants.AREA_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editQuestionStatus = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: areasConstants.QUESTION_LOADING});

  try {
    const {
      data: {data},
    } = await API.patch(`admin/v1/activeInactiveQuestion/${id}`);

    if (data) {
      dispatch({
        type: areasConstants.QUESTION_EDIT_SUCCESS,
        payload: data,
      });
    }
    successAlert(`Status updated for question~${id}`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: areasConstants.QUESTION_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editQuestionFilterStatus = (id, filter) => async (dispatch) => {
  await headerSetup();
  dispatch({type: areasConstants.QUESTION_LOADING});

  try {
    const {
      data: {data},
    } = await API.patch(`admin/v1/activeInactiveFeatureFilter/${id}/${filter}`);

    if (data) {
      dispatch({
        type: areasConstants.QUESTION_EDIT_SUCCESS,
        payload: data,
      });
    }
    successAlert(`Featured Filter Status updated for Question~${id}`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: areasConstants.QUESTION_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
export const suffleAreas = (formData) => async (dispatch) => {
  await headerSetup();
  const {
    postsPerPage,
    currentPage,
    searchKeyword,
    countryId,
    shuffleType,
  } = formData;
  dispatch({type: areasConstants.AREAS_LOADING});
  const ShuffleData = {
    from: formData.from,
    to: formData.to,
    shuffleType,
  };

  try {
    await API.put(`admin/v1/shuffleAreas`, ShuffleData);

    successAlert(`Areas rank updated successfully`);

    dispatch(
      listAreas(
        postsPerPage,
        currentPage,
        searchKeyword,
        countryId,
        !!shuffleType,
      ),
    );
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: areasConstants.AREA_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
