export const featureNationalitiesConstants = {
  FEATURE_NATIONALITY_LOADING: 'FEATURE_NATIONALITY_LOADING',
  FEATURE_NATIONALITY_LIST_SUCCESS: 'FEATURE_NATIONALITY_LIST_SUCCESS',
  FEATURE_NATIONALITY_ERROR: 'FEATURE_NATIONALITY_ERROR',
  FEATURE_NATIONALITY_ADD_SUCCESS: 'FEATURE_NATIONALITY_ADD_SUCCESS',
  NATIONALITY_SUFFLE: 'NATIONALITY_SUFFLE',
  NATIONALITY_DELETE_SUCCESS: 'NATIONALITY_DELETE_SUCCESS',
  NATIONALITY_GET_SINGLE_SUCCESS: 'NATIONALITY_GET_SINGLE_SUCCESS',
  FEATURE_NATIONALITY_EDIT_SUCCESS: 'FEATURE_NATIONALITY_EDIT_SUCCESS',
  FEATURE_NATIONALITY_RESET_SINGLE: 'FEATURE_NATIONALITY_RESET_SINGLE',


  COUNTRY_LOADING: 'COUNTRY_LOADING',
  COUNTRY_ERROR: 'COUNTRY_ERROR',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  COUNTRY_ADD_SUCCESS: 'COUNTRY_ADD_SUCCESS',
  COUNTRY_EDIT_SUCCESS: 'COUNTRY_EDIT_SUCCESS',
  COUNTRY_DELETE_SUCCESS: 'COUNTRY_DELETE_SUCCESS',
  COUNTRY_GET_SINGLE_SUCCESS: 'COUNTRY_GET_SINGLE_SUCCESS:',
  COUNTRY_RESET_SINGLE: 'COUNTRY_RESET_SINGLE:',
  COUNTRY_SUFFLE: 'COUNTRY_SUFFLE:',
};
