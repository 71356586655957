import {API, headerSetup} from '../../services/auth';
import {campaignConstants} from '../constants';
import {errorAlert, successAlert} from '../../utils/alerts';
import {errorParser} from './errorParser';

export const listCampaigns = () =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: campaignConstants.CAMPAIGN_LOADING});
    try {
      const {
        data: {data},
      } = await API.get('admin/v1/analytics');
      if (data) {
        dispatch({
          type: campaignConstants.CAMPAIGN_SUCCESS,
          payload: {listing: data, count: data.length},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: campaignConstants.CAMPAIGN_ERROR,
        payload: parsedError,
      });
    }
  };

  export const addCampaign = (formData, history) => async (dispatch) => {
    await headerSetup();
    dispatch({type: campaignConstants.CAMPAIGN_LOADING});
  
    try {
      const {
        data: {data},
      } = await API.post('admin/v1/campaign', formData);
  
      if (data) {
        dispatch({
          type: campaignConstants.CAMPAIGN_ADD_SUCCESS,
          payload: data,
        });
        successAlert(`campaign ${formData?.name} added successfully`);
        history.push('/admin/campaigns');
      }
    } catch (err) {
      const parsedError = await errorParser(err);
  
      dispatch({
        type: campaignConstants.CAMPAIGN_ERROR,
        payload: parsedError,
      });
      errorAlert(parsedError);
    }
  };