import featureNationalities from '../../views/pages/feature-nationalities';
import nationalityAdd from '../../views/pages/feature-nationalities/addNationality';
import nationalityEdit from '../../views/pages/feature-nationalities/editNationality';

let routes = [
  {
    path: '/admin/feature-nationalities',
    name: 'featureNationalities',
    icon: 'flag-icon',
    component: featureNationalities,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/feature-nationalities/addNationality',
    name: 'addFeatureNationality',
    icon: 'flag-icon',
    component: nationalityAdd,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  {
    path: '/admin/feature-nationalities/editNationality/:id',
    name: 'editFeatureNationality',
    icon: 'flag-icon',
    component: nationalityEdit,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
];

export default routes;
