import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {listCategories} from '../../redux/actions/categories.actions.js';
import {
  deleteNationality,
  editCountryStatus,
  listFeatureNationalities,
  suffleCountry,
} from '../../redux/actions/featureNationalities.actions';
import {featureNationalitiesConstants} from '../../redux/constants/featureNationalities.constants';
import {userTypes} from '../../utils/data';
import {getImageUrl} from '../../utils/renderImage.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';
import SwitchSlider from '../Switch/SwitchSlider.js';
import qs from 'qs';
import {replaceUrlSearchParams} from '../../utils';

const Tables = () => {
  const history = useHistory();
  const {categories: allCategories} = useSelector(
    (state) => state.categoriesReducer,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  const [user, setUser] = useState();
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);

  const {nationalities, loading, count} = useSelector(
    (state) => state.featureNationalitiesReducer,
  );
  const dispatch = useDispatch();

  const navigateTo = (route) => history.push(route);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteNationality(id));
    } else return;
  };

  const activeInactiveCountry = (id) => {
    dispatch(editCountryStatus(id));
  };

  const fetchData = useCallback(
    (currentPage, postsPerPage, search) => {
      if (user && categoryId)
        dispatch(
          listFeatureNationalities(
            postsPerPage,
            currentPage,
            search,
            user,
            categoryId,
          ),
        );
    },
    [dispatch, user, categoryId],
  );

  useEffect(() => {
    let tempCategories = [];
    allCategories?.forEach((category) => {
      if (user === 3 && category.catType === 1) tempCategories.push(category);
      else if (user !== 3 && category.catType !== 1)
        tempCategories.push(category);
    });
    setCategories(tempCategories);
    let {filtersQuery} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    if (!categoryId && !filtersQuery?.categoryId) {
      setCategoryId(tempCategories[0]?._id);
    }
  }, [allCategories, user]);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const onEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let countryClone = nationalities;
      let draggedLink = countryClone[sourceIdx];
      let newList = countryClone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);

      dispatch({
        type: featureNationalitiesConstants.NATIONALITY_SUFFLE,
        payload: newList,
      });
      dispatch(
        suffleCountry({
          from: countryClone[sourceIdx]._id,
          to: countryClone[destIdx]._id,
        }),
      );
    }
  };

  const handleUserClick = (newUserId) => {
    if (user !== newUserId) {
      setUser(newUserId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user: newUserId,
          categoryId,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const handleCategoryClick = (newCategoryId) => {
    if (categoryId !== newCategoryId) {
      setCategoryId(newCategoryId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user,
          categoryId: newCategoryId,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const rows = nationalities.map((item) => [
    item?.name[lang],
    <img
      alt={'Gulf Workers'}
      className=".table-sub-category-icon"
      src={getImageUrl(item?.icon, 50, 50)}
    />,
    <SwitchSlider
      clicked={() => activeInactiveCountry(item?._id)}
      checked={item?.status === 1}
      name={item?.id}
    />,
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={editIcon}
        onClick={() =>
          history.push({
            pathname: `/admin/feature-nationalities/editNationality/${item._id}`,
            search: window.location.search,
          })
        }
      />
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={deleteIcon}
        onClick={() => deleteHandler(item?._id)}
      />
    </>,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[t('name'), t('icons'), t('status'), t('actions')]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          onDragEnd={onEnd}
          addClickHandler={() =>
            navigateTo('/admin/feature-nationalities/addNationality')
          }
          fetchData={fetchData}
          exportButton={
            <Export
              filename="nationalities"
              resource="listCountry"
              params={{search}}
              headers={[
                {label: 'Name', key: `name.${lang}`},
                {label: 'Status', key: 'status'},
              ]}
            />
          }
          filters={{
            state: {user, categoryId},
            setState: ({user, categoryId}) => {
              handleUserClick(!isNaN(user) ? parseInt(user) : 1);
              handleCategoryClick(categoryId);
            },
            component: (
              <div className="cardScroll">
                <h6>User Type:</h6>
                <ScrollMenu>
                  {userTypes?.map((item, i) => (
                    <MenuItem
                      itemId={item._id}
                      title={item?.name}
                      key={item._id}
                      onClick={
                        user === item._id
                          ? null
                          : () => handleUserClick(item._id)
                      }
                      selected={user === item._id}
                      currentItem={user}
                    />
                  ))}
                </ScrollMenu>
                {user !== 4 && (
                  <>
                    <h6>Categories:</h6>
                    <ScrollMenu>
                      {categories?.map((item) => (
                        <MenuItem
                          itemId={item._id}
                          title={item?.name[lang]}
                          key={item._id}
                          onClick={
                            categoryId === item._id
                              ? null
                              : () => handleCategoryClick(item._id)
                          }
                          selected={categoryId === item._id}
                        />
                      ))}
                    </ScrollMenu>
                  </>
                )}
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
