export const contactUsConstants = {
  CONTACT_US_LOADING: 'CONTACT_US_LOADING',
  CONTACT_US_ERROR: 'CONTACT_US_ERROR',
  CONTACT_US_LIST_SUCCESS: 'CONTACT_US_LIST_SUCCESS',
  CONTACT_US_ADD_SUCCESS: 'CONTACT_US_ADD_SUCCESS',
  CONTACT_US_EDIT_SUCCESS: 'CONTACT_US_EDIT_SUCCESS',
  CONTACT_US_DELETE_SUCCESS: 'CONTACT_US_DELETE_SUCCESS',
  CONTACT_US_GET_SINGLE_SUCCESS: 'CONTACT_US_GET_SINGLE_SUCCESS:',
  CONTACT_US_RESET_SINGLE: 'CONTACT_US_RESET_SINGLE:',
  PENDING_CONTACT_REQUESTS_LIST_SUCCESS: 'PENDING_CONTACT_REQUESTS_LIST_SUCCESS',
  PENDING_CONTACT_REQUESTS_LIST_ERROR: 'PENDING_CONTACT_REQUESTS_LIST_ERROR',
};
