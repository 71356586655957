import React, {useState, useEffect} from 'react';

import Header from '../../Headers/Header.js';

import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {listCategories} from '../../../redux/actions/categories.actions.js';
import {addArea} from '../../../redux/actions/areas.actions.js';
import {useTranslation} from 'react-i18next';
import {emptyLanguagesObject} from '../../../constants/index.js';
import {validateLanguagesObject} from '../../../utils/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';

const DashboardForm = ({history}) => {
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.questionsReducer);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const countriesData = history.location.state.countries;

  const [name, setName] = useState({
    ...emptyLanguagesObject,
  });

  const validateForm = () => validateLanguagesObject(name) && selectedCountry;

  const countryChangeHandler = (item) => {
    setSelectedCountry(item);
    setUserDropdownOpen(!userDropdownOpen);
  };

  const onChangeHandler = (value, lang) => setName({...name, [lang]: value});

  const submitHandler = async () => {
    const payload = {
      name,
      countryId: selectedCountry._id,
    };
    dispatch(addArea(payload, history));
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('addArea')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">
                          {t('selectCountry')}{' '}
                        </Label>
                        <InputGroup
                          onClick={() =>
                            setUserDropdownOpen(!userDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('country')}
                            value={selectedCountry?.name[lang]}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={userDropdownOpen}
                            toggle={() =>
                              setUserDropdownOpen(!userDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {countriesData?.map((item, i) => (
                                <DropdownItem
                                  key={i + 1}
                                  onClick={() => countryChangeHandler(item)}>
                                  {item?.name[lang]}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                  <MultiLangInput
                    label="Area"
                    placeholder="Area"
                    value={name}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() =>
                    history.push({
                      pathname: '/admin/areas',
                      state: history.location.state,
                    })
                  }>
                  {t('cancel')}
                </button>
                <button
                  className="table-header-button"
                  onClick={submitHandler}
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
