import CampaignsPage from '../../views/pages/campaigns';
import AddCompaign from '../../views/pages/campaigns/addCompaign';

let routes = [
  {
    path: '/admin/campaigns',
    name: 'campaigns',
    icon: 'campaign-icon',
    component: CampaignsPage,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/campaigns/addCampaign',
    name: 'users',
    icon: 'campaign-icon',
    component: AddCompaign,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
];

export default routes;
