import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  Container,
} from 'reactstrap';
import CustomTable from '../../CustomTable/CustomTable.js';
import {getAdHistory} from '../../../redux/actions/ads.actions.js';
import {userTypes} from '../../../utils/data';
import {finder} from '../../../utils/dataHelpers.js';
import Header from '../../Headers/Header.js';
import SwitchSlider from '../../Switch/SwitchSlider';

const AdHistory = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const {loading, ads, count, ad} = useSelector((state) => state.adsReducer);

  useEffect(() => {
    dispatch(getAdHistory(id));
  }, [dispatch, id]);


  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const rows = ads.map((item) => [
    item?.adId,
    item?.userId?.name ? item?.userId?.name : 'N/A',
    moment(item?.createdAt).format('DD/MM/YYYY') + ' ' + moment(item?.createdAt).format('LT'),
    moment(item?.deletedAt).format('DD/MM/YYYY') + ' ' + moment(item?.deletedAt).format('LT') ,
    item?.deletedBy,
    finder(userTypes, item?.userType)?.name,
    item?.categoryId?.name[lang] ? item?.categoryId?.name[lang] : 'N/A',
    <SwitchSlider checked={item?.status === 1} />,
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
          <h2 className="custom-heading">{`${t('ad')} #${
            ad?.adId
          }`}</h2>
        <CustomTable
          head={[
            t('adTitle'),
            t('lastEditedBy'),
            t('lastEditAt'),
            t('status'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          hasSearch={false}
 
        />
      </Container>
    </>
  );
};

export default AdHistory;
