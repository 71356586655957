import moment from 'moment';
import React, {useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {Container, Tooltip} from 'reactstrap';
import {replaceUrlSearchParams} from '../../utils';
import queryString from 'query-string';
import filterIcon from '../../assets/images/filter.png';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import historyIcon from '../../assets/images/icons/table/history-icon.png';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import eyeIcon from '../../assets/images/icons/table/table-eye-icon.svg';
import {
  deleteAd,
  editAdStatus,
  listAds,
  listAdsByFilter,
} from '../../redux/actions/ads.actions';
import {userTypes} from '../../utils/data';
import {finder} from '../../utils/dataHelpers';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import AdsFilterModal from '../Modals/AdsFilterModal.js';
import HistoryModal from '../Modals/HistoryModal';
import SwitchSlider from '../Switch/SwitchSlider.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [searchKeyword] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredAds, setFilteredAds] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const clearData = () => {
    setUserType(null);
    setSelectedCategory(null);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: null,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const toggleHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const [isOpen, setIsOpen] = useState(false);
  const setModalOpen = () => setIsOpen(!isOpen);

  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();
  const {ads, loading, count} = useSelector((state) => state.adsReducer);

  const history = useHistory();
  const navigateTo = (route) => history.push(route);
  const queryData = queryString.parseUrl(history.location.search)?.query || {};

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword, _, filters, activeTab) => {
      if (filters?.userType && filters?.selectedCategory)
        dispatch(
          listAdsByFilter(
            postsPerPage,
            currentPage,
            parseInt(filters?.userType),
            filters?.selectedCategory,
            undefined,
            activeTab + 1,
          ),
        );
      else
        dispatch(
          listAds(
            postsPerPage,
            currentPage,
            searchKeyword,
            queryData.userId,
            activeTab + 1,
          ),
        );
    },
    [dispatch],
  );

  useEffect(() => {
    setFilteredAds(ads);
  }, [ads]);

  // useEffect(() => {
  //   const activeUsers = !!ads && ads.filter(user =>  user.status === 1);
  //   const inactiveUsers = !!ads && ads.filter(user =>  user.status === 2);
  //   const deletedUsers = !!ads && ads.filter(user =>  user.status === 3);
  //   setFilteredAds(activeTab === 0 ? activeUsers : activeTab === 1 ? inactiveUsers : deletedUsers);
  //   fetchData(currentPage, postsPerPage, '', null, null, activeTab);

  //   const query = replaceUrlSearchParams({
  //     page: 1,
  //   });
  //   history.push({
  //     pathname: window.location.pathname,
  //     search: `?${query}`,
  //   });
  //   setCurrentPage(1);
  // }, [activeTab])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteAd(id));
    } else return;
  };

  const activeInactiveAd = (id) => {
    dispatch(editAdStatus(id));
  };

  const onHistoryClick = (item) => {
    setHistoryData(item);
    toggleHistoryModal();
  };

  const rows = filteredAds.map((item) => [
    item?.adId,
    item?.userId?._id ? <Link to={`/admin/users/editUser/${item.userId._id}`}>{item.userId.userId}</Link> : 'N/A',
    item?.userId?.name ? item?.userId?.name : 'N/A',
    item?.whatsUniqueCounts || 0,
    item?.totalViews || 0,
    moment(item?.createdAt).format('DD/MM/YYYY') +
      ' ' +
      moment(item?.createdAt).format('LT'),
    activeTab === 2
      ? moment(item?.deletedAt).format('DD/MM/YYYY') +
        ' ' +
        moment(item?.deletedAt).format('LT')
      : '',
    activeTab === 2 ? item?.deletedBy : '',
    finder(userTypes, item?.userType)?.name,
    item?.categoryId?.name[lang] ? item?.categoryId?.name[lang] : 'N/A',
    <SwitchSlider
      clicked={() => activeInactiveAd(item?._id)}
      checked={item?.status === 1}
    />,
    <>
      {item.status != 3 ? (
        <>
          <img
            alt={'Gulf Workers'}
            className="td-action-img"
            src={eyeIcon}
            onClick={() =>
              navigateTo({
                pathname: `/admin/ads/viewAd/${item._id}`,
                search: window.location.search,
              })
            }
          />
          <img
            alt={'Gulf Workers'}
            className="td-action-img"
            src={editIcon}
            onClick={() => {
              history.push({
                pathname: `/admin/ads/editAd/${item._id}`,
                search: window.location.search,
              });
            }}
          />
          {item.editHistory && item.editHistory.length ? (
            <img
              alt={'Gulf Workers'}
              className="td-action-img"
              src={historyIcon}
              onClick={() => onHistoryClick(item.editHistory)}
            />
          ) : null}
          <img
            alt={'Gulf Workers'}
            className="td-action-img"
            src={deleteIcon}
            onClick={() => deleteHandler(item?._id)}
          />
        </>
      ) : null}
    </>,
  ]);

  const updateFiltersFromUrl = (filters) => {
    setUserType(!isNaN(filters?.userType) ? parseInt(filters?.userType) : null);
    setSelectedCategory(filters?.selectedCategory);
    const query = replaceUrlSearchParams({
      filtersQuery: filters,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
  };

  const handleCategoryChange = (newCategoryId) => {
    setSelectedCategory(newCategoryId);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: {
        selectedCategory: newCategoryId,
        userType,
      },
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
  };

  const handleUserTypeChange = (newUserType) => {
    setUserType(newUserType);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: {
        newUserType,
        selectedCategory,
      },
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      activeTab: newTab,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    fetchData(
      1,
      postsPerPage,
      '',
      null,
      {userType, selectedCategory},
      newTab,
    );
  };

  return (
    <>
      <Header cardsVisible={false} />
      <AdsFilterModal
        customProps={{
          open: isOpen,
          setModalOpen,
          userType,
          setUserType: handleUserTypeChange,
          selectedCategory,
          setSelectedCategory: handleCategoryChange,
          clearData,
          fetchData,
        }}
      />
      <HistoryModal
        toggle={toggleHistoryModal}
        modal={historyModal}
        data={historyData}
      />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('advertisementId'),
            t('userId'),
            t('name'),
            t('Unique Clicks'),
            t('Total Clicks'),
            t('creationDateTime'),
            activeTab === 2 ? t('deletionDateTime') : '',
            activeTab === 2 ? t('deletBy') : '',
            t('userType'),
            t('category'),
            t('status'),
            t('actions'),
          ]}
          rows={rows}
          setActiveTab={setActiveTab}
          handleTabChange={handleTabChange}
          showBtnTabs={true}
          activeTab={activeTab}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          addClickHandler={() => navigateTo('/admin/ads/addAd')}
          fetchData={fetchData}
          hasSearch={false}
          exportButton={
            <Export
              filename="ads"
              resource="listAdvertisement"
              dataType="ads"
              params={{
                status: activeTab + 1,
                userType: userType?.enum,
                categoryId: selectedCategory?._id,
                search: searchKeyword,
              }}
              headers={[
                {label: 'ID', key: 'adId'},
                {label: 'User ID', key: 'userId.userId'},
                {label: 'Name', key: 'userId.name'},
                {
                  label: 'Creation Date',
                  key: 'createdAt',
                  type: 'dateonly',
                },
                {
                  label: 'Creation Time',
                  key: 'createdAt',
                  type: 'timeonly',
                },
                {
                  label: 'User Type',
                  key: 'userType',
                  dataConverter: (val) => finder(userTypes, val)?.name,
                },
                {label: 'Category', key: `categoryId.name.${lang}`},
                {label: 'Status', key: 'status'},
                { label: activeTab === 2 ? 'Deleted By' : '', key: activeTab === 2 ? 'deletedBy' : '' },
                { label: activeTab === 2 ? 'Deleted At' : '', key: activeTab === 2 ? 'deletedAt' : '' }
              ]}
            />
          }
          filters={{
            state: {userType, selectedCategory},
            setState: updateFiltersFromUrl,
            component: (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}>
                <button
                  className="filter-button"
                  onClick={() => setModalOpen()}>
                  <img alt={'filter'} src={filterIcon} />
                  &nbsp; Filter
                </button>
                <div className={`${!userType ? 'hidden-filter' : ''}`}>
                  <p
                    id="TooltipExample"
                    onClick={() => {
                      dispatch(
                        listAds(
                          postsPerPage,
                          currentPage,
                          searchKeyword,
                          queryData.userId,
                          activeTab,
                        ),
                      );
                      clearData();
                    }}
                    style={{
                      margin: 0,
                      padding: 0,
                      color: 'red',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}>
                    X
                  </p>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="TooltipExample"
                    toggle={toggle}>
                    Clear Filters
                  </Tooltip>
                </div>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
