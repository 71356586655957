import React, {useCallback, useEffect} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import {Card, CardHeader, Row, Spinner, Table} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {errorParser} from '../../redux/actions/errorParser';
import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert} from '../../utils/alerts';
import FAQsModal from '../Modals/FAQsModal';

const FAQsTable = () => {
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [documentId, setDocumentId] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [questionIndex, setQuestionIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const getData = useCallback(async () => {
    setLoading(true);
    await headerSetup();
    try {
      const {
        data: {data: response},
      } = await API.get(`admin/v1/metadata?type=faqs`);
      setDocumentId(response._id);
      setQuestions(response.faqs);
    } catch (err) {
      const parsedError = await errorParser(err);
      errorAlert(parsedError);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      const newList = questions.filter((item) => item._id !== id);
      await headerSetup();
      try {
        await API.put(`admin/v1/metadata`, {_id: documentId, faqs: newList});
        setQuestions(newList);
        successAlert('FAQs updated successfully');
      } catch (err) {
        const parsedError = await errorParser(err);
        errorAlert(parsedError);
      }
    } else return;
  };

  const onEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let clone = questions;
      let draggedLink = clone[sourceIdx];
      let newList = clone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);
      setQuestions(newList);
      await headerSetup();
      try {
        await API.put(`admin/v1/metadata`, {_id: documentId, faqs: newList});
        successAlert('FAQs updated successfully');
      } catch (err) {
        setQuestions(clone);
        const parsedError = await errorParser(err);
        errorAlert(parsedError);
      }
    }
  };

  const toggleModal = () => setModal(!modal);

  const handleQuestionChange = (prop, lang, index, newValue) => {
    let tempQuestions = [...questions];
    tempQuestions[index][prop][lang] = newValue;
    setQuestions(tempQuestions);
  };

  return (
    <Row>
      <div className="col">
        <Card className="table-shadow">
          <CardHeader className="border-0 table-custom-header">
            <div className="table-header-actions">
              {loading && (
                <div className="table-loader">
                  <Spinner color={'info'} />
                </div>
              )}
              <div style={{display: 'flex', marginLeft: 'auto'}}>
                <button
                  onClick={() => {
                    setIsEdit(false);
                    toggleModal();
                  }}
                  className="mb-0 table-header-button">
                  {t('add')}
                </button>
              </div>
            </div>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light thead-custom">
              <tr>
                <th scope="col">{t('ques')}</th>
                <th scope="col">{t('answer')}</th>
              </tr>
            </thead>
            <>
              {!loading && questions?.length === 0 ? (
                <tbody>
                  <tr>
                    <td rowSpan={6} colSpan={6}>
                      {' '}
                      {t('noDataFound')}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <DragDropContext onDragEnd={onEnd}>
                    <Droppable droppableId={'questionList'}>
                      {(provided) => (
                        <tbody style={{width: '100%'}} ref={provided.innerRef}>
                          {questions?.map((item, index) => (
                            <Draggable
                              draggableId={`${index}`}
                              key={index}
                              index={index}>
                              {(provided, snapshot) => (
                                <tr
                                  className={`${
                                    snapshot.isDragging ? 'dragging' : ''
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={item?._id}>
                                  <td>{item?.title[lang]}</td>
                                  <td>{item?.answer[lang]}</td>
                                  <td>
                                    <img
                                      alt={'Gulf Workers'}
                                      className="td-action-img"
                                      src={editIcon}
                                      onClick={() => {
                                        setQuestionIndex(index);
                                        setIsEdit(true);
                                        toggleModal();
                                      }}
                                    />
                                    <img
                                      alt={'Gulf Workers'}
                                      className="td-action-img"
                                      src={deleteIcon}
                                      onClick={() => deleteHandler(item?._id)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </>
          </Table>
        </Card>
      </div>
      <FAQsModal
        documentId={documentId}
        isOpen={modal}
        toggleModal={toggleModal}
        questions={questions}
        addQuestion={(newQuestion) => setQuestions([...questions, newQuestion])}
        questionIndex={questionIndex}
        onChange={handleQuestionChange}
        isEdit={isEdit}
      />
    </Row>
  );
};

export default FAQsTable;
