import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Clipboard from '@react-native-clipboard/clipboard';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import queryString from 'query-string';
import {
  listCampaigns
} from '../../redux/actions/campaign.actions';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {campaigns, loading, count} = useSelector((state) => state.campaignsReducer);

  const history = useHistory();
  const navigateTo = (route) => history.push(route);
  const queryData = queryString.parseUrl(history.location.search)?.query || {};

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword, _, filters, activeTab) => {
      dispatch(
        listCampaigns(
          postsPerPage,
          currentPage,
          searchKeyword,
          queryData.userId,
          activeTab + 1,
        ),
      );
    },
    [dispatch],
  );

  const rows = campaigns.map((item) => [
    item?._id,
    item?.name.charAt(0).toUpperCase() + item?.name.slice(1),
    item?.clicks,
    item?.install,
    item?.register,
    item?.createAd,
    item?.viewAd,
    item?.startChat,
    moment(item?.createdAt).format('DD/MM/YYYY'),
    <div>
      <a href='#' onClick={() => {
        Clipboard.setString('https://gulfworkers.com/download/' + item.path);
        alert('Link has been copied.')
      }}>Copy URL</a>
    </div>
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('id'),
            t('name'),
            t('clicks'),
            t('installs'),
            t('register'),
            t('Ads Created'),
            t('View Ad'),
            t('Start Chat'),
            t('userType'),
            t('action'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          hasSearch={false}
          addClickHandler={() =>
            navigateTo({
              pathname: '/admin/campaigns/addCampaign',
              search: window.location.search,
            })
          }
        />
      </Container>
    </>
  );
};

export default Tables;
