import AdReports from '../../views/pages/ad-reports';

let adRoutes = [
  {
    path: '/admin/ad-reports',
    name: 'Ad Reports',
    icon: 'report-icon',
    component: AdReports,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
];

export default adRoutes;
