import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {chatsConstants} from '../constants';
import {errorParser} from './errorParser';
import { getPendingChatReports } from './chatReports.actions';

export const listChats =
  (perPage = 100, page = 1, search = '', order = -1) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: chatsConstants.CHAT_LOADING});

    try {
      const {
        data: {data},
      } = await API.get(
        `admin/v1/chatListing?perPage=${perPage}&page=${page}&search=${search}&order=${order}`,
      );
      console.log(data.length)
      if (data) {
        dispatch({
          type: chatsConstants.CHAT_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: chatsConstants.CHAT_ERROR,
        payload: parsedError,
      });
    }
  };

export const getSingleChat = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: chatsConstants.SINGLE_CHAT_LOADING});

  try {
    const {
      data: {data},
    } = await API.get(`admin/v1/chat/${id}`);

    if (data) {
      dispatch({
        type: chatsConstants.CHAT_GET_SINGLE_SUCCESS,
        payload: data
      });
    }
    dispatch(getPendingChatReports());
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: chatsConstants.CHAT_ERROR,
      payload: parsedError,
    });
  }
};

export const addChat = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: chatsConstants.CHAT_LOADING});

  try {
    const {
      data: {data},
    } = await API.post('admin/v1/addChat', formData);

    if (data) {
      dispatch({
        type: chatsConstants.CHAT_ADD_SUCCESS,
        payload: data,
      });
      successAlert(`Chat ${formData?.name} added successfullt`);
      history.push('/admin/chats');
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: chatsConstants.CHAT_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editChat = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: chatsConstants.CHAT_LOADING});

  try {
    const {
      data: {data},
    } = await API.put('admin/v1/editChat', formData);

    if (data) {
      dispatch({
        type: chatsConstants.CHAT_EDIT_SUCCESS,
        payload: data?.data,
      });
      successAlert(`${formData?.name} updated successfully`);

      history.push('/admin/chats');
      dispatch({
        type: chatsConstants.CHAT_RESET_SINGLE,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: chatsConstants.CHAT_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const deleteChat = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: chatsConstants.CHAT_LOADING});

  try {
    await API.delete(`admin/v1/deleteChat/${id}`);

    dispatch({
      type: chatsConstants.CHAT_DELETE_SUCCESS,
      payload: id,
    });
    warningAlert(`chat ${id} removed`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: chatsConstants.CHAT_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};