import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Container} from 'reactstrap';
import {listAdReports} from '../../redux/actions/adReports.actions';
import {replaceUrlSearchParams} from '../../utils';
import {adReportStatus, adReportStatusEnum} from '../../utils/data';
import CustomTable from '../CustomTable/CustomTable';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';
import {useHistory} from 'react-router-dom';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const [selectedStatus, setSelectedStatus] = useState(
    adReportStatusEnum.PENDING,
  );

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {adReports, loading, count} = useSelector(
    (state) => state.adReportsReducer,
  );

  const history = useHistory();

  const fetchData = useCallback(
    (currentPage, postsPerPage, search, sortDirection, {selectedStatus}) => {
      dispatch(listAdReports(postsPerPage, currentPage, selectedStatus));
    },
    [dispatch],
  );

  const toggleStatus = (newStatus, isFromUrl) => {
    let tempStatus;

    if (isFromUrl) tempStatus = parseInt(newStatus);
    else if (parseInt(selectedStatus) !== parseInt(newStatus))
      tempStatus = parseInt(newStatus);
    else tempStatus = undefined;

    setSelectedStatus(tempStatus);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: {
        selectedStatus: tempStatus,
      },
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });

    fetchData(currentPage, postsPerPage, undefined, undefined, {
      selectedStatus: tempStatus,
    });
  };

  const rows = adReports?.map((item, index) => [
    item?.reporter?.name,
    moment(item?.createdAt).format('DD/MM/YYYY'),
    moment(item?.createdAt).format('LT'),
    <Link to={`/admin/ads/editAd/${item?.ad._id}`}>{item?.ad.adId}</Link>,
    item?.ad?.title || 'N/A',
    item?.editedBy?.email || 'N/A',
    adReportStatus[item?.status] || 'N/A',
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('Reporter Name'),
            t('creationDate'),
            t('creationTime'),
            t('Ad ID'),
            t('Ad Title'),
            t('Edited by'),
            t('status'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          hasSearch={false}
          filters={{
            state: {selectedStatus},
            setState: ({selectedStatus}) => {
              toggleStatus(selectedStatus, true);
            },
            component: (
              <div className="cardScroll">
                <h6>Status</h6>
                <ScrollMenu>
                  {Object.keys(adReportStatus).map((status, i) => (
                    <MenuItem
                      itemId={status}
                      title={adReportStatus[status]}
                      key={`ad-report-status-${status}`}
                      display={'block'}
                      onClick={() => toggleStatus(status)}
                      selected={parseInt(status) === selectedStatus}
                    />
                  ))}
                </ScrollMenu>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
