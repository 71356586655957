import moment from 'moment';
import React, {useCallback, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import eyeIcon from '../../assets/images/icons/table/table-eye-icon.svg';
import {listChatReports} from '../../redux/actions/chatReports.actions';
import {replaceUrlSearchParams} from '../../utils';
import {chatReportStatus, chatReportStatusEnum} from '../../utils/data';
import CustomTable from '../CustomTable/CustomTable';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const [selectedStatus, setSelectedStatus] = useState(
    chatReportStatusEnum.PENDING,
  );

  const history = useHistory();

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {chatReports, loading, count} = useSelector(
    (state) => state.chatReportsReducer,
  );

  const navigateTo = (route) => history.push(route);

  const fetchData = useCallback(
    (currentPage, postsPerPage, search, sortDirection, {selectedStatus}) => {
      dispatch(listChatReports(postsPerPage, currentPage, selectedStatus));
    },
    [dispatch],
  );

  const toggleStatus = (newStatus, isFromUrl) => {
    let tempStatus;

    if (isFromUrl) tempStatus = parseInt(newStatus);
    else if (parseInt(selectedStatus) !== parseInt(newStatus))
      tempStatus = parseInt(newStatus);
    else tempStatus = undefined;

    setSelectedStatus(tempStatus);
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: {
        selectedStatus: tempStatus,
      },
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });

    fetchData(currentPage, postsPerPage, undefined, undefined, {
      selectedStatus: tempStatus,
    });
  };

  const reportedBy = (report, reportedBy) => {
    const reportedBySender = reportedBy === "sender";
    const reporter = reportedBySender ? report?.senderId?.name : report?.recieverId?.name;
    const reported = reportedBySender ? report?.recieverId?.name : report?.senderId?.name;
    const reportedAt = moment(reportedBySender ? report?.reportedBySender?.reportedAt : report?.reportedByReceiver?.reportedAt).format('DD/MM/YYYY hh:mm A');
    const status = reportedBySender ? report?.reportedBySender.status: report?.reportedByReceiver.status;
    
    return [
      reporter,
      reported,
      report?.adTitle,
      reportedAt,
      chatReportStatus[status] || 'N/A',
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={eyeIcon}
        onClick={() => navigateTo(`/admin/chats/${report?.conversationId}`)}
      />
    ];
  }

  let rows = [];
  chatReports?.forEach((item, index) => {
    if (item?.reportedBySender && (item.reportedBySender.status === selectedStatus || selectedStatus === undefined)) {
      rows.push(reportedBy(item, "sender"));
    }
    if (item?.reportedByReceiver && (item.reportedByReceiver.status === selectedStatus || selectedStatus === undefined)) {
      rows.push(reportedBy(item, "receiver"));
    }
  });


  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('reporter'),
            t('reported'),
            t('adTitle'),
            t('reportedAt'),
            t('status'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          hasSearch={false}
          filters={{
            state: {selectedStatus},
            setState: ({selectedStatus}) => {
              toggleStatus(selectedStatus, true);
            },
            component: (
              <div className="cardScroll">
                <h6>Status</h6>
                <ScrollMenu>
                  {Object.keys(chatReportStatus).map((status, i) => (
                    <MenuItem
                      itemId={status}
                      title={chatReportStatus[status]}
                      key={`chat-report-status-${status}`}
                      display={'block'}
                      onClick={() => toggleStatus(status)}
                      selected={parseInt(status) === selectedStatus}
                    />
                  ))}
                </ScrollMenu>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
