import cogoToast from 'cogo-toast';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import uploadIcon from '../../../assets/images/icons/form/upload-icon.png';
import {editAd, getSingleAd} from '../../../redux/actions/ads.actions.js';
import {listCategories} from '../../../redux/actions/categories.actions.js';
import {listCountries} from '../../../redux/actions/countries.actions';
import {listUsers} from '../../../redux/actions/users.actions.js';
import {adsConstants} from '../../../redux/constants/ads.constants.js';
import {isEmpty} from '../../../utils';
import {QuestionTypeEnums, UserTypeEnums, userTypes} from '../../../utils/data';
import {finder} from '../../../utils/dataHelpers.js';
import {imageUploader, renderImage} from '../../../utils/imageUpload.js';
import {getAdQuestions} from '../../../utils/questions.js';
import {getImageUrl} from '../../../utils/renderImage.js';
import Header from '../../Headers/Header.js';
import Dropdown from '../../Inputs/Dropdown/Dropdown';
import TextInput from '../../Inputs/TextInput/TextInput';
import SwitchSlider from '../../Switch/SwitchSlider';
import Question from './Question/Question';

const DashboardForm = () => {
  const {id} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categoriesReducer);
  const {loading, ad} = useSelector((state) => state.adsReducer);
  const {countries} = useSelector((state) => state.countriesReducer);
  const {users} = useSelector((state) => state.usersReducer);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [quesLoading, setQuesLoading] = useState(false);
  const [icon, setIcon] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [aspectRatio, setAspactRatio] = useState();
  const [isSafe, setIsSafe] = useState(true);
  const [countryCode, setCountryCode] = useState('+965');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    dispatch(getSingleAd(id));
    dispatch(listCategories());
    dispatch(listCountries());
    dispatch(listUsers(1000000));
  }, [dispatch, id]);

  useEffect(() => {
    if (!!ad?._id && ad?._id === id) {
      setUserType(finder(userTypes, ad?.userType)?.enum);
      setSelectedCategory(ad?.categoryId?._id);
      setSelectedUserAccount(ad?.userId?._id);
      setTitle(ad?.title);
      setDescription(ad?.description);
      setSelectedCountry(ad?.countryId?._id);
      setIsSafe(!ad?.reports?.length);
      resetQuestions();
      setPhone(ad?.whatsappPhoneNumber);
      setCountryCode(ad?.whatsappCallingCode || '+965');
    }
  }, [ad?._id]);

  useEffect(() => {
    if (
      ad?.additionalQuestion?.length &&
      ad?.userType === userType &&
      ad?.categoryId?._id === selectedCategory
    ) {
      resetQuestions();
    } else if (ad && (userType || selectedCategory)) {
      populateQuestions();
    }
  }, [userType, selectedCategory, ad]);

  const populateQuestions = async () => {
    setQuesLoading(true);
    const res = await getAdQuestions(userType, selectedCategory);
    setQuestions(res ? res : []);
    setQuesLoading(false);
  };

  const validateForm = () =>
    title &&
    userType &&
    countryValidate() &&
    selectedUserAccount &&
    validateQuestions();

  const countryValidate = () =>
    [UserTypeEnums.SERVICES].includes(userType) ? !!selectedCountry : true;

  const validateQuestions = () => {
    let isValid = true;
    questions.forEach((question) => {
      if (question?.isMandatory && isEmpty(question?.answer)) {
        isValid = false;
      }
    });
    return isValid;
  };

  const resetQuestions = () => {
    setQuestions(
      ad?.additionalQuestion?.length
        ? ad?.additionalQuestion?.map((el) => {
            return {
              ...el.questionId,
              answer: [
                QuestionTypeEnums.TEXT,
                QuestionTypeEnums.NATIONALITY,
              ].includes(el.questionId?.questionType)
                ? el.text
                : el.questionId?.questionType === QuestionTypeEnums.NUMBER
                ? el.num
                : [
                    QuestionTypeEnums.SINGLE_CHOICE,
                    QuestionTypeEnums.BOOLEAN,
                  ].includes(el.questionId?.questionType)
                ? el.optionId?.[0]?._id
                : el.questionId?.questionType ===
                  QuestionTypeEnums.MULTIPLE_CHOICE
                ? el.optionId?.map((el) => el?._id)
                : [],
            };
          })
        : [],
    );
  };

  const setAnswer = (questionIndex, questionType) => (newAnswer) => {
    let tempQuestions = [...questions];
    let tempQuestion = {...tempQuestions[questionIndex]};
    tempQuestion.answer = newAnswer;
    tempQuestions[questionIndex] = tempQuestion;
    setQuestions(tempQuestions);
  };

  const constructAnswersRequest = () => {
    return questions.map((question) => {
      return {
        questionId: question._id,
        text: [QuestionTypeEnums.TEXT, QuestionTypeEnums.NATIONALITY].includes(
          question.questionType,
        )
          ? question.answer
          : '',
        num: [QuestionTypeEnums.NUMBER].includes(question.questionType)
          ? question.answer
          : null,
        optionId: [
          QuestionTypeEnums.SINGLE_CHOICE,
          QuestionTypeEnums.BOOLEAN,
        ].includes(question.questionType)
          ? [question.answer]
          : question.questionType === QuestionTypeEnums.MULTIPLE_CHOICE
          ? question.answer
          : [],
      };
    });
  };

  const editWithIcon = async () => {
    dispatch({type: adsConstants.AD_LOADING});
    const formData = new FormData();
    formData.append('image', icon);
    const imageUrl = await imageUploader(formData);
    if (imageUrl) {
      dispatch(
        editAd(
          {
            icon: imageUrl,
            addId: id,
            userType: userType,
            additionalQuestion: constructAnswersRequest(),
            categoryId: selectedCategory,
            ...([UserTypeEnums.SERVICES].includes(userType) && {
              countryId: selectedCountry,
            }),
            userId: selectedUserAccount,
            title,
            description,
            whatsappCallingCode: phone ? countryCode : '',
            whatsappPhoneNumber: phone,
            reports: ad?.reports || [],
            ...(ad?.reports?.length && isSafe ? {isSafe: true} : {}),
          },
          history,
        ),
      );
    } else {
      // pop and error
    }
  };
  const editWithoutIcon = async () => {
    dispatch(
      editAd(
        {
          icon: ad?.icon,
          addId: id,
          userType: userType,
          additionalQuestion: constructAnswersRequest(),
          categoryId: selectedCategory,
          ...([UserTypeEnums.SERVICES].includes(userType) && {
            countryId: selectedCountry,
          }),
          userId: selectedUserAccount,
          whatsappCallingCode: phone ? countryCode : '',
          whatsappPhoneNumber: phone,
          title,
          description,
          reports: ad?.reports || [],
          ...(ad?.reports?.length && isSafe ? {isSafe: true} : {}),
        },
        history,
      ),
    );
  };

  const submitHandler = async () => {
    if (icon && aspectRatio !== 1) {
      cogoToast.error('Please chose image with same width and height', {
        hideAfter: 5,
        position: 'top-right',
        heading: 'Error',
      });
    } else if (!validateForm()) {
      cogoToast.error('Please fill all required fields', {
        hideAfter: 5,
        position: 'top-right',
        heading: 'Error',
      });
    } else {
      icon ? editWithIcon() : editWithoutIcon();
    }
  };

  const onLoad = ({target: {offsetHeight, offsetWidth}}) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };
  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const questionColSpan = 6;

  const phoneInputHandler = (number, data) => {
    setCountryCode('+' + data?.dialCode);
    setPhone(number.slice(data.dialCode.length));
  };

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{`${t('editAd')} #${
                ad?.adId
              }`}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <Dropdown
                        label={t('userType')}
                        placeholder={`Select ${t('userType')}`}
                        options={userTypes?.map((el) => ({
                          name: el.name,
                          value: el.enum,
                        }))}
                        value={userType}
                        onChange={(newValue) => setUserType(newValue)}
                        errorMessage={!userType && t('requiredField')}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <Dropdown
                        label={t('category')}
                        placeholder={`Select ${t('category')}`}
                        options={categories?.map((el) => ({
                          name: el.name[lang],
                          value: el._id,
                        }))}
                        value={selectedCategory}
                        onChange={(newValue) => setSelectedCategory(newValue)}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword"> {t('icons')} </Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder={t('uploadPlaceholder')}
                              onChange={(e) => setIcon(e.target?.files?.[0])}
                            />
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                          </label>

                          <div className="upload-icon">
                            <img
                              alt={'upload'}
                              style={{maxWidth: '15px'}}
                              src={uploadIcon}
                            />
                          </div>
                        </InputGroup>
                      </FormGroup>
                      {ad?.icon && (
                        <>
                          <br />
                          <img
                            style={{
                              position: 'relative',
                              left: '104px',
                            }}
                            alt={'Gulf wrokers'}
                            src={getImageUrl(ad?.icon, 150, 150)}
                          />
                        </>
                      )}
                      {icon && (
                        <img
                          src={renderImage(icon)}
                          className="input-image"
                          alt={'gcc'}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row form>
                    {[UserTypeEnums.SERVICES].includes(userType) &&
                      userType && (
                        <Col lg={4} md={6} sm={12}>
                          <Dropdown
                            label={t('country')}
                            placeholder={`Select country`}
                            options={countries?.map((el) => ({
                              name: el.name[lang],
                              value: el._id,
                            }))}
                            value={selectedCountry}
                            onChange={(newValue) =>
                              setSelectedCountry(newValue)
                            }
                            errorMessage={
                              !countryValidate() && t('requiredField')
                            }
                          />
                        </Col>
                      )}
                    <Col lg={4} md={6} sm={12}>
                      <Dropdown
                        label={t('user')}
                        placeholder={`Select ${t('user')}`}
                        options={users?.map((el) => ({
                          name: el.name,
                          value: el._id,
                        }))}
                        value={selectedUserAccount}
                        onChange={(newValue) =>
                          setSelectedUserAccount(newValue)
                        }
                        errorMessage={
                          !selectedUserAccount && t('requiredField')
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <label>Whatsapp Number</label>
                      <PhoneInput
                        containerStyle={{
                          border: '1px solid #707070',
                          marginBottom: 20,
                        }}
                        searchStyle={{
                          width: '100%',
                        }}
                        inputStyle={{
                          width: '100%',
                          direction: 'ltr',
                        }}
                        country={'kw'}
                        value={countryCode + phone}
                        onChange={(phone, countryData) =>
                          phoneInputHandler(phone, countryData)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <TextInput
                        label={t('title')}
                        placeholder={`${t('select')} ${t('title')}`}
                        value={title}
                        onChange={setTitle}
                        errorMessage={!title && t('requiredField')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <TextInput
                        label={t('Description')}
                        placeholder={`${t('Enter description')}`}
                        value={description}
                        type="textarea"
                        onChange={setDescription}
                      />
                    </Col>
                  </Row>
                  {ad?.reports?.length ? (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Label
                        for="exampleEmail"
                        style={{marginBottom: 0, marginRight: 10}}>
                        {t('Mark as Safe')}
                      </Label>
                      <SwitchSlider
                        clicked={() => setIsSafe((prev) => !prev)}
                        checked={isSafe}
                      />
                    </div>
                  ) : null}
                  <br />
                  <hr />
                  <br />
                  <h4> {t('otherDetails')}</h4>
                  <br />
                  <br />
                  {quesLoading && <Spinner color={'info'} />}
                  {!quesLoading && questions?.length === 0 ? (
                    <p>{t('noQuesData')}</p>
                  ) : (
                    <Row form>
                      {questions?.map((item, index) => (
                        <Col
                          style={{marginBottom: '100px'}}
                          key={index}
                          lg={questionColSpan}
                          md={12}
                          sm={12}>
                          <Question
                            data={item}
                            index={index}
                            setAnswer={setAnswer(index, item?.questionType)}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/admin/ads',
                      search: window.location.search,
                    });
                  }}
                  className="form-cancel-button">
                  {t('cancel')}
                </button>
                <button onClick={submitHandler} className="table-header-button">
                  {loading ? <Spinner /> : t('update')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
