export const languages = ['en', 'ar', 'hi', 'fil', 'si', 'ne', 'ta'];

export const emptyLanguagesObject = {
  en: '',
  ar: '',
  hi: '',
  fil: '',
  si: '',
  ne: '',
  ta: ''
};

export const languageNames = {
  en: {
    en: 'English',
    ar: 'Arabic',
    hi: 'Hindi',
    fil: 'Filipino',
    si: 'Sinhala',
    ne: 'Nepali',
    ta: 'Tamil'
  },
};
