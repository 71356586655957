import {API, headerSetup} from '../../services/auth';
import {featureNationalitiesConstants} from '../constants';
import {errorParser} from './errorParser';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';

export const listFeatureNationalities = (perPage = 15, page = 1, search = '', user = 1, categoryId = '60f41f4aaa96bbc2a0e161ec',) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});
    const isNationality = true;
    try {
      let url = `common/v1/listFeatureNationalities?perPage=${perPage}&isNationality=${isNationality}&page=${page}&search=${search}`;
      if(categoryId) url = url + `&categoryId=${categoryId}`;
      if(user) url = url + `&userType=${user}`;

      const {
        data: {data},
      } = await API.get(url);
      if (data) {
        dispatch({
          type: featureNationalitiesConstants.FEATURE_NATIONALITY_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
        payload: parsedError,
      });
      errorAlert(parsedError);
    }
  };

export const getSingleFeatureNationality = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});

  try {
    const {
      data: {data},
    } = await API.get(`admin/v1/country?countryId=${id}`);
    if (data) {
      dispatch({
        type: featureNationalitiesConstants.NATIONALITY_GET_SINGLE_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const addFeatureNationality = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});

  try {
    const {
      data: {data},
    } = await API.post('admin/v1/addFeatureNationality', formData);

    if (data) {
      dispatch({
        type: featureNationalitiesConstants.FEATURE_NATIONALITY_ADD_SUCCESS,
        payload: data,
      });
      successAlert(`Feature nationality added successfully`);

      history.push('/admin/feature-nationalities');
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editFeatureNationality = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});

  try {
    const {
      data: {data},
    } = await API.put('admin/v1/editFeatureNationality', formData);

    if (data) {
      dispatch({
        type: featureNationalitiesConstants.FEATURE_NATIONALITY_EDIT_SUCCESS,
        payload: data?.data,
      });

      successAlert(`Feature nationality updated successfully`);
      history.push({pathname: '/admin/feature-nationalities', search: window.location.search});
      dispatch({
        type: featureNationalitiesConstants.FEATURE_NATIONALITY_RESET_SINGLE,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const deleteNationality = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});

  try {
    await API.delete(`admin/v1/deleteCountry/${id}`);

    dispatch({
      type: featureNationalitiesConstants.NATIONALITY_DELETE_SUCCESS,
      payload: id,
    });

    warningAlert(`Country~${id} deleted`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editCountryStatus = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});

  try {
    const {
      data: {data},
    } = await API.patch(`admin/v1/activeInnactiveCountry/${id}`);

    if (data) {
      dispatch({
        type: featureNationalitiesConstants.COUNTRY_EDIT_SUCCESS,
        payload: data,
      });
    }
    successAlert(`Status updated for Country~${id}`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const suffleCountry = (formData) => async (dispatch) => {
  await headerSetup();
  dispatch({type: featureNationalitiesConstants.COUNTRY_LOADING});

  try {
    await API.put(`admin/v1/suffleCountry`, formData);

    successAlert(`Rank updated for Country`);

    dispatch(listFeatureNationalities());
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
