import {API, headerSetup} from '../../services/auth';
import { errorAlert } from '../../utils/alerts';
import {contactUsConstants} from '../constants';
import {errorParser} from './errorParser';

export const listContactUs =
  (perPage = 15, page = 1, search = '', status) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: contactUsConstants.CONTACT_US_LOADING});

    try {
      const {
        data: {data},
      } = await API.get(`admin/v1/listContactUsRequest`, {
        params: {
          perPage,
          page,
          search,
          status: status == 0 ? undefined : status,
        },
      });

      if (data) {
        dispatch({
          type: contactUsConstants.CONTACT_US_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: contactUsConstants.CONTACT_US_ERROR,
        payload: parsedError,
      });
    }
  };

export const getPendingContactRequests =
  (perPage = 99, page = 1) =>
  async (dispatch) => {
    await headerSetup();
    try {
      const {
        data: {data},
      } = await API.get(`admin/v1/listContactUsRequest`, {
        params: {
          perPage,
          page,
          status: 1,
        },
      });
      if (data) {
        dispatch({
          type: contactUsConstants.PENDING_CONTACT_REQUESTS_LIST_SUCCESS,
          payload: {count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: contactUsConstants.PENDING_CONTACT_REQUESTS_LIST_ERROR,
        payload: parsedError,
      });
      errorAlert(parsedError);
    }
  };
