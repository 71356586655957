export const countriesConstants = {
  COUNTRY_LOADING: 'COUNTRY_LOADING',
  COUNTRY_ERROR: 'COUNTRY_ERROR',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  COUNTRY_ADD_SUCCESS: 'COUNTRY_ADD_SUCCESS',
  COUNTRY_EDIT_SUCCESS: 'COUNTRY_EDIT_SUCCESS',
  COUNTRY_DELETE_SUCCESS: 'COUNTRY_DELETE_SUCCESS',
  COUNTRY_GET_SINGLE_SUCCESS: 'COUNTRY_GET_SINGLE_SUCCESS:',
  COUNTRY_RESET_SINGLE: 'COUNTRY_RESET_SINGLE:',
  COUNTRY_SUFFLE: 'COUNTRY_SUFFLE:',
};
