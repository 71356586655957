import moment from 'moment';
import qs from 'qs';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { listChats } from '../../redux/actions/chats.actions.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';

const Tables = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { chatrooms, loading, count } = useSelector(
    (state) => state.chatsReducer,
  );

  const navigateTo = (route) => history.push(route);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword, sortDirection) =>
      dispatch(
        listChats(postsPerPage, currentPage, searchKeyword, sortDirection),
      ),
    [dispatch],
  );

  const rows = chatrooms.map((item) => [
    <div
      className="userName"
      onClick={() =>
        item.metadata.senderId && navigateTo(`/admin/users/editUser/${item.metadata.senderId}`)
      }>
      {' '}
      {item.senderData[0]?.userId ? item?.senderData[0]?.userId : 'N/A'}
    </div>,
    <div
      className="userName"
      onClick={() =>
        item.metadata.senderId && navigateTo(`/admin/users/editUser/${item.metadata.senderId}`)
      }>
      {' '}
      {item.senderData[0]?.name ? item?.senderData[0]?.name : 'N/A'} ({item.metadata.messagesCounter ? item.metadata.messagesCounter[item.senderData[0]?._id] : 0})
    </div>,
    <div
      className="userName"
      onClick={() =>
        item.metadata.recieverId &&
        navigateTo(`/admin/users/editUser/${item.metadata.recieverId}`)
      }>
      {item?.recieverData[0]?.userId ? item?.recieverData[0]?.userId : 'N/A'}
    </div>,
    <div
      className="userName"
      onClick={() =>
        item.metadata.recieverId &&
        navigateTo(`/admin/users/editUser/${item.metadata.recieverId}`)
      }>
      {item?.recieverData[0]?.name ? item?.recieverData[0]?.name : 'N/A'} ({item.metadata.messagesCounter ? item.metadata.messagesCounter[item.recieverData[0]?._id] : 0})
    </div>,
    item.count || 0,
    <div>
      <div>{moment(item.metadata.createdAt).format('DD/MM/YYYY')}</div>
      <div>{moment(item.metadata.createdAt).format('hh:mm A')}</div>
    </div>,
    <div>
      <div>{moment(item.metadata.lastMessageSentAt).format('DD/MM/YYYY')}</div>
      <div>{moment(item.metadata.lastMessageSentAt).format('hh:mm A')}</div>
    </div>,
    <div
      className="userName"
      onClick={() =>
        item?.metadata?.adId &&
        navigateTo(`/admin/ads/editAd/${item.metadata.adId}`)
      }>
      {item?.metadata?.adTitle?.trim() ? item?.metadata?.adTitle.trim() : 'N/A'}
    </div>,
    <button
      className="chats-view-btn"
      onClick={() => navigateTo(`/admin/chats/${item._id}`)}>
      {t('view')}
    </button>,
  ]);

  const search = useMemo(() => {
    const { search } = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('fromUserId'),
            t('fromUser'),
            t('toUserId'),
            t('toUser'),
            t('numOfMessages'),
            <div>
              <div>{t('firstMsg')}</div>
              <div>{t('sentTime')}</div>
            </div>,
            <div>
              <div>{t('lastMsg')}</div>
              <div>{t('sentTime')}</div>
            </div>,
            t('adTitle'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          hasSortDirectionSelect={true}
          exportButton={
            <Export
              filename="chats"
              resource="chatListing"
              params={{ search }}
              dataType="chats"
              headers={[
                { label: 'From User ID', key: 'senderUserId' },
                { label: 'From User', key: 'senderName' },
                { label: 'Sender Count', key: 'senderCount' },
                { label: 'To User ID', key: 'recieverUserId' },
                { label: 'To User', key: 'recieverName' },
                { label: 'Receiver Count', key: 'receiverCount' },
                { label: 'Total Messages', key: 'count' },
                { label: 'Ad Title', key: 'metadata.adTitle' },
                { label: 'First Message Sent At', key: 'metadata.createdAt', type: 'datetime' },
                { label: 'Last Message Sent At', key: 'metadata.lastMessageSentAt', type: 'datetime' },
              ]}
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
