import React, {useState} from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
} from 'reactstrap';

export default function Dropdown({
  value,
  options,
  label,
  placeholder,
  errorMessage,
  style,
  onChange,
  disabled
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div style={style}>
      <FormGroup>
        <Label for="examplePassword">{label}</Label>
        <InputGroup>
          <Input
            readOnly
            placeholder={placeholder}
            value={
              [null, undefined].includes(value)
                ? ''
                : options?.length && typeof options[0] === 'object'
                ? options.find((option) => option.value === value)?.name
                : value
            }
            invalid={!!errorMessage ? true : false}
          />
          <InputGroupButtonDropdown
            className={disabled && 'disabled-dropdown-btn-color'}
            addonType="append"
            isOpen={dropdownOpen}
            toggle={() => !disabled && setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle>
              <p>{'>'}</p>
            </DropdownToggle>
            <DropdownMenu>
              {options?.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    onChange(
                      typeof option === 'object' ? option.value : option,
                    );
                  }}>
                  {typeof option === 'object' ? option.name : option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
        </InputGroup>
        {errorMessage && (
          <>
            <br />
            <div
              style={{
                color: 'red',
                fontSize: 12,
                marginTop: -15,
                marginLeft: 5,
              }}>
              * {errorMessage}
            </div>
          </>
        )}
      </FormGroup>
    </div>
  );
}
