import qs from 'qs';
import {default as React, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Card, CardFooter, CardHeader, Row, Spinner, Table} from 'reactstrap';
import {replaceUrlSearchParams} from '../../utils';
import Pagination from '../Pagination/paginate';

const CustomTable = (props) => {
  let {
    head,
    rows,
    loading,
    currentPage = 1,
    setCurrentPage = () => {},
    postsPerPage,
    totalCount,
    onDragEnd,
    exportButton,
    importButton,
    filters = {
      position: 'second-row',
    },
    addClickHandler,
    addButtonText,
    fetchData,
    hasSortDirectionSelect,
    hasSearch = true,
    activeTab,
    setActiveTab = () => {},
    handleTabChange = () => {},
    showBtnTabs,
    dense = false,
  } = props;

  const {position = 'second-row'} = filters;

  const [rowsPerPage] = useState(postsPerPage || 10000);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortDirection, setSortDirection] = useState(-1);

  const history = useHistory();
  const {t} = useTranslation();

  React.useEffect(() => {
    (async () => {
      try {
        let {search, page, sortDirection, filtersQuery, activeTab} = qs.parse(
          window.location.search?.split('?')[1]
            ? window.location.search?.split('?')[1]
            : 'search=',
        );
        search = search ? search : '';
        sortDirection = sortDirection ? sortDirection : -1;
        filtersQuery = filtersQuery ? filtersQuery : filters?.state;
        activeTab = activeTab ? parseInt(activeTab) : 0;
        setActiveTab(activeTab);
        setSearchKeyword(search);
        setSortDirection(sortDirection);

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }
        await fetchData(
          page,
          rowsPerPage,
          search,
          sortDirection,
          filtersQuery,
          activeTab,
        );
      } catch (err) {}
    })();
  }, [fetchData, rowsPerPage]);

  React.useEffect(() => {
    let {filtersQuery} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    filtersQuery = filtersQuery ? filtersQuery : filters?.state;
    if (filters?.setState) filters.setState(filtersQuery);
  }, []);

  const searchChangeHandler = (event) => {
    const query = replaceUrlSearchParams({
      search: event.target.value,
      page: 1,
    });
    setSearchKeyword(event.target.value);
    setCurrentPage(1);
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    fetchData(
      1,
      rowsPerPage,
      event.target.value,
      sortDirection,
      filters?.state,
      activeTab,
    );
  };

  const paginate = (pageNumber) => {
    const query = replaceUrlSearchParams({
      page: pageNumber,
    });
    setCurrentPage(pageNumber);
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    fetchData(
      pageNumber,
      rowsPerPage,
      searchKeyword,
      sortDirection,
      filters?.state,
      activeTab,
    );
  };

  const sortDirectionHandler = (event) => {
    const query = replaceUrlSearchParams({
      sortDirection: event.target.value,
      page: 1,
    });
    setSortDirection(event.target.value);
    setCurrentPage(1);
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    fetchData(
      1,
      rowsPerPage,
      searchKeyword,
      event.target.value,
      filters?.state,
      activeTab,
    );
  };

  return (
    <Row>
      <div className="col">
        <Card className="table-shadow">
          <CardHeader className="border-0 table-custom-header">
            <div className="table-header-actions">
              {hasSearch ? (
                <input
                  placeholder={t('search')}
                  className="table-header-input"
                  type={'text'}
                  value={searchKeyword}
                  onChange={searchChangeHandler}
                />
              ) : (
                filters?.component
              )}
              {loading && (
                <div className="table-loader">
                  <Spinner color={'info'} />
                </div>
              )}
              <div style={{display: 'flex', marginLeft: 'auto'}}>
                {importButton ? importButton : null}
                {exportButton ? exportButton : null}
                {hasSortDirectionSelect && (
                  <select
                    className="mb-0 table-header-button"
                    value={sortDirection}
                    onChange={sortDirectionHandler}>
                    <option value="-1">Newer First</option>
                    <option value="1">Oldest First</option>
                  </select>
                )}
                {addClickHandler && (
                  <button
                    className="mb-0 table-header-button"
                    onClick={addClickHandler}>
                    {addButtonText ? addButtonText : t('add')}
                  </button>
                )}
                {filters?.component &&
                  position === 'first-row' &&
                  filters.component}
              </div>
            </div>
            {showBtnTabs && (
              <div className="flex-center btngroup-container">
                <button
                  className={
                    activeTab === 0
                      ? 'btngroup-btn-active'
                      : 'btngroup-btn-inactive'
                  }
                  onClick={() => handleTabChange(0)}>
                  Active
                </button>
                <button
                  className={
                    activeTab === 1
                      ? 'btngroup-btn-active'
                      : 'btngroup-btn-inactive'
                  }
                  onClick={() => handleTabChange(1)}>
                  Inactive
                </button>
                <button
                  className={
                    activeTab === 2
                      ? 'btngroup-btn-active'
                      : 'btngroup-btn-inactive'
                  }
                  onClick={() => handleTabChange(2)}>
                  Deleted
                </button>
              </div>
            )}
          </CardHeader>
          {filters?.component && position === 'second-row' && hasSearch && (
            <div className="cardScroll mx-4 my-4">{filters.component}</div>
          )}
          <Table
            style={{width: '100%'}}
            className="align-items-center table-flush"
            responsive>
            <thead className="thead-light thead-custom">
              <tr>
                {head?.map((item, index) => (
                  <th
                    style={dense ? {padding: '10px 3px'} : {}}
                    key={index}
                    scope="col">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <>
              {!loading && rows?.length === 0 ? (
                <tbody>
                  <tr>
                    <td rowSpan={6} colSpan={6}>
                      {' '}
                      {t('noDataFound')}
                    </td>
                  </tr>
                </tbody>
              ) : onDragEnd ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={'questionList'}>
                    {(provided) => (
                      <tbody style={{width: '100%'}} ref={provided.innerRef}>
                        {rows?.map((row, rowIndex) => (
                          <Draggable
                            draggableId={`id-${rowIndex}`}
                            key={rowIndex}
                            index={rowIndex}>
                            {(provided, snapshot) => (
                              <tr
                                className={`${
                                  snapshot.isDragging ? 'dragging' : ''
                                }`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={rowIndex}>
                                {row?.map((col, colIndex) => (
                                  <td key={colIndex}>{col}</td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                rows?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row?.map((col, colIndex) => (
                      <td
                        style={dense ? {padding: '5px 3px'} : {}}
                        key={colIndex}>
                        {col}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </>
          </Table>
          <CardFooter className="py-4">
            {totalCount > rowsPerPage && (
              <Pagination
                postsPerPage={rowsPerPage}
                totalPosts={totalCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
              />
            )}
          </CardFooter>
        </Card>
      </div>
    </Row>
  );
};

export default CustomTable;
