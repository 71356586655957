import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import {getMaterialFileIcon} from 'file-extension-icon-js';
import React from 'react';
import { getSizeString } from '../../../utils';

export default function FileCard(props) {
  const {name, size, url} = props;

  return (
    <div style={styles.root}>
      <img
        style={styles.icon}
        src={`${getMaterialFileIcon(name)}`}
        alt="..."
        width="24"
      />
      <div style={styles.infoContainer}>
        <p style={styles.name}>{name}</p>
        {size ? <p style={styles.size}>{getSizeString(size)}</p> : null}
      </div>
      <div style={styles.buttonsContainer}>
        {url ? (
          <a target="_blank" href={url} download>
            <CloudDownloadRoundedIcon style={styles.downloadIcon} />
          </a>
        ) : null}
      </div>
    </div>
  );
}

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    // boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
    backgroundColor: 'white',
    height: 60,
    margin: '10px 0px',
    padding: '6px',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
    maxHeight: 50,
    width: 30,
    objectFit: 'contain',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 75px)',
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
  size: {
    fontSize: 13,
    fontWeight: 200,
    margin: 0,
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto',
  },
  downloadIcon: {
    color: 'rgba(33, 150, 245, 0.26)',
    cursor: 'pointer',
    transition: 'all 0.3s',
    marginRight: 10,
    height: 28,
    width: 28,
    '&:hover': {
      color: "rgb(33, 150, 245)"
    },
  },
};
