const AddType = [
  {
    _id: 1,
    enum: 1,
    addType: 'Plain Ad',
  },
  {
    _id: 2,
    enum: 2,
    addType: 'Full Page Ad',
  },
];

export {AddType};
