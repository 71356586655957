import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, CardHeader, Container, Row} from 'reactstrap';
import MetadataForm from '../../../components/Forms/metadata/editForm';
import Header from '../../../components/Headers/Header';
import FAQsTable from '../../../components/Tables/FAQsTable';
import {metadataType} from '../../../utils/data';

const Metadata = () => {
  const {t} = useTranslation();
  const [currentTab, setCurrentTab] = React.useState(metadataType.ABOUT_APP);

  return (
    <div>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="table-shadow" style={{padding: 10}}>
              <CardHeader className="border-0 table-custom-header">
                <div className="table-header-actions" />
              </CardHeader>
              <Tabs
                defaultActiveKey={currentTab}
                onSelect={(tab) => setCurrentTab(tab)}
                id="metadata-tabs"
                className="mb-3">
                <Tab eventKey={metadataType.ABOUT_APP} title="About App">
                  {currentTab === metadataType.ABOUT_APP && (
                    <MetadataForm type={metadataType.ABOUT_APP} />
                  )}
                </Tab>
                <Tab
                  eventKey={metadataType.PRIVACY_POLICY}
                  title="Privacy Policy">
                  {currentTab === metadataType.PRIVACY_POLICY && (
                    <MetadataForm type={metadataType.PRIVACY_POLICY} />
                  )}
                </Tab>
                <Tab eventKey={metadataType.TERMS_OF_USE} title="Terms of use">
                  {currentTab === metadataType.TERMS_OF_USE && (
                    <MetadataForm type={metadataType.TERMS_OF_USE} />
                  )}
                </Tab>
                <Tab eventKey={metadataType.FAQS} title="FAQs">
                  {currentTab === metadataType.FAQS && <FAQsTable />}
                </Tab>
              </Tabs>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Metadata;
