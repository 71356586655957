import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import {emptyLanguagesObject} from '../../../constants/index.js';
import {listCategories} from '../../../redux/actions/categories.actions.js';
import {addQuestion} from '../../../redux/actions/questions.actions.js';
import {QuestionTypeEnums, quesTypes, userTypes} from '../../../utils/data';
import {validateLanguagesObject} from '../../../utils/index.js';
import Header from '../../Headers/Header.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';

const DashboardForm = ({history}) => {
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categoriesReducer);
  const {loading} = useSelector((state) => state.questionsReducer);

  const [userType, setUserType] = useState(null);
  const [quesType, setQuesType] = useState(null);
  const [quesDropdownOpen, setQuesDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMandatory, setIsMandatory] = useState(false);
  const [showOnPreview, setShowOnPreview] = useState(false);
  const [isSingleSelect, setIsSingleSelect] = useState(false);

  const [question, setQuestion] = useState({...emptyLanguagesObject});
  const [filterText, setFilterText] = useState({...emptyLanguagesObject});
  const [options, setOptions] = useState([
    {
      name: {...emptyLanguagesObject},
    },
  ]);

  const validateOptions = () => {
    let isValid = true;
    options?.forEach((option) => {
      if (!validateLanguagesObject(option.name)) {
        isValid = false;
      }
    });
    return isValid;
  };

  const validateForm = () =>
    validateLanguagesObject(question) &&
    validateLanguagesObject(filterText) &&
    options &&
    userType &&
    quesType &&
    selectedCategory;

  // handle input change
  const handleOptionsChange = (value, lang, index) => {
    console.log(value, lang, index);
    const list = [...options];
    list[index].name[lang] = value;
    setOptions(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...options];
    list.splice(index, 1);
    setOptions(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setOptions([
      ...options,
      {
        name: {...emptyLanguagesObject},
      },
    ]);
  };

  const quesTypeChangeHandler = (item) => {
    setQuesType(item);
    setQuesDropdownOpen(!quesDropdownOpen);
  };
  const userTypeChangeHandler = (item) => {
    setUserType(item);
    setUserDropdownOpen(!userDropdownOpen);
  };

  const categoryChangeHandler = (i) => {
    setSelectedCategory(i);
    setCategoryDropdownOpen(!categoryDropdownOpen);
  };

  const onChangeHandler = (value, lang) => {
    setQuestion({...question, [lang]: value});
  };

  const onChangeHandlerFilter = (value, lang) => {
    setFilterText({...filterText, [lang]: value});
  };

  const submitHandler = async () => {
    const formData = {
      userType: userType?.enum,
      questionType: quesType?.enum,
      filterText: filterText,
      showOnPreview,
      isMandatory,
      isSingleSelect,
      question,
      ...(quesType?.enum !== 1 &&
        quesType?.enum !== 5 &&
        quesType?.enum !== 6 &&
        quesType?.enum !== 7 && {options}),
      categoryId: selectedCategory?._id,
    };
    dispatch(addQuestion(formData, history));
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    if (quesType?.enum === QuestionTypeEnums.BOOLEAN) {
      setOptions([
        {
          name: {...emptyLanguagesObject},
        },
        {
          name: {...emptyLanguagesObject},
        },
      ]);
    } else if (
      [
        QuestionTypeEnums.MULTIPLE_CHOICE,
        QuestionTypeEnums.SINGLE_CHOICE,
        QuestionTypeEnums.BOOLEAN,
      ].includes(quesType?.enum)
    ) {
      setOptions([
        {
          name: {...emptyLanguagesObject},
        },
      ]);
    } else {
      setOptions([]);
    }
  }, [quesType?.enum]);

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('addQuestion')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('userType')} </Label>
                        <InputGroup
                          onClick={() =>
                            setUserDropdownOpen(!userDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('userType')}
                            value={userType?.name}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={userDropdownOpen}
                            toggle={() =>
                              setUserDropdownOpen(!userDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userTypes?.map((item, i) => (
                                <DropdownItem
                                  key={i + 1}
                                  onClick={() => userTypeChangeHandler(item)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    {/* {![3, 4].includes(userType?.enum) && ( */}
                    <>
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup>
                          <Label for="examplePassword">{t('category')}</Label>
                          <InputGroup
                            onClick={() =>
                              setCategoryDropdownOpen(!categoryDropdownOpen)
                            }>
                            <Input
                              style={{background: '#fff'}}
                              readOnly
                              placeholder={t('select') + t('category')}
                              value={selectedCategory?.name[lang]}
                            />
                            <InputGroupButtonDropdown
                              addonType="append"
                              isOpen={categoryDropdownOpen}
                              toggle={() =>
                                setCategoryDropdownOpen(!categoryDropdownOpen)
                              }>
                              <DropdownToggle>
                                <p>{'>'}</p>
                              </DropdownToggle>
                              <DropdownMenu>
                                {userType?._id === 3
                                  ? categories
                                      ?.filter((c) => c?.catType === 1)
                                      .map((item) => (
                                        <DropdownItem
                                          key={item?._id}
                                          onClick={() =>
                                            categoryChangeHandler(item)
                                          }>
                                          {item?.name[lang]}
                                        </DropdownItem>
                                      ))
                                  : categories
                                      ?.filter((c) => c?.catType !== 1)
                                      .map((item) => (
                                        <DropdownItem
                                          key={item?._id}
                                          onClick={() =>
                                            categoryChangeHandler(item)
                                          }>
                                          {item?.name[lang]}
                                        </DropdownItem>
                                      ))}
                              </DropdownMenu>
                            </InputGroupButtonDropdown>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup>
                          <Label for="examplePassword">
                            {t('isMandatory')}
                          </Label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              checked={isMandatory}
                              onChange={() => setIsMandatory(!isMandatory)}
                            />
                            <span>&nbsp; {t('isThisQuestionMandatery')}</span>
                          </label>
                        </FormGroup>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup>
                          <Label for="examplePassword">
                            {t('showOnAdPreview')}
                          </Label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              checked={showOnPreview}
                              onChange={() => setShowOnPreview(!showOnPreview)}
                            />
                            <span>&nbsp; {t('showOnAdPreview')}</span>
                          </label>
                        </FormGroup>
                      </Col>
                      {quesType?.enum === 5 && (
                        <Col lg={4} md={6} sm={12}>
                          <FormGroup>
                            <Label for="examplePassword">
                              {t('isSingleSelect')}
                            </Label>
                            <br />
                            <label>
                              <input
                                type="checkbox"
                                checked={isSingleSelect}
                                onChange={() =>
                                  setIsSingleSelect(!isSingleSelect)
                                }
                              />
                              <span>&nbsp; {t('isSingleSelect')}</span>
                            </label>
                          </FormGroup>
                        </Col>
                      )}
                    </>
                    {/* )} */}
                  </Row>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('quesType')} </Label>
                        <InputGroup
                          onClick={() =>
                            setQuesDropdownOpen(!quesDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('select') + t('quesType')}
                            value={quesType?.name}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={quesDropdownOpen}
                            toggle={() =>
                              setQuesDropdownOpen(!quesDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {quesTypes?.map((item, i) => (
                                <DropdownItem
                                  key={i + 1}
                                  onClick={() => quesTypeChangeHandler(item)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                  <MultiLangInput
                    label="Question"
                    placeholder={'Enter ' + t('ques')}
                    value={question}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                  <MultiLangInput
                    label="Filter Text"
                    placeholder="Filter Text"
                    value={filterText}
                    onChange={(value, lang) =>
                      onChangeHandlerFilter(value, lang)
                    }
                    isRequired
                  />
                  <br />
                  <hr />
                  <br />
                  {quesType &&
                    quesType?.enum !== 1 &&
                    quesType?.enum !== 5 &&
                    quesType?.enum !== 6 &&
                    quesType?.enum !== 7 &&
                    options?.length >= 1 &&
                    options.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <MultiLangInput
                            label={`Option ${i + 1}`}
                            placeholder={`Option ${i + 1}`}
                            value={item.name}
                            onChange={(value, lang) =>
                              handleOptionsChange(value, lang, i)
                            }
                            isRequired
                          />

                          {quesType?.enum !== 4 && (
                            <>
                              {' '}
                              {options.length !== 1 && (
                                <button
                                  className="btn remove-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveClick(i);
                                  }}>
                                  X
                                </button>
                              )}
                              {options.length - 1 === i && (
                                <button
                                  className="btn add-button"
                                  onClick={handleAddClick}>
                                  {t('add')}
                                </button>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}

                  <Row form></Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() =>
                    history.push({
                      pathname: '/admin/questions',
                      state: history.location.state,
                    })
                  }>
                  {t('cancel')}
                </button>
                <button
                  className="table-header-button"
                  onClick={submitHandler}
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
