export const usersConstants = {
  USER_LOADING: 'USER_LOADING',
  USER_ERROR: 'USER_ERROR',
  USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
  USER_ADD_SUCCESS: 'USER_ADD_SUCCESS',
  USER_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_GET_SINGLE_SUCCESS: 'USER_GET_SINGLE_SUCCESS:',
  USER_RESET_SINGLE: 'USER_RESET_SINGLE:',
};
