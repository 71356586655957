import React, {useState, useEffect} from 'react';

// reactstrap components

// core components
import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import {
  addNotification,
  getSingleNotification,
} from '../../../redux/actions/notifications.actions.js';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {validateLanguagesObject} from '../../../utils/index.js';
import {emptyLanguagesObject} from '../../../constants/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';

const DashboardForm = () => {
  const {id} = useParams();

  const dispatch = useDispatch();
  const {loading, notification} = useSelector(
    (state) => state.notificationsReducer,
  );
  const [dropdownOpen, setdropDowOpen] = useState(false);
  const [title, setTitle] = useState({
    ...emptyLanguagesObject,
  });
  const [message, setMessage] = useState({
    ...emptyLanguagesObject,
  });
  const [topic, setTopic] = useState('');

  const onMessageChangeHandler = (value, lang) =>
    setMessage({...message, [lang]: value});

  const onTitleChangeHandler = (value, lang) =>
    setTitle({...title, [lang]: value});

  const history = useHistory();

  const validateForm = () =>
    validateLanguagesObject(title) && validateLanguagesObject(message) && topic;

  const submitHandler = async () => {
    dispatch(
      addNotification(
        {
          title,
          message,
          topic,
        },
        history,
      ),
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getSingleNotification(id));
    }

    // eslint-disable-next-line
  }, [dispatch, id]);

  useEffect(() => {
    if (id && !!notification) {
      setTopic(notification?.topic);
      setMessage(notification?.message);
      setTitle(notification?.title);
    }
  }, [notification, id]);

  const {t} = useTranslation();
  const isDemo = ['localhost', 'dash-demo.gulfworkers.com'].includes(window.location.hostname);

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('addNotification')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <MultiLangInput
                    label="Title"
                    placeholder="Title"
                    value={title}
                    onChange={(value, lang) =>
                      onTitleChangeHandler(value, lang)
                    }
                    isRequired
                  />
                  <br />
                  <hr />
                  <br />
                  <MultiLangInput
                    lg={6}
                    md={12}
                    sm={12}
                    label="Message"
                    placeholder="Message"
                    value={message}
                    onChange={(value, lang) =>
                      onMessageChangeHandler(value, lang)
                    }
                    type="textarea"
                    isRequired
                    style={{
                      height: '150px',
                    }}
                  />
                  <br />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('sendTo')} </Label>
                        <InputGroup>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('select') + t('category')}
                            value={topic}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={dropdownOpen}
                            toggle={() => setdropDowOpen(!dropdownOpen)}>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => setTopic(isDemo ? 'demo' : 'all')}>
                                {t('all')}
                              </DropdownItem>
                              {/*<DropdownItem onClick={() => setTopic('free')}>
                                {t('free')}
                              </DropdownItem>
                              <DropdownItem onClick={() => setTopic('paid')}>
                                {t('paid')}
                              </DropdownItem>*/}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/notifications')}>
                  {t('cancel')}
                </button>
                <button
                  className="table-header-button"
                  onClick={submitHandler}
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
