import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {emptyLanguagesObject, languageNames, languages} from '../../constants';
import {errorParser} from '../../redux/actions/errorParser';
import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert} from '../../utils/alerts';
import TextInput from '../Inputs/TextInput/TextInput';

const FAQsModal = ({
  isOpen,
  toggleModal,
  questions,
  questionIndex,
  onChange,
  isEdit,
  documentId,
  addQuestion,
}) => {
  const {t} = useTranslation();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('en');
  const [newQuestion, setNewQuestion] = useState({
    title: {...emptyLanguagesObject},
    answer: {...emptyLanguagesObject},
  });

  const reset = () => {
    setNewQuestion({
      title: {...emptyLanguagesObject},
      answer: {...emptyLanguagesObject},
    });
    setCurrentTab('en');
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    await headerSetup();
    const faqs = isEdit ? questions : [...questions, newQuestion];
    try {
      await API.put(`admin/v1/metadata`, {_id: documentId, faqs});
      successAlert('FAQs updated successfully');
      if (!isEdit) addQuestion(newQuestion);
    } catch (err) {
      const parsedError = await errorParser(err);
      errorAlert(parsedError);
    }
    setSubmitLoading(false);
    onClose();
  };

  const handleChange = (prop, index, lang) => (e) => {
    if (isEdit) onChange(prop, lang, index, e);
    else {
      setNewQuestion({
        ...newQuestion,
        [prop]: {...newQuestion[prop], [lang]: e},
      });
    }
  };

  const onClose = () => {
    reset();
    toggleModal();
  };

  const navLinkStyle = {
    boxShadow: 'none',
  };

  return (
    <Modal
      style={{maxWidth: 700}}
      isOpen={isOpen}
      toggle={onClose}
      centered={true}>
      <ModalHeader toggle={onClose}>
        {isEdit ? 'Edit question' : 'Add question'}
      </ModalHeader>
      <ModalBody>
        <Row>
          <div className="col">
            <Card style={{border: 'none', paddingTop: 20}}>
              <Tab.Container
                onSelect={(tab) => setCurrentTab(tab)}
                id="languages-tabs"
                defaultActiveKey="en">
                <Row>
                  <Col lg={3} md={12} style={{marginRight: -25}}>
                    <Nav variant="pills" className="flex-column">
                      {languages.map((langCode, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link style={navLinkStyle} eventKey={langCode}>
                            {languageNames.en[langCode]}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col lg={9} md={12}>
                    <Tab.Content style={{minHeight: 300}}>
                      {languages.map((langCode, index) => (
                        <Tab.Pane key={index} eventKey={langCode}>
                          {currentTab === langCode && (
                            <div>
                              <TextInput
                                label={t('question')}
                                placeholder={`${t('enter')} ${t('question')}`}
                                value={
                                  isEdit
                                    ? questions?.[questionIndex]?.title?.[
                                        langCode
                                      ]
                                    : newQuestion?.title?.[langCode]
                                }
                                onChange={handleChange(
                                  'title',
                                  questionIndex,
                                  langCode,
                                )}
                              />
                              <TextInput
                                label={t('answer')}
                                placeholder={`${t('enter')} ${t('answer')}`}
                                value={
                                  isEdit
                                    ? questions?.[questionIndex]?.answer?.[
                                        langCode
                                      ]
                                    : newQuestion?.answer?.[langCode]
                                }
                                onChange={handleChange(
                                  'answer',
                                  questionIndex,
                                  langCode,
                                )}
                                type="textarea"
                              />
                            </div>
                          )}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={!submitLoading ? handleSubmit : null}
          variant="primary"
          disabled={submitLoading}>
          {submitLoading ? 'Loading…' : t('submit')}
        </Button>
        <Button color="secondary" variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FAQsModal;
