import React from 'react';
import {Col, Row} from 'reactstrap';
import TextInput from '../../Inputs/TextInput/TextInput.js';
import {useTranslation} from 'react-i18next';
import {languages} from '../../../constants/index.js';

const MultiLangInput = ({
  value: valueProp,
  type,
  label: labelProp,
  placeholder: placeholderProp,
  isRequired,
  style,
  onChange,
  disabled,
  lg = 3,
  md = 6,
  sm = 12,
}) => {
  const {t} = useTranslation();

  const label = React.useMemo(() => {
    if (labelProp && typeof labelProp === 'string') {
      return {
        en: labelProp + ' (English)',
        ar: labelProp + ' (Arabic)',
        hi: labelProp + ' (Hindi)',
        fil: labelProp + ' (Filipino)',
        si: labelProp + ' (Sinhala)',
        ne: labelProp + ' (Nepali)',
        ta: labelProp + ' (Tamil)',
      };
    } else if (labelProp && typeof labelProp === 'object') {
      return labelProp;
    } else {
      return null;
    }
  }, [labelProp]);

  const placeholder = React.useMemo(() => {
    if (placeholderProp && typeof placeholderProp === 'string') {
      return {
        en: placeholderProp + ' (English)',
        ar: placeholderProp + ' (Arabic)',
        hi: placeholderProp + ' (Hindi)',
        fil: placeholderProp + ' (Filipino)',
        si: placeholderProp + ' (Sinhala)',
        ne: placeholderProp + ' (Nepali)',
        ta: placeholderProp + ' (Tamil)',

      };
    } else if (placeholderProp && typeof placeholderProp === 'object') {
      return placeholderProp;
    } else {
      return null;
    }
  }, [placeholderProp]);

  const getValue = (lang) =>
    valueProp?.length
      ? valueProp[0]?.[lang] || valueProp[1]?.[lang]
      : valueProp
      ? valueProp[lang]
      : null;

  return (
    <Row style={{marginTop: 20}} form>
      {languages.map((lang, index) => (
        <Col key={index} lg={lg} md={md} sm={sm}>
          <TextInput
            label={label ? label[lang] : ''}
            placeholder={placeholder ? placeholder[lang] : ''}
            value={getValue(lang)}
            onChange={(value) => onChange(value, lang)}
            errorMessage={
              isRequired && !getValue(lang) ? t('requiredField') : ''
            }
            style={style}
            type={type}
            disabled={disabled}
          />
        </Col>
      ))}
    </Row>
  );
};

export default MultiLangInput;
