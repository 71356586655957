import {featureNationalitiesConstants} from '../constants';

const featureNationalitiesReducer = (state = {nationalities: [], nationality: {}}, action) => {
  switch (action.type) {
    case featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING:
      return {...state, loading: true};

    case featureNationalitiesConstants.FEATURE_NATIONALITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nationalities: action.payload.listing,
        count: action.payload.count,
      };
    case featureNationalitiesConstants.COUNTRY_SUFFLE:
      return {
        ...state,
        nationalities: action.payload,
      };

    case featureNationalitiesConstants.FEATURE_NATIONALITY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nationalities: [action.payload, ...state.nationalities],
      };

    case featureNationalitiesConstants.NATIONALITY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nationalities: state.nationalities.filter((i) => i?._id !== action.payload),
      };
    case featureNationalitiesConstants.FEATURE_NATIONALITY_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nationalities: state.nationalities.map((item) =>
          item._id === action.payload?._id ? action.payload : item,
        ),
      };

    case featureNationalitiesConstants.NATIONALITY_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nationality: action.payload,
      };
    case featureNationalitiesConstants.FEATURE_NATIONALITY_RESET_SINGLE:
      return {
        ...state,

        nationality: {},
      };

    case featureNationalitiesConstants.FEATURE_NATIONALITY_ERROR:
      return {...state, loading: false, error: action.payload};
    case 'LOGOUT':
      return {
        ...state,
        nationalities: [],
        nationality: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default featureNationalitiesReducer;
