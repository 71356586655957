import Filters from '../../views/pages/filters';
import FiltersAdd from '../../views/pages/filters/addFilter';
// import FiltersView from '../../views/pages/filters/viewFilter';
import FiltersEdit from '../../views/pages/filters/editFilter';

let routes = [
  {
    path: '/admin/filters',
    name: 'Filters',
    icon: 'search-icon',
    component: Filters,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/filters/addFilter',
    name: 'question',
    icon: 'user-icon',
    component: FiltersAdd,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  // {
  //   path: '/admin/filters/viewFilter/:id',
  //   name: 'question',
  //   icon: 'user-icon',
  //   component: FiltersView,
  //   svg: true,
  //   layout: '/admin',
  //   sideBar: false,
  // },
  {
    path: '/admin/filters/editFilter/:id',
    name: 'question',
    icon: 'user-icon',
    component: FiltersEdit,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  //   {
  //     path: '/admin/Filters/edit/:id',
  //     name: 'Filters),
  //     icon: 'user-icon',
  //     component: FiltersEdit,
  //     svg: true,
  //     layout: '/admin',
  //     sideBar: false,
  //   },
  //   {
  //     path: '/admin/Filters/view/:id',
  //     name: 'Filters),
  //     icon: 'user-icon',
  //     component: FiltersView,
  //     svg: true,
  //     layout: '/admin',
  //     sideBar: false,
  //   },
];

export default routes;
