import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import {listCategories} from '../../redux/actions/categories.actions';
import {userTypes} from '../../utils/data';

const AdsFilterModal = ({
  customProps: {
    open,
    setModalOpen,
    userType,
    setUserType,
    selectedCategory,
    setSelectedCategory,
    clearData,
    fetchData,
  },
}) => {
  const {t} = useTranslation();

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

  const dispatch = useDispatch();

  const {categories} = useSelector((state) => state.categoriesReducer);

  const userTypeChangeHandler = (item) => {
    setUserType(item?.enum);
    setUserDropdownOpen(!userDropdownOpen);
  };

  const categoryChangeHandler = (i) => {
    setSelectedCategory(i?._id);
    setCategoryDropdownOpen(!categoryDropdownOpen);
  };

  const searchHandler = () => {
    fetchData(1, 15, undefined, undefined, {userType, selectedCategory}, 0);
    setModalOpen();
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const userTypeObj = userTypes.find((i) => i?.enum === userType);
  const categoryObj = categories?.find((i) => i?._id === selectedCategory);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={() => setModalOpen()}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('filterAds')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalOpen()}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <FormGroup>
              <Label for="examplePassword">{t('userType')} </Label>
              <InputGroup>
                <Input
                  style={{background: '#fff'}}
                  readOnly
                  placeholder={t('userType')}
                  value={userTypeObj?.name ? userTypeObj?.name : t('userType')}
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={userDropdownOpen}
                  toggle={() => setUserDropdownOpen(!userDropdownOpen)}>
                  <DropdownToggle>
                    <p>{'>'}</p>
                  </DropdownToggle>
                  <DropdownMenu>
                    {userTypes?.map((item, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => userTypeChangeHandler(item)}>
                        {item?.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <FormGroup>
              <Label for="examplePassword">{t('category')} </Label>
              <InputGroup>
                <Input
                  style={{background: '#fff'}}
                  readOnly
                  placeholder={t('select') + ' ' + t('category')}
                  value={
                    categoryObj?.name?.en
                      ? categoryObj?.name?.en
                      : t('select') + ' ' + t('category')
                  }
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={categoryDropdownOpen}
                  toggle={() => setCategoryDropdownOpen(!categoryDropdownOpen)}>
                  <DropdownToggle>
                    <p>{'>'}</p>
                  </DropdownToggle>
                  <DropdownMenu>
                    {categories?.map((item, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => categoryChangeHandler(item)}>
                        {item?.name?.en}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm={12}>
            <Button color="secondary" onClick={clearData} type="button">
              {t('clear')}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => setModalOpen()}>
          {t('close')}
        </Button>
        <Button
          style={{background: '#007bff'}}
          color="primary"
          type="button"
          onClick={searchHandler}
          disabled={!userType}>
          {t('search')}
        </Button>
      </div>
    </Modal>
  );
};

export default AdsFilterModal;
