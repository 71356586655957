import React from 'react';

export default function SelectInput({
  value,
  options,
  label,
  type,
  errorMessage,
  style,
  onChange,
  disabled
}) {
  const handleChange = (option) => {
    if (type === 'checkbox') {
      if (value?.includes(option)) {
        onChange(value.filter((item) => item !== option));
      } else {
        onChange([...(value ?? []), option]);
      }
    } else {
      onChange(option);
    }
  };
  return (
    <div>
      <label> {label}</label>
      {errorMessage && (
        <>
          <br />
          <div
            style={{
              color: 'red',
              fontSize: 12,
              marginTop: -5,
              marginBottom: -10,
              marginLeft: 5,
            }}>
            * {errorMessage}
          </div>
        </>
      )}
      <br />
      <div style={style}>
        {options?.map((option, index) => (
          <div key={index}>
            <input
              className={disabled && 'disabled-radio-input'}
              style={{marginRight: '10px'}}
              type={type}
              checked={
                type === 'checkbox' && value
                  ? value.includes(option?.value)
                  : value === option?.value
              }
              onChange={() => {
                !disabled && handleChange(option?.value);
              }}
              value={option?.name}
            />
            <span>{option?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
