import moment from 'moment';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import nonReplyIcon from '../../assets/images/icons/table/table-non-reply.svg';
import {listContactUs} from '../../redux/actions/contactUs.actions';
import {contactUsStatus, contactUsStatusEnum} from '../../utils/data';
import {replaceUrlSearchParams} from '../../utils/index.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import ResolveModal from '../Modals/ContactUsResolveModal.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [activeRequest, setActiveRequest] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const setModalOpen = (request) => {
    setIsOpen(!isOpen);
    if (request) {
      setActiveRequest(request);
    } else {
      setActiveRequest({});
    }
  };

  const dispatch = useDispatch();
  const {contacts, loading, count} = useSelector(
    (state) => state.contactUsReducer,
  );

  const fetchData = useCallback(
    (
      currentPage,
      postsPerPage,
      searchKeyword,
      sortDirection,
      {selectedStatus},
    ) => {
      dispatch(
        listContactUs(postsPerPage, currentPage, searchKeyword, selectedStatus),
      );
    },
    [dispatch],
  );

  const {t} = useTranslation();

  const resolveRequest = () => {};

  const handleFilterChange = (newStatus) => {
    setSelectedStatus(parseInt(newStatus));
    setCurrentPage(1);
    const query = replaceUrlSearchParams({
      filtersQuery: {
        selectedStatus: parseInt(newStatus),
      },
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    fetchData(1, postsPerPage, search, null, {
      selectedStatus: parseInt(newStatus),
    });
  };

  const updateFiltersFromUrl = (filters) => {
    const newStatus = !isNaN(filters?.selectedStatus)
      ? parseInt(filters?.selectedStatus)
      : 0;
    setSelectedStatus(newStatus);
    const query = replaceUrlSearchParams({
      filtersQuery: {selectedStatus: newStatus},
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
  };

  const rows = contacts.map((item) => [
    item?.name,
    item?.email ? item?.email : 'N/A',
    item?.fullNumber?.startsWith('+')
      ? item?.fullNumber
      : '+' + item?.fullNumber,
    moment(item?.createdAt).format('DD/MM/YYYY'),
    moment(item?.createdAt).format('hh:mm A'),
    item?.status === contactUsStatusEnum.RESOLVED ? (
      <p onClick={() => setModalOpen(item)} className="replied">
        Replied
      </p>
    ) : item?.status === contactUsStatusEnum.IGNORED ? (
      <p onClick={() => setModalOpen(item)} className="ignored">
        Ignored
      </p>
    ) : (
      <img
        onClick={() => setModalOpen(item)}
        alt="Gulf workers"
        className="table-non-reply"
        src={nonReplyIcon}
      />
    ),
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <ResolveModal
        open={isOpen}
        setModalOpen={setModalOpen}
        onSubmit={resolveRequest}
        setActiveRequest={setActiveRequest}
        activeRequest={activeRequest}
      />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('userName'),
            t('emailAddress'),
            t('phoneNumber'),
            t('contactDate'),
            t('contactTime'),
            t('status'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          exportButton={
            <Export
              filename="contact-us"
              resource="listContactUsRequest"
              params={{search}}
              headers={[
                {label: 'Title', key: 'name'},
                {label: 'Email', key: 'email'},
                {label: 'Phone Number', key: 'fullNumber'},
                {
                  label: 'Contact Date',
                  key: 'createdAt',
                  type: 'dateonly',
                },
                {
                  label: 'Contact Time',
                  key: 'createdAt',
                  type: 'timeonly',
                },
                {
                  label: 'User Message',
                  key: 'note',
                },
                {
                  label: 'Admin Reply',
                  key: 'resolveNote',
                },
                {
                  label: 'Status',
                  key: 'status',
                  dataConverter: (val) => contactUsStatus[val],
                },
              ]}
            />
          }
          filters={{
            state: {selectedStatus},
            setState: updateFiltersFromUrl,
            position: 'first-row',
            component: (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <select
                  className="mb-0 table-header-button"
                  value={selectedStatus}
                  onChange={(e) => handleFilterChange(e.target.value)}>
                  {Object.keys(contactUsStatus).map((key) => (
                    <option key={key} value={key}>
                      {contactUsStatus[key]}
                    </option>
                  ))}
                </select>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
