export const categoriesConstants = {
  CATEGORY_LOADING: 'CATEGORY_LOADING',
  CATEGORY_ERROR: 'CATEGORY_ERROR',
  CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
  CATEGORY_ADD_SUCCESS: 'CATEGORY_ADD_SUCCESS',
  CATEGORY_EDIT_SUCCESS: 'CATEGORY_EDIT_SUCCESS',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_GET_SINGLE_SUCCESS: 'CATEGORY_GET_SINGLE_SUCCESS:',
  CATEGORY_RESET_SINGLE: 'CATEGORY_RESET_SINGLE:',
  CATEGORY_SUFFLE: 'CATEGORY_SUFFLE:',
};
