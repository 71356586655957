import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import moment from 'moment';

const HistoryModal = (props) => {
  const { toggle, modal, data, user } = props;
  const {t} = useTranslation();

  const [showChanges, setShowChanges] = useState(false);
  const lang = 'en';

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={modal}
      toggle={() => {
        toggle()
        setShowChanges(false)
      }}>
      <div className="modal-header">
        <h5 className="modal-title" style={{color: '#007bff'}} id="exampleModalLabel">
          {user ? t('userHistory') : t('adHistory')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            toggle()
            setShowChanges(false)
          }}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12} md={12} sm={12}>
            { showChanges ? <div>
              { showChanges.changes.title && <div className='history-changes'>
                <div className='history-changes-title'>{t('userName')}:</div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('oldValue')}</div>: {showChanges.changes.title.old}
                </div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('newValue')}</div>: {showChanges.changes.title.latest}
                </div>
              </div> }
              { showChanges.changes.description && <div className='history-changes'>
                <div className='history-changes-title'>{t('Description')}:</div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('oldValue')}</div>: {showChanges.changes.description.old}
                </div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('newValue')}</div>: {showChanges.changes.description.latest}
                </div>
              </div> }
              { (showChanges.changes.phoneNumber) && <div className='history-changes'>
                <div className='history-changes-title'>{t('phoneNumber')}:</div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('oldValue')}</div>: {showChanges.changes.phoneNumber?.old}
                </div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('newValue')}</div>: {showChanges.changes.phoneNumber?.latest}
                </div>
              </div> }
              { showChanges.changes.questions && showChanges.changes.questions.map(q => <div className='history-changes'>
                  <div className='history-changes-title'>{q.question[lang]}:</div>
                  <div className='history-changes-value'>
                    <div className='history-changes-label'>{t('oldValue')}</div>: {q.old.map ? q.old.map(val => val[lang]) : q.old}
                  </div>
                  <div className='history-changes-value'>
                    <div className='history-changes-label'>{t('newValue')}</div>: {q.latest.map ? q.latest.map(val => val[lang]) : q.latest}
                  </div>
                </div>) }
            </div> : <div>
              <div className='heading-row'>
                <p>{t('editBy')}</p>
                <p>{t('editTime')}</p>
                <p>{t('actions')}</p>
              </div>
              <div className='table-data-container'>
                {data.length ? data.map((e, key) => <div key={key} className='single-row'>
                  <p>{e.editedBy}</p>
                  <p>{moment(e.editedAt).format('lll')}</p>
                  <p style={{ minWidth: 100 }}>{ e.changes && <a href='#' onClick={() => setShowChanges(e)}>{t('viewChanges')}</a>}</p>
                </div>) : null}

              </div>
            </div> }
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        { showChanges && <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowChanges(false)}>
            {t('back')}
          </Button> }
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            toggle()
            setShowChanges(false)
          }}>
          {t('close')}
        </Button>
      </div>
    </Modal>
  );
};

export default HistoryModal;
