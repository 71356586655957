export const bannersConstants = {
  BANNER_LOADING: 'BANNER_LOADING',
  BANNER_ERROR: 'BANNER_ERROR',
  BANNER_LIST_SUCCESS: 'BANNER_LIST_SUCCESS',
  BANNER_ADD_SUCCESS: 'BANNER_ADD_SUCCESS',
  BANNER_EDIT_SUCCESS: 'BANNER_EDIT_SUCCESS',
  BANNER_DELETE_SUCCESS: 'BANNER_DELETE_SUCCESS',
  BANNER_GET_SINGLE_SUCCESS: 'BANNER_GET_SINGLE_SUCCESS:',
  BANNER_RESET_SINGLE: 'BANNER_RESET_SINGLE:',
  BANNER_SUFFLE: 'BANNER_SUFFLE:',
};
