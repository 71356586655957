import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import countryList from 'react-select-country-list';
import {isEmpty} from '../../../../utils';
import {QuestionTypeEnums} from '../../../../utils/data';
import Dropdown from '../../../Inputs/Dropdown/Dropdown';
import SelectInput from '../../../Inputs/SelectInput/SelectInput';
import TextInput from '../../../Inputs/TextInput/TextInput';

export default function Question({data, index, setAnswer, fromViewAd}) {
  const countriesOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((country) => country.label),
    [],
  );

  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const isRequired = !!data?.isMandatory;

  return (
    <div>
      {[QuestionTypeEnums.TEXT, QuestionTypeEnums.NUMBER].includes(
        data?.questionType,
      ) && (
        <TextInput
          style={{
            maxWidth: '250px',
            boxShadow: 'none',
          }}
          label={`${index + 1}. ${data.question[lang]}`}
          placeholder={t('answerPlaceholder')}
          type={
            data?.questionType === QuestionTypeEnums.NUMBER ? 'number' : 'text'
          }
          disabled={fromViewAd ? true : false}
          value={data?.answer}
          onChange={(newValue) => {
            setAnswer(newValue);
          }}
          errorMessage={
            isRequired && isEmpty(data?.answer) ? t('requiredField') : null
          }
        />
      )}
      {data?.questionType === QuestionTypeEnums.NATIONALITY && (
        <Dropdown
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
          disabled={fromViewAd ? true : false}
          label={`${index + 1}. ${data.question[lang]}`}
          placeholder={`Select ${data.question[lang]}`}
          value={data?.answer}
          options={countriesOptions}
          onChange={(newValue) => {
            setAnswer(newValue);
          }}
          errorMessage={
            isRequired && isEmpty(data?.answer) ? t('requiredField') : null
          }
        />
      )}
      {[
        QuestionTypeEnums.SINGLE_CHOICE,
        QuestionTypeEnums.BOOLEAN,
        QuestionTypeEnums.MULTIPLE_CHOICE,
      ].includes(data?.questionType) && (
        <SelectInput
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          label={`${index + 1}. ${data.question[lang]}`}
          disabled={fromViewAd ? true : false}
          type={
            data?.questionType === QuestionTypeEnums.MULTIPLE_CHOICE
              ? 'checkbox'
              : 'radio'
          }
          value={data?.answer}
          options={data?.options?.map((opt) => ({
            value: opt?._id,
            name: opt?.name?.[lang],
          }))}
          onChange={(newValue) => {
            setAnswer(newValue);
          }}
          errorMessage={
            isRequired && isEmpty(data?.answer) ? t('requiredField') : null
          }
        />
      )}
    </div>
  );
}
