import React, {useState, useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  CardHeader,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import {useDispatch, useSelector} from 'react-redux';
import {userGoals} from '../../../utils/data.js';
import CountryJSON from '../../../utils/countries.json';
import {editUser, getSingleUser} from '../../../redux/actions/users.actions.js';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import {validateEmail} from '../../../utils/dataHelpers.js';
import cogoToast from 'cogo-toast';
import Switch from 'react-switch';

const DashboardForm = () => {
  const history = useHistory();

  const {t} = useTranslation();
  //redux
  const dispatch = useDispatch();
  const {loading, user} = useSelector((state) => state.usersReducer);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [createNote, setCreateNote] = useState(false);
  const [add, setAdd] = useState();
  const [note, setNote] = useState('');
  const [checked, setChecked] = useState(user.isBlocked);
  const [goalsDropdownOpen, setGoalsDropdownOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState('');
  const [nationalityDropdownOpen, setNationalityDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(''); 
  const {id} = useParams();

  useEffect(() => {
    dispatch(getSingleUser(id));
    setChecked(user.isBlocked);

    // eslint-disable-next-line
  }, [dispatch, id]);

  useEffect(() => {
    setChecked(user.isBlocked);
  }, [user]);

  useEffect(() => {
    if (!!user?.name || !!user?.email || user?.fullNumber || user?.nationality || user?.goals) {
      setName(user?.name ? user?.name : '');
      setEmail(user?.email);
      setPhone(user?.phoneNumber);
      setCountryCode(user?.callingCode);
      setSelectedCountry(user?.nationality);
      setSelectedGoal(user?.goals[0]);
    }
    // eslint-disable-next-line
  }, [user?.name, user?.email, user?.fullNumber, user?.nationality]);

  // const validateForm = () => !!name && phone && countryCode;
  const submitHandler = async () => {
    if (validateEmail(email) || (phone.length > 6 && countryCode)) {
      dispatch(
        editUser(
          {
            name,
            ...(email && {email}),
            callingCode: countryCode,
            phoneNumber: phone,
            userId: id,
            isBlocked: checked,
            goals: [selectedGoal],
            nationality: selectedCountry
          },
          history,
        ),
      );
    } else {
      (!email || (phone.length < 6 && !countryCode)) && cogoToast.error(t('emailOrPhoneIsRequired'), {
        position: 'top-right',
        hideAfter: 3,
      });
    }
  };
  const phoneInputHanlder = (number, data) => {
    setCountryCode('+' + data?.dialCode);
    setPhone(number?.slice(data?.dialCode?.length));
  };

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const handleAddNote = () => {
    const notes = user.notes ? JSON.parse(user.notes) : [];
    dispatch(
      editUser(
        {
          userId: id,
          notes: JSON.stringify([
            ...notes,
            {
              note,
              add,
              id: uuid(),
            },
          ]),
        },
        null,
        onSave,
      ),
    );
  };

  const onSave = () => {
    dispatch(getSingleUser(id));
    setNote('');
    setAdd(null);
  };

  const notes = user.notes ? JSON.parse(user.notes) : [];

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <CardHeader className="table-custom-header">
                <div className="table-header-actions">
                  <h2 className="dashboard-form-header padding-0">{`${t(
                    'editUser',
                  )} #${user?.userId}`}</h2>

                  {loading && (
                    <div className="table-loader">
                      <Spinner color={'info'} />
                    </div>
                  )}
                  <button
                    className="create-note-btn"
                    onClick={() => setCreateNote(true)}>
                    Create Note
                  </button>
                </div>
              </CardHeader>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('name')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          type="text"
                          placeholder={t('namePlaceholder')}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('phone')}
                          <sup>*</sup>{' '}
                        </Label>
                        <PhoneInput
                          containerStyle={{
                            border: '1px solid #707070',
                          }}
                          searchStyle={{
                            width: '100%',
                          }}
                          inputStyle={{
                            width: '100%',
                            direction: 'ltr',
                          }}
                          // country={getCountryCode()}
                          value={countryCode + phone}
                          onChange={(phone, countryData) => {
                            phoneInputHanlder(phone, countryData)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{t('emailAddress')}</Label>
                        <Input
                          type="email"
                          placeholder={t('emailPlaceholder')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('goal')} </Label>
                        <InputGroup
                          onClick={() =>
                            setGoalsDropdownOpen(!goalsDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('selectGoal')}
                            value={selectedGoal}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={goalsDropdownOpen}
                            toggle={() =>
                              setGoalsDropdownOpen(!goalsDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userGoals?.map((item, i) => (
                                <DropdownItem
                                  key={i}
                                  onClick={() => setSelectedGoal(item.name)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('nationality')} </Label>
                        <InputGroup
                          onClick={() =>
                            setNationalityDropdownOpen(!nationalityDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('selectNationality')}
                            value={selectedCountry}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={nationalityDropdownOpen}
                            toggle={() =>
                              setNationalityDropdownOpen(!nationalityDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {CountryJSON?.map((item, i) => (
                                <DropdownItem
                                  key={i}
                                  onClick={() => setSelectedCountry(item.name.en)}>
                                  {item.name.en}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="more-actions-container">
                    <label className="switch-input">
                      <span>User blocked status:</span>
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#FF0000"
                      />
                    </label>
                    {createNote && (
                      <Row form>
                        <Col lg={12} md={12} sm={12}>
                          <Label className="custom-type-margin">Type:</Label>
                          <select
                            className="mb-0 table-header-button"
                            value={add}
                            onChange={(e) => setAdd(e.target.value)}>
                            <option>Select Type</option>
                            <option>Note</option>
                            <option>Inquiry</option>
                            <option>Complaint</option>
                          </select>
                          {add && add !== 'Select Type' && (
                            <div>
                              <Input
                                placeholder={
                                  add === 'Complaint'
                                    ? 'Enter your complaint here'
                                    : add === 'Inquiry'
                                    ? 'Enter your inquiry here'
                                    : 'Enter your note here'
                                }
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                type="textarea"
                              />
                              <button
                                className="edit-user-add-btn"
                                type="button"
                                onClick={handleAddNote}>
                                Add
                              </button>
                              <button
                                className="edit-user-cancel-btn"
                                onClick={() => setCreateNote(false)}>
                                Cancel
                              </button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}

                    <br />

                    {notes?.length
                      ? notes.map((note, index) => (
                          <div key={index}>
                            <b>Type: </b>
                            {note.add}
                            <br />
                            <b>Description: </b>
                            {note.note}

                            <hr />
                          </div>
                        ))
                      : null}
                  </div>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => {
                    history.push({
                      pathname: '/admin/users',
                      search: window.location.search,
                    });
                  }}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={loading}>
                  {loading ? <Spinner color={'info'} /> : t('update')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
