import moment from 'moment';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import roundIcon from '../../assets/images/icons/table/table-notifications-action-icon.svg';
import {listNotifications} from '../../redux/actions/notifications.actions.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';

const Tables = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch();
  const {notifications, loading, count} = useSelector(
    (state) => state.notificationsReducer,
  );

  const history = useHistory();

  const navigateTo = (route) => history.push(route);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) =>
      dispatch(listNotifications(postsPerPage, currentPage, searchKeyword)),
    [dispatch],
  );

  const rows = notifications.map((item) => [
    item?.title[lang],
    item?.message[lang],
    item?.topic,
    moment(item?.createdAt).format('DD/MM/YYYY'),
    moment(item?.createdAt).format('LT'),
    <img
      alt={'Gulf Workers'}
      className="td-action-img"
      src={roundIcon}
      onClick={() =>
        navigateTo(`/admin/notifications/addNotification/${item?._id}`)
      }
    />,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('userName'),
            t('message'),
            t('sendTo'),
            t('sentDate'),
            t('sentTime'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          addClickHandler={() =>
            navigateTo('/admin/notifications/addNotification')
          }
          addButtonText={t('sendNotifications')}
          exportButton={
            <Export
              filename="notifications"
              resource="listAdminNotification"
              params={{search}}
              headers={[
                {label: 'Title', key: `title.${lang}`},
                {label: 'Message', key: `message.${lang}`},
                {label: 'Send To', key: 'topic'},
                {
                  label: 'Sent Date',
                  key: 'createdAt',
                  type: 'dateonly',
                },
                {
                  label: 'Sent Time',
                  key: 'createdAt',
                  type: 'timeonly',
                },
              ]}
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
