import {areasConstants} from '../constants';

const areasReducer = (
  state = {
    areas: [],
    question: {}
  }, action) => {
  switch (action.type) {
    case areasConstants.AREAS_LOADING:
      return {...state, loading: true};

    case areasConstants.AREAS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        areas: action.payload.listing,
        count: action.payload.count,
      };

    case areasConstants.AREA_ADDED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case areasConstants.AREA_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        areas: state.areas.filter((i) => i?._id !== action.payload),
      };
    case areasConstants.AREA_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // questions: state.questions.map((item) =>
        //   item._id === action.payload?._id ? action.payload : item,
        // ),
      };

    case areasConstants.QUESTION_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        question: action.payload,
      };
    case areasConstants.QUESTION_RESET_SINGLE:
      return {
        ...state,

        question: {},
      };
    case 'LOGOUT':
      return {
        ...state,
        questions: [],
        question: {},
        loading: false,
        error: null,
      };
    case areasConstants.AREA_ERROR:
      return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
};

export default areasReducer;
