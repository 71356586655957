import moment from 'moment';
import qs from 'qs';
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import eyeIcon from '../../assets/images/icons/table/table-eye-icon.svg';
import {deleteUser, listUsers} from '../../redux/actions/users.actions.js';
import historyIcon from '../../assets/images/icons/table/history-icon.png';
import Export from '../Buttons/Export';
import {replaceUrlSearchParams} from '../../utils';
import CustomTable from '../CustomTable/CustomTable.js';
import HistoryModal from '../Modals/HistoryModal';
import Header from '../Headers/Header.js';

const Tables = ({history}) => {
  const {users, loading, count} = useSelector((state) => state.usersReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [postsPerPage] = useState(15);
  const [historyModal, setHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(id));
    } else return;
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    const activeUsers = !!users && users.filter((user) => user.status === 1);
    const inactiveUsers = !!users && users.filter((user) => user.status === 2);
    const deletedUsers = !!users && users.filter((user) => user.status === 3);
    setFilteredUsers(
      activeTab === 0
        ? activeUsers
        : activeTab === 1
        ? inactiveUsers
        : deletedUsers,
    );
    fetchData(1, postsPerPage, '', undefined, undefined, activeTab);
    const query = replaceUrlSearchParams({
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    setCurrentPage(1);
  }, [activeTab]);

  const navigateTo = (route) => history.push(route);

  const fetchData = useCallback(
    (
      currentPage,
      postsPerPage,
      searchKeyword,
      sortDirection,
      filterParams,
      activeTab,
    ) => {
      return dispatch(
        listUsers(postsPerPage, currentPage, searchKeyword, activeTab + 1),
      );
    },
    [dispatch],
  );

  const onHistoryClick = (item) => {
    setHistoryData(item);
    toggleHistoryModal();
  };

  const toggleHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const rows = filteredUsers.map((item) => [
    <div style={{width: 25}}>{item?.userId}</div>,
    <div style={{width: '100%'}}>{item?.name ? item?.name : 'N/A'}</div>,
    item?.nationality ? item?.nationality : 'N/A',
    <div style={{width: 75}}>
      {moment(item?.createdAt).format('DD/MM/YYYY') +
        ' ' +
        moment(item?.createdAt).format('LT')}
    </div>,
    activeTab === 2
      ? moment(item?.deletedAt).format('DD/MM/YYYY') +
        ' ' +
        moment(item?.deletedAt).format('LT')
      : '',
    activeTab === 2 ? item?.deletedBy : '',
    <div style={{width: 120}}>
      {item.status != 3 ? item.callingCode + item?.phoneNumber : item?.deletedPhone}
    </div>,
    moment(item?.lastOnline).format('DD/MM/YYYY'),
    item?.numberOfAdds ? (
      <Link to={`/admin/ads?page=1&userId=${item._id}`}>
        {item?.numberOfAdds}
      </Link>
    ) : (
      item?.numberOfAdds
    ),
    <ul style={{width: 120}}>
      {item?.goals?.map((goal, i) => (
        <li>{goal}</li>
      ))}
    </ul>,
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={eyeIcon}
        onClick={() =>
          navigateTo({
            pathname: `/admin/users/viewUser/${item._id}`,
            search: window.location.search,
          })
        }
      />
      {item.status != 3 ? (
        <img
          alt={'Gulf Workers'}
          className="td-action-img"
          src={editIcon}
          onClick={() =>
            navigateTo({
              pathname: `/admin/users/editUser/${item._id}`,
              search: window.location.search,
            })
          }
        />
      ) : null}
      {item.editHistory && (
        <img
          alt={'Gulf Workers'}
          className="td-action-img"
          src={historyIcon}
          onClick={() => onHistoryClick(item.editHistory)}
        />
      )}
      {item.status != 3 ? (
        <img
          alt={'Gulf Workers'}
          className="td-action-img"
          src={deleteIcon}
          onClick={() => deleteHandler(item?._id)}
        />
      ) : null}
    </>,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  const commonSheetHeaders = [
    {label: 'ID', key: 'userId'},
    {label: 'Email', key: 'email'},
    {label: 'Name', key: 'name'},
    {
      label: 'Registration Date',
      key: 'createdAt',
      type: 'dateonly',
    },
    {
      label: 'Registration Time',
      key: 'createdAt',
      type: 'timeonly',
    },
    {label: 'Phone Number', key: 'fullNumber'},
    {label: 'Nationality', key: 'nationality'},
    {
      label: 'Purpose of Registration',
      key: 'goals',
      type: 'array',
      dataConverter: (val) =>
        val && Array.isArray(val)
          ? val.map((el, index) => `${el}`).join(', ')
          : '',
    },
    {
      label: 'Last Date Active',
      key: 'lastOnline',
      type: 'dateonly',
    },
    {label: 'Number of Adds', key: 'numberOfAdds'},
  ];

  const deletedAtSheetHeaders = [
    {label: 'ID', key: 'userId'},
    {label: 'Email', key: 'deletedEmail'},
    {label: 'Name', key: 'name'},
    {
      label: 'Registration Date',
      key: 'createdAt',
      type: 'dateonly',
    },
    {
      label: 'Registration Time',
      key: 'createdAt',
      type: 'timeonly',
    },
    {label: 'Phone Number', key: 'deletedPhone'},
    {label: 'Nationality', key: 'nationality'},
    {
      label: 'Purpose of Registration',
      key: 'goals',
      type: 'array',
      dataConverter: (val) =>
        val && Array.isArray(val)
          ? val.map((el, index) => `${el}`).join(', ')
          : '',
    },
    {
      label: 'Last Date Active',
      key: 'lastOnline',
      type: 'dateonly',
    },
    {label: 'Number of Adds', key: 'numberOfAdds'},
    {
      label: 'Deleted At',
      key: 'deletedAt',
      type: 'datetime',
    },
    {
      label: 'Deleted By',
      key: 'deletedBy',
    },
  ];

  return (
    <>
      <Header cardsVisible={false} />
      <HistoryModal
        toggle={toggleHistoryModal}
        modal={historyModal}
        data={historyData}
        user={true}
      />
      <Container className="mt--7" fluid>
        <CustomTable
          dense
          head={[
            t('ID'),
            t('name'),
            t('nationality'),
            t('createdAt'),
            activeTab === 2 ? t('deletedAt') : '',
            activeTab === 2 ? t('deletBy') : '',
            t('phoneNumber'),
            t('lastDateActive'),
            t('numberOfAds'),
            t('goal'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          status={activeTab + 1}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          setActiveTab={setActiveTab}
          handleTabChange={setActiveTab}
          showBtnTabs={true}
          activeTab={activeTab}
          totalCount={count}
          addClickHandler={() =>
            navigateTo({
              pathname: '/admin/users/addUser',
              search: window.location.search,
            })
          }
          fetchData={fetchData}
          exportButton={
            <Export
              filename="users"
              filters={{status: activeTab + 1}}
              resource="userListing"
              dataType="users"
              params={{search}}
              headers={
                activeTab === 2 ? deletedAtSheetHeaders : commonSheetHeaders
              }
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
