import React, {useEffect, useState} from 'react';
import Header from '../../Headers/Header.js';
import {Col, Row, Container} from 'reactstrap';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {getSingleChat} from '../../../redux/actions/chats.actions.js';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FileCard from './FileCard.js';

const DashboardForm = () => {
  const [senderId, setSenderId] = useState(null);
  const {t} = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const {loading, chats, sender, receiver} = useSelector(
    (state) => state.chatsReducer,
  );

  const {chatId} = useParams();

  useEffect(() => {
    if (chats.length) {
      setSenderId(chats[0]?.senderId);
    }
  }, [chats]);

  useEffect(() => {
    dispatch(getSingleChat(chatId));
  }, [dispatch, chatId]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <div className="dashboard-form-container chat-view-container">
              <div className="dashboard-form-body margin-vertical-0 mobile-view-container">
                <Container className="padding-0">
                  <Row>
                    <Col>
                      {chats.map((chat, i) => {
                        return (
                          <div key={i}>
                            <div
                              className={
                                senderId === chat.senderId
                                  ? 'sender-side-single-msg'
                                  : 'receiver-side-single-msg'
                              }>
                              <a
                                href={`/admin/users/editUser/${chat.senderId}`}
                                className="sender-name">
                                {senderId === chat.senderId ? sender : receiver}
                              </a>
                              {chat.mediaFiles.length ? (
                                <div>
                                  {chat.mediaFiles[0].uri.indexOf('.m4a') > 0 ||
                                  chat.mediaFiles[0].uri.indexOf('.mp4') > 0 ? (
                                    <audio controls>
                                      <source
                                        src={`https://api.gulfworkers.com/common/v1/resizer/${chat.mediaFiles[0].uri}/100/100`}
                                        type="audio/mpeg"
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  ) : [
                                      'png',
                                      'webp',
                                      'jpg',
                                      'jpeg',
                                      'jfif',
                                      'pjpeg',
                                    ].includes(
                                      chat.mediaFiles[0]?.uri
                                        ?.split('.')
                                        .reverse()[0],
                                    ) ? (
                                    <img
                                      src={`https://api.gulfworkers.com/common/v1/resizer/${chat.mediaFiles[0].uri}/400/400`}
                                    />
                                  ) : (
                                    <FileCard
                                      name={chat.mediaFiles[0]?.name}
                                      size={chat.mediaFiles[0]?.size}
                                      url={`https://api.gulfworkers.com/common/v1/resizer/${chat.mediaFiles[0].uri}/1/1`}
                                    />
                                  )}
                                </div>
                              ) : (
                                <p className="receiver-msg">{chat.text}</p>
                              )}
                              <p className="receiver-msg-time">
                                {moment(chat.createdAt).format(
                                  'DD-MM-YYYY HH:mm A',
                                )}
                              </p>
                            </div>
                            <div className="clear-both"></div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      {/* <CardFooter className="py-4 transparent-background">
        <div className="dashboard-form-footer custom-bottom">
          <button
            className="form-cancel-button"
            onClick={() => history.push('/admin/chats')}>
            {loading ? 'Loading..' : 'Close'}
          </button>
        </div>
      </CardFooter> */}
    </>
  );
};

export default DashboardForm;
