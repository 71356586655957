import React, {useState, useEffect} from 'react';
// core components
import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Spinner,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import {listCategories} from '../../../redux/actions/categories.actions.js';
import {userTypes} from '../../../utils/data';
import uploadIcon from '../../../assets/images/icons/form/upload-icon.png';
import {imageUploader, renderImage} from '../../../utils/imageUpload.js';
import {useDispatch, useSelector} from 'react-redux';
import {featureNationalitiesConstants} from '../../../redux/constants';
import {getImageUrl} from '../../../utils/renderImage';
import {useParams} from 'react-router-dom';
import {finder} from '../../../utils/dataHelpers.js';
import {
  editFeatureNationality,
  getSingleFeatureNationality,
} from '../../../redux/actions/featureNationalities.actions';
import {useTranslation} from 'react-i18next';
import cogoToast from 'cogo-toast';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';
import { validateLanguagesObject } from '../../../utils/index.js';

const DashboardForm = ({history}) => {
  //redux
  const dispatch = useDispatch();
  const {categories} = useSelector((state) => state.categoriesReducer);
  const {
    nationality,
    loading,
    nationality: nationalityById,
  } = useSelector((state) => state.featureNationalitiesReducer);

  //local state
  const [icon, setIcon] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [aspectRatio, setAspactRatio] = useState();
  const [name, setName] = useState({
    en: '',
    hi: '',
    ar: '',
    fil: '',
  });
  const from = 'nationality';
  const {id} = useParams();

  useEffect(() => {
    if (!!nationalityById) {
      setSelectedCategory(
        categories.find((c) => c._id === nationalityById?.categoryId),
      );
      setUserType(finder(userTypes, nationalityById?.userType));
    }
  }, [nationalityById?.name, nationalityById?._id]);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleFeatureNationality(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!!nationality?.name) {
      setName(nationality?.name);
    }
  }, [nationality?.name]);

  const onChangeHandler = (value, lang) => {
    setName({...name, [lang]: value});
  };

  const inputFileHandler = (e) => setIcon(e.target?.files?.[0]);

  const validateForm = () => validateLanguagesObject(name);

  const editWithIcon = async () => {
    dispatch({type: featureNationalitiesConstants.FEATURE_NATIONALITY_LOADING});
    const formData = new FormData();
    formData.append('image', icon);

    const imageUrl = await imageUploader(formData);
    let payload = {
      name,
      icon: imageUrl,
      countryId: id,
      categoryId: selectedCategory?._id,
      userType: userType?.enum,
    };
    if (imageUrl) {
      dispatch(editFeatureNationality(payload, history, from));
    } else {
      //pop an error alert
    }
  };
  const editWithoutIcon = async () =>
    dispatch(
      editFeatureNationality(
        {
          name,
          icon: nationality?.icon,

          countryId: id,
          categoryId: selectedCategory?._id,
          userType: userType?.enum,
        },
        history,
        from,
      ),
    );

  const categoryChangeHandler = (i) => {
    // setSubCategoriesList([]);
    // setSelectedSubCategory(null);
    setSelectedCategory(i);
    // setCategoryDropdownOpen(!categoryDropdownOpen);
  };

  const userTypeChangeHandler = (item) => {
    setUserType(item);
    setUserDropdownOpen(!userDropdownOpen);
  };

  const submitHandler = async () => {
    if (aspectRatio === 1) {
      icon ? editWithIcon() : editWithoutIcon();
    } else {
      cogoToast.error('Please chose image with same width and height');
    }
  };

  const onLoad = ({target: {offsetHeight, offsetWidth}}) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('editNationality')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('userType')}</Label>
                        <InputGroup
                          onClick={() =>
                            setUserDropdownOpen(!userDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={'Select ' + t('userType')}
                            value={userType?.name}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={userDropdownOpen}
                            toggle={() =>
                              setUserDropdownOpen(!userDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userTypes?.map((item, i) => (
                                <DropdownItem
                                  key={i}
                                  onClick={() => userTypeChangeHandler(item)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('category')}</Label>
                        <InputGroup
                          onClick={() =>
                            setCategoryDropdownOpen(!categoryDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={'select' + t('category')}
                            value={selectedCategory?.name[lang]}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={categoryDropdownOpen}
                            toggle={() =>
                              setCategoryDropdownOpen(!categoryDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {userType?._id === 3
                                ? categories
                                    ?.filter((c) => c?.catType === 1)
                                    .map((item) => (
                                      <DropdownItem
                                        key={item?._id}
                                        onClick={() =>
                                          categoryChangeHandler(item)
                                        }>
                                        {item?.name[lang]}
                                      </DropdownItem>
                                    ))
                                : categories
                                    ?.filter((c) => c?.catType !== 1)
                                    .map((item) => (
                                      <DropdownItem
                                        key={item?._id}
                                        onClick={() =>
                                          categoryChangeHandler(item)
                                        }>
                                        {item?.name[lang]}
                                      </DropdownItem>
                                    ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <MultiLangInput
                    label="Name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('icons')} </Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder="Ppload file"
                              onChange={inputFileHandler}>
                              {' '}
                            </Input>
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                            <div className="upload-icon">
                              <img
                                alt={'upload'}
                                style={{maxWidth: '15px'}}
                                src={uploadIcon}
                              />
                            </div>
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <br />
                      {icon ? (
                        <img
                          src={renderImage(icon)}
                          // ref={ref}
                          className="input-image"
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      ) : (
                        <img
                          alt={'Gulf wrokers'}
                          src={getImageUrl(nationality?.icon, 50, 50)}
                          onLoad={onLoad}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() =>
                    history.push({
                      pathname: '/admin/feature-nationalities',
                      search: history.location.search,
                      state: history.location.state,
                    })
                  }>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('update')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
