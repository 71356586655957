import qs from 'qs';
import {languages} from '../constants';

export const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const isEmpty = (val) => {
  if (typeof val === 'number') return false;
  if (val === undefined) return true;
  if (val === null) return true;
  if (typeof val === 'string' && val?.trim() === '') return true;
  if (Array.isArray(val) && val?.length === 0) return true;
  if (typeof val === 'object' && Object.keys(val).length === 0) return true;
  return false;
};

export const getSizeString = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const replaceUrlSearchParams = (newParams) => {
  let parsedParams = qs.parse(
    window.location.search?.split('?')[1]
      ? window.location.search?.split('?')[1]
      : 'search=',
  );
  parsedParams = {...parsedParams, ...newParams};
  return qs.stringify(parsedParams);
};

export const validateLanguagesObject = (obj) => {
  let isValid = true;
  languages.forEach((lang) => {
    if (!obj?.[lang]) {
      isValid = false;
    }
  });
  return isValid;
};

export const validateLanguagesArray = (arr) => {
  let isValid = true;
  arr.forEach((obj) => {
    languages.forEach((lang) => {
      if (!obj?.[lang]) {
        isValid = false;
      }
    });
  });
  return isValid;
};

export const flattenObject = (data, keepArrays = true) => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur) && !keepArrays) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l == 0) result[prop] = [];
    } else if (Array.isArray(cur) && keepArrays) {
      result[prop] = cur;
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};