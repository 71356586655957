export const chatBansConstants = {
  CHATBAN_LOADING: 'CHATBAN_LOADING',
  CHATBAN_ERROR: 'CHATBAN_ERROR',
  CHATBAN_LIST_SUCCESS: 'CHATBAN_LIST_SUCCESS',
  CHAT_ADD_SUCCESS: 'CHAT_ADD_SUCCESS',
  CHAT_UNBAN_SUCCESS: 'CHAT_UNBAN_SUCCESS',
  CHAT_DELETE_SUCCESS: 'CHAT_DELETE_SUCCESS',
  CHAT_GET_SINGLE_SUCCESS: 'CHAT_GET_SINGLE_SUCCESS:',
  CHAT_RESET_SINGLE: 'CHAT_RESET_SINGLE:',
  SINGLE_CHAT_LOADING: 'SINGLE_CHAT_LOADING'
};
