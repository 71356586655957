import React, {useState} from 'react';
import {FormGroup, Input, InputGroup, Modal} from 'reactstrap';
import uploadIcon from '../../assets/images/icons/form/upload-icon.png';
import {API} from '../../services/auth';
import {successAlert} from '../../utils/alerts';

const acceptedFileTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const isExcelFile = (fileExt) =>
  fileExt && (fileExt === 'xls' || fileExt === 'xlsx');

export default ({path, ...props}) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputFileHandler = (e) => {
    const fileExtension = e.target?.files?.[0]?.name.split('.').pop();

    if (!isExcelFile(fileExtension)) {
      setFile(null);
      setError('Please select a valid file (xls, xlsx)');
      return;
    }

    setFile(e.target?.files?.[0]);
    setError(null);
  };

  const handleFileUpload = async () => {
    const fileExtension = file?.name.split('.').pop();
    if (!file || !isExcelFile(fileExtension)) {
      setError('Please select a valid file (xls, xlsx)');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      const res = await API.put(`/admin/v1/${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setShowImportModal(false);
      successAlert(`${path.replace("import", "")} imported successfully`);
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      // handle excel errors
      setError(err?.response?.data?.message);
    } finally {
      setLoading(false);
      setFile(null);
    }

    // setShowImportModal(false);
    // setFile(null);
  };

  const handleCancel = (e) => {
    setShowImportModal(false);
    setFile(null);
    setError(null);
  };

  const fileExtension = file?.name.split('.').pop();
  const uploadValid =
    fileExtension && (fileExtension === 'xls' || fileExtension === 'xlsx');

  return (
    <div style={styles.mainContainer}>
      <button
        className="btn table-header-button"
        onClick={() => setShowImportModal(true)}>
        Import
      </button>
      <Modal
        className="modal-dialog-centered"
        isOpen={showImportModal}
        toggle={handleCancel}>
        <div className="modal-header">
          <p className="modal-title" id="exampleModalLabel">
            Please select a file to upload
          </p>
        </div>
        <div className="modal-body">
          <FormGroup>
            {/* <Label for="import-file">Please select a file to upload</Label> */}

            <InputGroup>
              <label
                className="form-control chooseFile"
                style={styles.fileLabel}>
                <Input
                  id="import-file"
                  type="file"
                  accept={acceptedFileTypes.join(', ')}
                  name="icon-upload"
                  onChange={inputFileHandler}
                  onClick={(event)=> { 
                    event.target.value = null
                  }}
                  ></Input>
                {file ? <p className="file-input-name">{file?.name}</p> : null}
              </label>

              <div className="upload-icon" style={styles.uploadIconContainer}>
                <img
                  alt={'upload'}
                  style={styles.uploadIcon}
                  src={uploadIcon}
                />
              </div>
            </InputGroup>
          </FormGroup>
          {error && <p style={styles.error}>{`Please fix the following errors, then upload the file again:\n\n${error}`}</p>}
        </div>
        <div className="modal-footer">
          <div>
            <button className="btn table-header-button" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn table-header-button"
              style={styles.uploadBtn}
              onClick={handleFileUpload}>
              {loading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  mainContainer: {marginRight: '0.5rem'},
  fileLabel: {
    border: '1px solid #949494',
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
  },
  uploadIconContainer: {
    height: 38,
    padding: 0,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    right: 0,
    paddingTop: 3,
  },
  uploadIcon: {maxWidth: '15px'},
  error: {color: 'red', whiteSpace: 'pre-line'},
  uploadBtn: {
    borderColor: '#007BFF',
    backgroundColor: '#007BFF',
    color: '#FFF',
  },
};
