import React, {useEffect, useState} from 'react';
import Header from '../../Headers/Header.js';
import {Col, Row, Container, Form, FormGroup, Label, Input} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {getSingleUser} from '../../../redux/actions/users.actions.js';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Switch from 'react-switch';

const registeredWithEnum = {
  EMAIL: 1,
  WHATSAPP: 2,
  SMS: 3,
};

const registeredWithObj = {
  [registeredWithEnum.EMAIL]: {
    name: 'Email',
    icon: 'email.png'
  },
  [registeredWithEnum.WHATSAPP]: {
    name: 'WhatsApp',
    icon: 'whatsapp.png'
  },
  [registeredWithEnum.SMS]: {
    name: 'SMS',
    icon: 'sms.png'
  }
};

const DashboardForm = () => {
  //redux
  const dispatch = useDispatch();
  const {loading, user} = useSelector((state) => state.usersReducer);

  const {t} = useTranslation();
  const history = useHistory();

  const {id} = useParams();
  const navigateTo = (route) => history.push(route);

  useEffect(() => {
    dispatch(getSingleUser(id));

    // eslint-disable-next-line
  }, [dispatch, id]);

  const notes = user.notes ? JSON.parse(user.notes) : [];

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <div className="flex-spacebetween">
                <h2 className="dashboard-form-header view-user-header-padding">{`${t(
                  'viewUser',
                )} #${user?.userId}`}</h2>

                <h2 className="dashboard-form-header padding-0">{}</h2>
                <button
                  className="mb-0 table-header-button"
                  onClick={() =>
                    navigateTo({
                      pathname: `/admin/users/editUser/${user?._id}`,
                      search: window.location.search,
                    })
                  }>
                  {t('edit')}
                </button>
              </div>
              <div className="dashboard-form-body">
                <Form>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{t('name')}</Label>
                        <Input
                          type="text"
                          placeholder="Enter name"
                          value={user?.name}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{t('emailAddress')}</Label>
                        <Input
                          type="text"
                          placeholder="Enter email address"
                          value={user?.email}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">{t('phoneNumber')}</Label>
                        <Input
                          type="tel"
                          placeholder="Enter full phone numbe"
                          value={user?.fullNumber}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="more-actions-container">
                    <Row>
                      <Col lg={4} md={6} sm={12}>
                        <label className="switch-input">
                          <span>Verification status:</span>
                        </label>
                        {user?.status ? (
                          <p>
                            <img
                              alt="icon"
                              src={
                                require(`../../../assets/images/icons/verified.png`)
                                  .default
                              }
                              style={styles.icon}
                            />
                            Verified
                          </p>
                        ) : (
                          <p>
                            <img
                              alt="icon"
                              src={
                                require(`../../../assets/images/icons/warning.png`)
                                  .default
                              }
                              style={styles.icon}
                            />
                            Not Verified
                          </p>
                        )}
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        {user?.status && user?.registeredWith && (
                          <React.Fragment>
                            <label className="switch-input">
                              <span>Verified via:</span>
                            </label>
                            <p>
                              <img
                                alt="icon"
                                src={
                                  require(`../../../assets/images/icons/${
                                    registeredWithObj[user?.registeredWith].icon
                                  }`).default
                                }
                                style={styles.icon}
                              />
                              {registeredWithObj[user?.registeredWith].name}
                            </p>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    {/* <br /> */}
                    <Row>
                      <Col lg={4} md={6} sm={12}>
                        <label className="switch-input">
                          <span>{t('goal')}</span>
                        </label>
                        {user?.goals?.map((goal, i) => (
                          <p>
                            {i + 1}: {goal}
                          </p>
                        ))}
                        <br />
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <label className="switch-input">
                            <span>{t('nationality')}</span>
                          </label>
                            <p>
                             {user?.nationality}
                            </p>
                          <br />
                      </Col>
                    </Row>

                    <label className="switch-input">
                      <span>User blocked status:</span>
                      <Switch
                        checked={user.isBlocked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        disabled
                        onColor="#FF0000"
                      />
                    </label>
                    <br />
                    <b>Notes: </b>
                    <br />
                    <br />
                    {notes?.length
                      ? notes.map((note, index) => (
                          <div key={index}>
                            <b>Type: </b>
                            {note.add}
                            <br />
                            <b>Description: </b>
                            {note.note}

                            <hr />
                          </div>
                        ))
                      : null}
                  </div>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() =>
                    navigateTo({
                      pathname: '/admin/users',
                      search: window.location.search,
                    })
                  }>
                  {loading ? 'Loading..' : 'Close'}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;

const styles = {
  icon: {
    width: 25,
    height: 25,
    marginRight: 8,
    marginBottom: 2,
  },
};
