import React, {useState} from 'react';

import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroupButtonDropdown,
} from 'reactstrap';

import {useDispatch, useSelector} from 'react-redux';

import {addCategory} from '../../../redux/actions/categories.actions.js';
import {categoriesConstants} from '../../../redux/constants/categories.constants.js';
import {imageUploader, renderImage} from '../../../utils/imageUpload.js';
import uploadIcon from '../../../assets/images/icons/form/upload-icon.png';
import {useTranslation} from 'react-i18next';
import {emptyLanguagesObject} from '../../../constants/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';
import {validateLanguagesObject} from '../../../utils/index.js';

const DashboardForm = ({history}) => {
  //redux
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.categoriesReducer);
  const [dropdownOpen, setDropdownOpen] = useState();
  const [cateType, setCateType] = useState();

  const [name, setName] = useState({
    ...emptyLanguagesObject,
  });
  const [firstCategoryOption, setFirstCategoryOption] = useState({
    ...emptyLanguagesObject,
  });
  const [secondCategoryOption, setSecondCategoryOption] = useState({
    ...emptyLanguagesObject,
  });
  const [icon, setIcon] = useState(null);
  const [aspectRatio, setAspactRatio] = useState();
  const {en, hi, ar, fil} = name;

  const onChangeHandler = (value, lang) => setName({...name, [lang]: value});

  const onOptionNameChange = (value, lang, optionNumber) => {
    if (optionNumber === 1) {
      setFirstCategoryOption({
        ...firstCategoryOption,
        [lang]: value,
      });
    } else {
      setSecondCategoryOption({
        ...secondCategoryOption,
        [lang]: value,
      });
    }
  };

  const inputFileHandler = (e) => setIcon(e.target?.files?.[0]);

  const validateForm = () => validateLanguagesObject(name) && icon;

  const submitHandler = async () => {
    // if (aspectRatio === 1) {
    dispatch({type: categoriesConstants.CATEGORY_LOADING});
    const formData = new FormData();
    formData.append('image', icon);

    const imageUrl = await imageUploader(formData);
    if (imageUrl) {
      dispatch(
        addCategory(
          {
            name,
            firstCategoryOption,
            secondCategoryOption,
            icon: imageUrl,
            catType: cateType,
          },
          history,
        ),
      );
    } else {
      // pop and error
    }
    // } else {
    //   cogoToast.error('Please chose image with same width and height');
    // }
  };

  const onLoad = ({target: {offsetHeight, offsetWidth}}) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };
  const {t} = useTranslation();
  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('addCategory')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <MultiLangInput
                    label="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                  <MultiLangInput
                    label={t('firstOptionName')}
                    placeholder={t('firstOptionName')}
                    value={firstCategoryOption}
                    onChange={(value, lang) =>
                      onOptionNameChange(value, lang, 1)
                    }
                  />
                  <MultiLangInput
                    label={t('secondOptionName')}
                    placeholder={t('secondOptionName')}
                    value={secondCategoryOption}
                    onChange={(value, lang) =>
                      onOptionNameChange(value, lang, 2)
                    }
                  />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('icons')} </Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder={t('uploadPlaceholder')}
                              onChange={inputFileHandler}>
                              {' '}
                            </Input>
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                            <div className="upload-icon">
                              <img
                                alt={'upload'}
                                style={{
                                  maxWidth: '15px',
                                  pointerEvents: 'none',
                                }}
                                src={uploadIcon}
                              />
                            </div>
                          </label>
                          <br />
                        </InputGroup>
                      </FormGroup>
                      {icon && (
                        <img
                          src={renderImage(icon)}
                          className="input-image"
                          alt={'gcc'}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('quesType')} </Label>
                        <InputGroup
                          onClick={() => setDropdownOpen(!dropdownOpen)}>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={'select' + t('quesType')}
                            value={cateType ? 'Services' : `Jobs & Workers`}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={dropdownOpen}
                            toggle={() => setDropdownOpen(!dropdownOpen)}>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => setCateType(0)}>
                                Jobs &amp; Workers
                              </DropdownItem>
                              <DropdownItem onClick={() => setCateType(1)}>
                                Services
                              </DropdownItem>
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/categories')}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;

// banner 0.4186  0.465 0.5116
// sub 1
// category 1
// ad 1
// country 1
