export const chatsConstants = {
  CHAT_LOADING: 'CHAT_LOADING',
  CHAT_ERROR: 'CHAT_ERROR',
  CHAT_LIST_SUCCESS: 'CHAT_LIST_SUCCESS',
  CHAT_ADD_SUCCESS: 'CHAT_ADD_SUCCESS',
  CHAT_EDIT_SUCCESS: 'CHAT_EDIT_SUCCESS',
  CHAT_DELETE_SUCCESS: 'CHAT_DELETE_SUCCESS',
  CHAT_GET_SINGLE_SUCCESS: 'CHAT_GET_SINGLE_SUCCESS:',
  CHAT_RESET_SINGLE: 'CHAT_RESET_SINGLE:',
  SINGLE_CHAT_LOADING: 'SINGLE_CHAT_LOADING'
};
