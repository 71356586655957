import Chats from '../../views/pages/chats';
import ChatView from '../../views/pages/chats/viewChat';

let routes = [
  {
    path: '/admin/chats',
    name: 'chats',
    icon: 'contact-us-icon',
    component: Chats,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/chats/:chatId',
    name: 'chatView',
    icon: 'contact-us-icon',
    component: ChatView,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
];

export default routes;
