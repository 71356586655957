import React, { useEffect } from 'react';

const Paginate = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  setCurrentPage,
}) => {
  const pageNumbers = [];
  const maxPages = Math.ceil(totalPosts / postsPerPage);
  for (let i = 1; i <= maxPages; i++) {
    if ((maxPages > 6 && (currentPage + 1) >= i && (currentPage - 2) < i) || (maxPages > 6 && (maxPages - 1) <= i))
      pageNumbers.push(i);
    else if (maxPages < 6)
      pageNumbers.push(i);
  }

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      paginate(currentPage - 1);
    }
  };

  const nextHandler = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
      paginate(currentPage + 1);
    }
  };

  return (
    <div className="custom-pagination">
      <button className="pagination-button" onClick={prevHandler}>
        Previous
      </button>

      <div className="pagination-items">
        {pageNumbers.map((number) => {
          if(currentPage + 3 < maxPages && currentPage + 1 === number) return <div style={{display: 'inline-block'}}>
              <span
                onClick={() => paginate(number)}
                key={number}
                className={`${number === currentPage ? 'active' : ''} `}>
                {number}
              </span>
              <span key='dots'>
                ...
              </span>
            </div>
            
          return <span
            onClick={() => paginate(number)}
            key={number}
            className={`${number === currentPage ? 'active' : ''} `}>
            {number}
          </span>
        })}
      </div>
      <button className="pagination-button" onClick={nextHandler}>
        Next
      </button>
    </div>
  );
};

export default Paginate;
