import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, Spinner} from 'reactstrap';
import {
  emptyLanguagesObject,
  languageNames,
  languages,
} from '../../../constants';
import {errorParser} from '../../../redux/actions/errorParser';
import {API, headerSetup} from '../../../services/auth';
import {errorAlert, successAlert} from '../../../utils/alerts';
import RichText from '../../Inputs/RichText/RichText';

const MetadataForm = ({type}) => {
  const {t} = useTranslation();
  const [data, setData] = useState({[type]: {...emptyLanguagesObject}});
  const [documentId, setDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);
  const [currentTab, setCurrentTab] = React.useState('en');

  const getData = useCallback(async () => {
    setLoading(true);
    await headerSetup();
    try {
      const {
        data: {data: response},
      } = await API.get(`admin/v1/metadata?type=${type}`);
      setDocumentId(response._id);
      setData({[type]: {...response[type]}});
    } catch (err) {
      const parsedError = await errorParser(err);
      errorAlert(parsedError);
    }
    setLoading(false);
  }, [type]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setHeight(containerRef.current.clientHeight);
  });

  const handleSubmit = async () => {
    setSubmitLoading(true);
    await headerSetup();
    try {
      await API.put(`admin/v1/metadata`, {_id: documentId, ...data});
      successAlert('Metadata updated successfully');
    } catch (err) {
      const parsedError = await errorParser(err);
      errorAlert(parsedError);
    }
    setSubmitLoading(false);
  };

  const handleChange = (lang) => (newValue) => {
    setData({[type]: {...data[type], [lang]: newValue}});
  };

  const navLinkStyle = {
    boxShadow: 'none',
    width: 200,
  };

  return (
    <Row>
      <div ref={containerRef} className="col">
        <Card style={{border: 'none', paddingTop: 20}}>
          {loading && (
            <div
              style={{
                height: height + 10,
                width: '100%',
                position: 'absolute',
                zIndex: 1000,
                background: 'rgba(255,255,255,0.8)',
                top: -10,
                left: -2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  position: 'unset',
                }}
                className="table-loader">
                <Spinner color={'info'} />
              </div>
            </div>
          )}
          <Tab.Container
            onSelect={(tab) => setCurrentTab(tab)}
            id="languages-tabs"
            defaultActiveKey="en">
            <Row>
              <Col lg={3} md={12} style={{marginRight: -25}}>
                <Nav variant="pills" className="flex-column">
                  {languages.map((lang) => (
                    <Nav.Item key={lang}>
                      <Nav.Link style={navLinkStyle} eventKey={lang}>
                        {languageNames.en[lang]}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col lg={9} md={12}>
                <Tab.Content>
                  {languages.map((lang) => (
                    <Tab.Pane key={lang} eventKey={lang}>
                      {currentTab === lang && (
                        <RichText
                          value={data[type][lang]}
                          setValue={handleChange(lang)}
                          rtl={lang === 'ar'}
                        />
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Button
            style={{
              width: 200,
              margin: 'auto',
              marginBottom: 15,
              marginTop: 20,
            }}
            variant="primary"
            disabled={submitLoading}
            onClick={!submitLoading ? handleSubmit : null}>
            {submitLoading ? 'Loading…' : t('submit')}
          </Button>
        </Card>
      </div>
    </Row>
  );
};

export default MetadataForm;
