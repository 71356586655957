import React, {useCallback, useEffect, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {
  deleteArea,
  listAreas,
  suffleAreas,
} from '../../redux/actions/areas.actions.js';
import {listCountries} from '../../redux/actions/countries.actions';
import {replaceUrlSearchParams} from '../../utils';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';

const Tables = ({history}) => {
  const dispatch = useDispatch();
  const {countries} = useSelector((state) => state.countriesReducer);
  const {areas, count, loading} = useSelector((state) => state.areasReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  const [countryId, setCountryId] = useState(null);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteArea(id));
    } else return;
  };

  useEffect(() => {
    if (countries?.length > 0 && countryId === null)
      setCountryId(countries[0]?._id || null);
  }, [countries]);

  useEffect(() => {
    dispatch(listCountries());
  }, [dispatch]);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) => {
      if (countryId)
        dispatch(
          listAreas(postsPerPage, currentPage, searchKeyword, countryId),
        );
    },
    [dispatch, countryId],
  );

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const handleClick = (newCountryId) => {
    if (countryId !== newCountryId) {
      setCountryId(newCountryId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          countryId: newCountryId,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const onEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let clone = areas;
      let draggedLink = clone[sourceIdx];
      let newList = clone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);
      dispatch(
        suffleAreas({
          from: clone[sourceIdx]._id,
          to: clone[destIdx]._id,
          postsPerPage: postsPerPage,
          currentPage: currentPage,
          countryId,
        }),
      );
    }
  };

  const rows = areas.map((item) => [
    item?.name[lang],
    countries?.find((c) => c._id === item?.countryId)?.name[lang]
      ? countries.find((c) => c._id === item.countryId)?.name[lang]
      : 'N/A',
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={editIcon}
        onClick={() =>
          history.push({
            pathname: `/admin/areas/editArea/${item._id}`,
            state: {
              area: item,
              countries,
            },
            search: window.location.search,
          })
        }
      />
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={deleteIcon}
        onClick={() => deleteHandler(item?._id)}
      />
    </>,
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[t('name'), t('country'), t('actions')]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          onDragEnd={onEnd}
          fetchData={fetchData}
          addClickHandler={() =>
            history.push({
              pathname: '/admin/areas/addArea',
              state: {countries},
            })
          }
          filters={{
            state: {countryId},
            setState: ({countryId}) => {
              handleClick(countryId);
            },
            component: (
              <div className="cardScroll">
                <h6>Countries:</h6>
                <ScrollMenu>
                  {countries?.map((c, i) => (
                    <MenuItem
                      itemId={c._id}
                      title={c?.name[lang]}
                      key={c._id}
                      display={'block'}
                      onClick={
                        countryId === c._id ? null : () => handleClick(c?._id)
                      }
                      selected={countryId === c._id}
                    />
                  ))}
                </ScrollMenu>
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
