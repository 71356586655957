import moment from 'moment';
import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import {chatUnban, listChatBans} from '../../redux/actions/chatBans.actions.js';
import Export from '../Buttons/Export';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import SwitchSlider from '../Switch/SwitchSlider.js';

const Tables = ({history}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);

  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {chatBannedUsers, loading, count} = useSelector(
    (state) => state.chatBansReducer,
  );

  const navigateTo = (route) => history.push(route);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword, sortDirection) =>
      dispatch(
        listChatBans(postsPerPage, currentPage, searchKeyword, sortDirection),
      ),
    [dispatch],
  );

  const handleUnbanUser = (item) => {
    let payload = {
      banId: item?._id,
    };
    dispatch(chatUnban(payload, item.blockedUser?.[0]?.name));
  };

  const rows = chatBannedUsers.map((item) => [
    <div
      className="userName"
      onClick={() =>
        item?.blockedUser?.[0] &&
        navigateTo(`/admin/users/editUser/${item?.blockedUser?.[0]?._id}`)
      }>
      {item?.blockedUser?.[0]?.name ? item?.blockedUser?.[0]?.name : 'N/A'}
    </div>,
    <div
      className="userName"
      onClick={() =>
        item?.fromUser?.[0] &&
        navigateTo(`/admin/users/editUser/${item?.fromUser?.[0]?._id}`)
      }>
      {item?.fromUser?.[0]?.name ? item?.fromUser?.[0]?.name : 'N/A'}
    </div>,
    moment(item?.createdAt).format('DD/MM/YYYY hh:mm A'),
    <div
      className="flex-center userName"
      onClick={() =>
        item?.adId && navigateTo(`/admin/ads/editAd/${item?.adId}`)
      }>
      {item?.adData[0]?.title}
    </div>,
    <SwitchSlider
      clicked={() => handleUnbanUser(item)}
      checked={item?._id}
      name={item?.id}
    />,
  ]);

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('blockedUser'),
            t('byWhom'),
            t('lastBlockedAt'),
            t('addTitle'),
            t('status'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          hasSortDirectionSelect={true}
          exportButton={
            <Export
              filename="chats"
              resource="chatbans"
              params={{search}}
              headers={[
                {label: 'Blocked User', key: 'blockedUser.0.name'},
                {label: 'By Whom', key: 'fromUser.0.name'},
                {
                  label: 'Last Message Sent Time',
                  key: 'createdAt',
                  type: 'datetime',
                },
              ]}
            />
          }
        />
      </Container>
    </>
  );
};

export default Tables;
