import {chatsConstants} from '../constants';

const chatsReducer = (state = {chatrooms: [], chats: [], sender: null, receiver: null}, action) => {
  switch (action.type) {
    case chatsConstants.CHAT_LOADING:
      return {...state, loading: true};
    case chatsConstants.SINGLE_CHAT_LOADING:
      return {...state, loading: true, chats: []};

    case chatsConstants.CHAT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chatrooms: action.payload.listing,
        count: action.payload.count,
      };

    case chatsConstants.CHAT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: [action.payload, ...state.chats],
      };

    case chatsConstants.CHAT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: state.chats.filter((i) => i?._id !== action.payload),
      };
    case chatsConstants.CHAT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: state.chats.map((item) =>
          item._id === action.payload?._id ? action.payload : item,
        ),
      };

    case chatsConstants.CHAT_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: action.payload.listing,
        sender: action.payload.sender[0]?.name,
        receiver: action.payload.receiver[0]?.name,
      };
    case chatsConstants.CHAT_RESET_SINGLE:
      return {
        ...state,

        chat: {},
      };

    case chatsConstants.CHAT_ERROR:
      return {...state, loading: false, error: action.payload};
    case 'LOGOUT':
      return {
        ...state,
        chats: [],
        chat: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default chatsReducer;
