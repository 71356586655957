import { API, headerSetup } from '../../services/auth';
import { chatReportsConstants } from '../constants';
import { errorParser } from './errorParser';
import { errorAlert, successAlert, warningAlert } from '../../utils/alerts';
import { chatReportStatusEnum } from '../../utils/data';

export const listChatReports =
  (perPage = 15, page = 1, status = '') =>
    async (dispatch) => {
      await headerSetup();
      dispatch({ type: chatReportsConstants.CHAT_REPORTS_LOADING });
      status = parseInt(status);
      const statusQuery =
        !isNaN(status) && status !== null ? `&reportStatus=${status}` : '';

      try {
        const {
          data: { data },
        } = await API.get(
          `admin/v1/chat-reports?perPage=${perPage}&page=${page}${statusQuery}`,
        );
        if (data) {
          dispatch({
            type: chatReportsConstants.CHAT_REPORTS_LIST_SUCCESS,
            payload: { listing: data?.listing, count: data?.count },
          });
        }
      } catch (err) {
        const parsedError = await errorParser(err);
        dispatch({
          type: chatReportsConstants.CHAT_REPORTS_ERROR,
          payload: parsedError,
        });
        errorAlert(parsedError);
      }
    };

export const getPendingChatReports =
  (perPage = 1, page = 1) =>
    async (dispatch) => {
      await headerSetup();
      const statusQuery = `&reportStatus=${chatReportStatusEnum.PENDING}`;
      try {
        const {
          data: { data },
        } = await API.get(
          `admin/v1/chat-reports?perPage=${perPage}&page=${page}${statusQuery}`,
        );
        if (data) {
          dispatch({
            type: chatReportsConstants.PENDING_CHAT_REPORTS_LIST_SUCCESS,
            payload: { count: data?.count },
          });
        }
      } catch (err) {
        const parsedError = await errorParser(err);
        dispatch({
          type: chatReportsConstants.PENDING_CHAT_REPORTS_LIST_ERROR,
          payload: parsedError,
        });
        errorAlert(parsedError);
      }
    };
