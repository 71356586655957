import Settings from '../../views/pages/settings';

let routes = [
  {
    path: '/admin/settings',
    name: 'Settings',
    icon: 'settings-icon',
    component: Settings,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
];

export default routes;
