import Metadata from '../../views/pages/metadata';

let routes = [
  {
    path: '/admin/metadata',
    name: 'Metadata',
    icon: 'about-icon',
    component: Metadata,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
];

export default routes;
