import {campaignConstants} from '../constants';

const campaignsReducer = (state = {campaigns: [], campaign: {}}, action) => {
  switch (action.type) {
    case campaignConstants.CAMPAIGN_LOADING:
      return {...state, loading: true};

    case campaignConstants.CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        campaigns: action.payload.listing,
        count: action.payload.count,
      };
    case campaignConstants.CAMPAIGN_SUFFLE:
      return {
        ...state,
        campaigns: action.payload,
      };

    case campaignConstants.CAMPAIGN_ERROR:
      return {...state, loading: false, error: action.payload};

    case 'LOGOUT':
      return {
        ...state,
        campaigns: [],
        campaign: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default campaignsReducer;
