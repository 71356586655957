import React, {useState, useEffect} from 'react';

// reactstrap components

// core components
import Header from '../../Headers/Header.js';
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroupButtonDropdown,
  Label,
  Input,
  Spinner,
  InputGroup,
} from 'reactstrap';

import {useDispatch, useSelector} from 'react-redux';
import uploadIcon from '../../../assets/images/icons/form/upload-icon.png';
import {useParams} from 'react-router-dom';
import {
  editCategory,
  getSingleCategory,
} from '../../../redux/actions/categories.actions.js';
import {categoriesConstants} from '../../../redux/constants/categories.constants.js';
import {getImageUrl} from '../../../utils/renderImage.js';
import {imageUploader, renderImage} from '../../../utils/imageUpload.js';
import {useTranslation} from 'react-i18next';
import {emptyLanguagesObject} from '../../../constants/index.js';
import MultiLangInput from '../../Inputs/MultiLangInput/MultiLangInput.js';
import {validateLanguagesObject} from '../../../utils/index.js';

const DashboardForm = ({history}) => {
  //redux
  const dispatch = useDispatch();

  //import loading and error as well
  const {category, loading} = useSelector((state) => state.categoriesReducer);
  const [aspectRatio, setAspactRatio] = useState();
  const [dropdownOpen, setDropdownOpen] = useState();
  const [cateType, setCateType] = useState();
  const [name, setName] = useState({
    ...emptyLanguagesObject,
  });
  const [firstCategoryOption, setFirstCategoryOption] = useState({
    ...emptyLanguagesObject,
  });
  const [secondCategoryOption, setSecondCategoryOption] = useState({
    ...emptyLanguagesObject,
  });
  const [icon, setIcon] = useState(null);

  const {id} = useParams();

  const inputFileHandler = (e) => setIcon(e.target?.files?.[0]);

  useEffect(() => {
    dispatch(getSingleCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!!category?.name) {
      setName(category?.name);
    }
    if (!!category?.firstCategoryOption) {
      setFirstCategoryOption(category?.firstCategoryOption);
    }
    if (!!category?.secondCategoryOption) {
      setSecondCategoryOption(category?.secondCategoryOption);
    }

    setCateType(category?.catType);
  }, [category?.name]);

  const onChangeHandler = (value, lang) => setName({...name, [lang]: value});

  const onOptionNameChange = (value, lang, optionNumber) => {
    if (optionNumber === 1) {
      setFirstCategoryOption({
        ...firstCategoryOption,
        [lang]: value,
      });
    } else {
      setSecondCategoryOption({
        ...secondCategoryOption,
        [lang]: value,
      });
    }
  };

  const validateForm = () => validateLanguagesObject(name);

  const editWithIcon = async () => {
    dispatch({type: categoriesConstants.CATEGORY_LOADING});
    const formData = new FormData();
    formData.append('image', icon);

    const imageUrl = await imageUploader(formData);
    if (imageUrl) {
      dispatch(
        editCategory(
          {
            name,
            firstCategoryOption,
            secondCategoryOption,
            icon: imageUrl,
            categoryId: id,
            catType: cateType,
          },
          history,
        ),
      );
    } else {
      //pop an error alert
      dispatch({type: categoriesConstants.CATEGORY_ERROR});
    }
  };

  const {t} = useTranslation();
  const editWithoutIcon = async () =>
    dispatch(
      editCategory(
        {
          name,
          firstCategoryOption,
          secondCategoryOption,
          icon: category?.icon,
          categoryId: id,
          catType: cateType,
        },
        history,
      ),
    );

  const onLoad = ({target: {offsetHeight, offsetWidth}}) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };

  const submitHandler = () => {
    // if (aspectRatio === 1) {
    icon ? editWithIcon() : editWithoutIcon();
    // } else {
    // cogoToast.error('Please chose image with same width and height');
    // }
  };

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('editCategory')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <MultiLangInput
                    label="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(value, lang) => onChangeHandler(value, lang)}
                    isRequired
                  />
                  <MultiLangInput
                    label={t('firstOptionName')}
                    placeholder={t('firstOptionName')}
                    value={firstCategoryOption}
                    onChange={(value, lang) =>
                      onOptionNameChange(value, lang, 1)
                    }
                  />
                  <MultiLangInput
                    label={t('secondOptionName')}
                    placeholder={t('secondOptionName')}
                    value={secondCategoryOption}
                    onChange={(value, lang) =>
                      onOptionNameChange(value, lang, 2)
                    }
                  />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('icons')}</Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder={t('uploadPlaceholder')}
                              onChange={inputFileHandler}>
                              {' '}
                            </Input>
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                            <div className="upload-icon">
                              <img
                                alt={'upload'}
                                style={{maxWidth: '15px'}}
                                src={uploadIcon}
                              />
                            </div>
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <br />

                      {icon ? (
                        <img
                          src={renderImage(icon)}
                          className="input-image"
                          alt={'gcc'}
                        />
                      ) : (
                        <img
                          alt={'Gulf wrokers'}
                          src={getImageUrl(category?.icon, 50, 50)}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('quesType')} </Label>
                        <InputGroup
                          onClick={() => setDropdownOpen(!dropdownOpen)}>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={'select' + t('quesType')}
                            value={cateType ? 'Services' : `Jobs & Workers`}
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={dropdownOpen}
                            toggle={() => setDropdownOpen(!dropdownOpen)}>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => setCateType(0)}>
                                Jobs &amp; Workers
                              </DropdownItem>
                              <DropdownItem onClick={() => setCateType(1)}>
                                Services
                              </DropdownItem>
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/categories')}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('update')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
