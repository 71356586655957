import {API, headerSetup} from '../../services/auth';
import {adReportsConstants} from '../constants';
import {errorParser} from './errorParser';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {adReportStatusEnum} from '../../utils/data';

export const listAdReports =
  (perPage = 15, page = 1, status = '') =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: adReportsConstants.AD_REPORTS_LOADING});
    status = parseInt(status);
    const statusQuery =
      !isNaN(status) && status !== null ? `&status=${status}` : '';

    try {
      const {
        data: {data},
      } = await API.get(
        `admin/v1/ad_reports?perPage=${perPage}&page=${page}${statusQuery}`,
      );

      if (data) {
        dispatch({
          type: adReportsConstants.AD_REPORTS_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: adReportsConstants.AD_REPORTS_ERROR,
        payload: parsedError,
      });
      errorAlert(parsedError);
    }
  };

export const getPendingReports =
  (perPage = 1, page = 1) =>
  async (dispatch) => {
    await headerSetup();
    const statusQuery =`&status=${adReportStatusEnum.PENDING}`;

    try {
      const {
        data: {data},
      } = await API.get(
        `admin/v1/ad_reports?perPage=${perPage}&page=${page}${statusQuery}`,
      );

      if (data) {
        dispatch({
          type: adReportsConstants.PENDING_AD_REPORTS_LIST_SUCCESS,
          payload: {count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: adReportsConstants.PENDING_AD_REPORTS_LIST_ERROR,
        payload: parsedError,
      });
      errorAlert(parsedError);
    }
  };
