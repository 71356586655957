import adRoutes from './ads.routes';
import categoriesRoutes from './categories.routes';
import AreasRoutes from './areas.routes';
import bannersRoutes from './banners.routes';
import notificationsRoutes from './notifications.routes';
import paymentRoutes from './payments.routes';
import usersRoutes from './users.routes';
import chatsRoutes from './chats.routes';
import dashboardRoutes from './dashboard.routes';
import contactUsRoutes from './contactUs.routes';
import questionsRoutes from './questions.routes';
import countriesRoutes from './countries.routes';
import filtersRoutes from './filters.routes';
import adReportsRoutes from './adReports.routes';
import chatBansRoutes from './chatBans.routes';
import chatReportsRoutes from './chatReports.routes';
import featurenationalities from './featureNationalities.routes';
import metadataRoutes from './metadata.routes';
import settingsRoutes from './settings.routes';
import campaignsRoutes from './campaign.routes';

const routes = [
  ...dashboardRoutes,
  ...usersRoutes,
  ...chatsRoutes,
  ...chatBansRoutes,
  ...categoriesRoutes,
  ...questionsRoutes,
  ...filtersRoutes,
  ...countriesRoutes,
  ...featurenationalities,
  ...AreasRoutes,
  ...adRoutes,
  ...bannersRoutes,
  ...paymentRoutes,
  ...notificationsRoutes,
  ...adReportsRoutes,
  ...chatReportsRoutes,
  ...contactUsRoutes,
  ...metadataRoutes,
  ...settingsRoutes,
  ...campaignsRoutes
];

export default routes;
