import {chatBansConstants} from '../constants';

const chatBansReducer = (state = {chatBannedUsers: [], chats: [], sender: null, receiver: null}, action) => {
  switch (action.type) {
    case chatBansConstants.CHATBAN_LOADING:
      return {...state, loading: true};
    case chatBansConstants.SINGLE_CHAT_LOADING:
      return {...state, loading: true, chats: []};

    case chatBansConstants.CHATBAN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chatBannedUsers: action.payload.listing,
        count: action.payload.count,
      };

    case chatBansConstants.CHAT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: [action.payload, ...state.chats],
      };

    case chatBansConstants.CHAT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: state.chats.filter((i) => i?._id !== action.payload),
      };
    case chatBansConstants.CHAT_UNBAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chatBannedUsers: state.chatBannedUsers.filter((item) => item._id !== action.payload?.banId)
      };

    case chatBansConstants.CHAT_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        chats: action.payload.listing,
        sender: action.payload.sender[0]?.name,
        receiver: action.payload.receiver[0]?.name,
      };
    case chatBansConstants.CHAT_RESET_SINGLE:
      return {
        ...state,

        chat: {},
      };

    case chatBansConstants.CHATBAN_ERROR:
      return {...state, loading: false, error: action.payload};
    case 'LOGOUT':
      return {
        ...state,
        chats: [],
        chat: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default chatBansReducer;
