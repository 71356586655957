export const questionsConstants = {
  QUESTION_LOADING: 'QUESTION_LOADING',
  QUESTION_ERROR: 'QUESTION_ERROR',
  QUESTION_LIST_SUCCESS: 'QUESTION_LIST_SUCCESS',
  QUESTION_ADD_SUCCESS: 'QUESTION_ADD_SUCCESS',
  QUESTION_EDIT_SUCCESS: 'QUESTION_EDIT_SUCCESS',
  QUESTION_DELETE_SUCCESS: 'QUESTION_DELETE_SUCCESS',
  QUESTION_GET_SINGLE_SUCCESS: 'QUESTION_GET_SINGLE_SUCCESS:',
  QUESTION_RESET_SINGLE: 'QUESTION_RESET_SINGLE:',
  QUESTION_SUFFLE: 'QUESTION_SUFFLE:',
};
