import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, CardHeader, Container, Row} from 'reactstrap';
import Header from '../../../components/Headers/Header';
import SwitchSlider from '../../../components/Switch/SwitchSlider.js';
import {
  listGlobalSettings,
  updateGlobalSettings,
} from '../../../redux/actions/settings.actions';

const Metadata = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {settings} = useSelector((state) => state.settingsReducer);
  const googleCaptcha = settings.find(setting => setting.key === 'googleCaptcha');
  const [gCaptcha, setgCaptcha] = useState(false);

  const handleClick = () => {
    dispatch(updateGlobalSettings({
      key: 'googleCaptcha',
      value: !gCaptcha ? '1' : '0'
    }));
    setgCaptcha(!gCaptcha);
  }

  useEffect(() => {
    dispatch(listGlobalSettings());
  }, []);

  useEffect(() => {
    setgCaptcha(googleCaptcha && parseInt(googleCaptcha.value));
  }, [settings]);

  return (
    <div>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card style={{padding: 10}}>
              <CardHeader className="border-0 table-custom-header">
                <div className="table-header-actions" />
              </CardHeader>
              <div className='recaptcha-row'>
                <p>{t('googleRecaptcha')}</p>
                <SwitchSlider
                  clicked={handleClick}
                  checked={gCaptcha}
                  // name={}
                />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Metadata;
