import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {usersConstants} from '../constants';
import {errorParser} from './errorParser';

export const listUsers =
  (perPage = 100, page = 1, search = '', status = 1) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: usersConstants.USER_LOADING});

    try {
      const {
        data: {data},
      } = await API.get(
        `admin/v1/userListing?perPage=${perPage}&page=${page}&search=${search}${status ? `&status=${status}`: `&status=1`}`,
      );

      if (data) {
        dispatch({
          type: usersConstants.USER_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: usersConstants.USER_ERROR,
        payload: parsedError,
      });
    }
  };

export const getSingleUser = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: usersConstants.USER_LOADING});

  try {
    const {
      data: {data},
    } = await API.get(`admin/v1/userDetail?userId=${id}`);

    if (data) {
      dispatch({
        type: usersConstants.USER_GET_SINGLE_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: usersConstants.USER_ERROR,
      payload: parsedError,
    });
  }
};

export const addUser = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: usersConstants.USER_LOADING});

  try {
    const {
      data: {data},
    } = await API.post('admin/v1/addUser', formData);

    if (data) {
      dispatch({
        type: usersConstants.USER_ADD_SUCCESS,
        payload: data,
      });
      successAlert(`User ${formData?.name} added successfullt`);
      history.push('/admin/users');
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: usersConstants.USER_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editUser = (formData, history, cb = null) => async (dispatch) => {
  await headerSetup();
  dispatch({type: usersConstants.USER_LOADING});
  try {
    const {
      data: {data},
    } = await API.put('admin/v1/editUser', formData);

    if (data) {
      dispatch({
        type: usersConstants.USER_EDIT_SUCCESS,
        payload: data?.data,
      });
      successAlert(`${formData?.name || 'Data'} has been updated successfully.`);
      if (history) {
        history.push({
          pathname: '/admin/users',
          search: window.location.search,
        });
      }
      if(cb) cb();
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: usersConstants.USER_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: usersConstants.USER_LOADING});

  try {
    const response = await API.delete(`admin/v1/deleteUser/${id}`);
    dispatch({
      type: usersConstants.USER_DELETE_SUCCESS,
      payload: id,
    });
    successAlert(`User '${response?.data?.data?.name || response?.data?.data?.email || response?.data?.data?.fullNumber}' deleted successfully`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: usersConstants.USER_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
