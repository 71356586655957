import Area from '../../views/pages/areas';
import AreaAdd from '../../views/pages/areas/addArea';
import AreaEdit from '../../views/pages/areas/editArea';
// import CountryView from '../../views/pages/countries/viewCountry';

let routes = [
  {
    path: '/admin/areas',
    name: 'areas',
    icon: 'banner-icon',
    component: Area,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/areas/addArea',
    name: 'areas',
    icon: 'banner-icon',
    component: AreaAdd,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  {
    path: '/admin/areas/editArea/:id',
    name: 'areas',
    icon: 'banner-icon',
    component: AreaEdit,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  // {
  //   path: '/admin/countries/viewCountry/:id',
  //   name: 'countries',
  //   icon: 'banner-icon',
  //   component: CountryView,
  //   svg: true,
  //   layout: '/admin',
  //   sideBar: false,
  // },
];

export default routes;
